window.search = (function () {
  let searchDone = false;
  let timeout: $TSFixMe = null;
  const $searchWrapper = $(".js-search");

  const initPage = function () {
    window.ProductList.initialize();
    const $filterItems = $(".js-filter");
    $.each($filterItems, (index, filterItem) => {
      window.Filter.initialize(filterItem);
    });
    window.CategoryListSort.initialize();
  };

  const doSearch = function (basePath: $TSFixMe) {
    $.ajax({
      url: basePath + window.location.search,
      type: "get",
      cache: false,
      contentType: "application/x-www-form-urlencoded; charset=UTF-8",
      success(htmlData) {
        if (!searchDone) {
          searchDone = true;
          $searchWrapper.html(htmlData);
          initPage();
        }
        if (timeout) {
          clearTimeout(timeout);
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  };

  const search = function () {
    searchDone = false;
    timeout = null;

    doSearch("/search/personalised");
    const headStart = parseInt($searchWrapper.data("headStart"), 10) || 2000;
    timeout = window.setTimeout(doSearch, headStart, "/search/fallback");
  };

  const initialize = function () {
    if (!$searchWrapper.length) {
      return;
    }
    search();
  };

  return {
    initialize,
  };
})();

$(() => {
  window.search.initialize();
});
