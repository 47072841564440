window.Base = {
  resolutionSizes: {
    mobile: 767,
    tablet: 1024,
    laptop: 1080,
    smallDesktop: 1280,
    desktop: 1395,
  },
  /* 
    This breakpoints are matching newSizes from css
  */
  newResolutionSizes: {
    smallMobile: 319,
    mobile: 374,
    smallTablet: 499,
    tablet: 767,
    laptop: 1023,
    smallDesktop: 1080,
    desktop: 1279,
    bigDesktop: 1439,
    fullHD: 1919,
    FourK: 3999,
  },
};
