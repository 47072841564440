(function () {
  const initialize = function ($banner: JQuery<HTMLElement>) {
    const formattedText =
      window.XxlHelpers.replaceNewLineCharactersWithBrElements($banner.text());

    $banner.html(formattedText);
  };

  $(() => {
    const $banners = $(".js-banner-description");
    $banners.each(function () {
      initialize($(this));
    });
  });
})();
