$(() => {
  const $productDetails = $(".js-product-details");
  $.each($productDetails, (index, productDetail) => {
    window.ProductDetail.initialize(productDetail);
  });
});

window.ProductDetail = (function () {
  const initialize = function (productDetail: $TSFixMe) {
    const $itemHeader = $(".js-product-details__item-header");

    $($itemHeader)
      .off("click")
      .on("click", function () {
        const $tabHeader = $(this);
        const isBigScreen =
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          $(window).width() >= window.Base.resolutionSizes.tablet;
        const selectedClassName = "product-details__item--selected";
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        const tabContentId: string = $tabHeader.attr("href");
        const $tabContent = $(tabContentId);

        if (isBigScreen && $tabHeader.parent().hasClass(selectedClassName)) {
          return;
        }

        const toggleParentSelectedClass = ($child: JQuery) => {
          $child.parent().toggleClass(selectedClassName);
        };

        const removeSelectedClassFromParentSiblings = ($child: JQuery) => {
          $child.parent().siblings().removeClass(selectedClassName);
        };

        if (isBigScreen) {
          toggleParentSelectedClass($tabHeader);
          removeSelectedClassFromParentSiblings($tabHeader);
        }

        toggleParentSelectedClass($tabContent);
        removeSelectedClassFromParentSiblings($tabContent);
        window.Lightbox.initialize();
      });

    // Ran on page load to fetch the correct values depending on device
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if ($(window).width() < window.Base.resolutionSizes.tablet) {
      $(productDetail)
        .find(".product-details__item--selected")
        .removeClass("product-details__item--selected");
    } else {
      $($itemHeader).first().click();
    }
  };

  const pushTabPageViewEvent = (url: $TSFixMe) => {
    if (
      !$("#product-details__size-guide")
        .parent()
        .hasClass("product-details__item--selected")
    ) {
      window.Gtm.pushPageViewEvent(url);
    }
  };

  return {
    initialize,
    pushTabPageViewEvent,
  };
})();
