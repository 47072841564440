window.Popup = (function () {
  const openPopup = function ($button: JQuery) {
    $button.off("click").on("click", function () {
      const currentPopup = $(this).siblings(".js-popup");
      $(".js-popup").not(currentPopup).slideUp(400);
      currentPopup.slideDown(200);
      closePopup();
      return false;
    });
  };

  var closePopup = function () {
    $(".js-popup__close")
      .off("click")
      .on("click", function () {
        $(this).parent(".js-popup").slideUp(200);
        return false;
      });
  };

  const initialize = function () {
    const $popup = $(".js-popup__open");

    if (!$popup.length) {
      return;
    }

    $.each($popup, (index, element) => {
      openPopup($(element));
    });
  };

  return {
    initialize,
  };
})();
