import type { BundleConfigurationMemberData } from "@xxl/pim-api";
import type {
  BundleConfigurationGroupData,
  BundleConfigurationProductData,
} from "../../../../../../react-app/src/global";

// @ts-expect-error TS(2322) FIXME: Type '{ resetBundleJsonConfig: (groupCode: string)... Remove this comment to see the full error message
window.BundleJsonConfig = (function () {
  const resetBundleJsonConfig = (groupCode: string) => {
    window.BundleGlobal.bundleDataJSONConfig.groups.forEach((group) => {
      if (group.id === groupCode) {
        group.products = [];
      }
    });
  };

  const productsBundleConfig = (
    selectedProductStyle: string,
    sizeSelected: string,
    groupId: string,
    bundleType: string | null,
    ean: string
  ) => {
    const bundleTypeProductObject: BundleConfigurationGroupData = {
      id: groupId,
      products: [],
      bundleType,
    };

    if (
      !window.BundleGlobal.bundleDataJSONConfig.groups
        .map((group) => group.id)
        .includes(groupId)
    ) {
      window.BundleGlobal.bundleDataJSONConfig.groups.push(
        bundleTypeProductObject
      );
    }

    const bundleSelectedItemObject: BundleConfigurationProductData = {
      item: sizeSelected,
      style: selectedProductStyle,
      ean,
    };

    window.BundleGlobal.bundleDataJSONConfig.groups
      .filter((group) => group.id === groupId)
      .forEach((group) => {
        const isProductMissing = !group.products.some(
          ({ style }) => style === selectedProductStyle
        );

        if (isProductMissing) {
          group.products.push(bundleSelectedItemObject);
          return;
        }

        group.products
          .filter((product) => product.style === selectedProductStyle)
          .forEach((product) => {
            product.item = sizeSelected;
            product.ean = ean;
          });
      });
  };

  const getPrintConfig = (
    configName: string,
    prints: BundleConfigurationGroupData[]
  ) => {
    for (const print of prints) {
      for (const product of print.products) {
        if (
          product.configurationsKeyValueMap !== undefined &&
          product.configurationsKeyValueMap.TEXTFIELD[configName]
        ) {
          return product.configurationsKeyValueMap.TEXTFIELD[configName];
        }
      }
    }
  };

  const selectedMemberConfigure = function () {
    const $selectedMemberDisplay = $(".js-team-bundle-member__selected-list");
    const $selectedMemberProducts = $selectedMemberDisplay.find(
      ".js-team-bundle__member-product"
    );

    if (!$selectedMemberProducts.length) {
      return;
    }

    teamBundleConfigureInitialProductGroups();
    selectedMemberConfigPrint($selectedMemberProducts);
  };

  const selectedMemberConfigPrint = (
    $selectedMemberProducts: JQuery<HTMLElement>
  ) => {
    const selectedMemberConfig: BundleConfigurationGroupData[] = [];

    $selectedMemberProducts.each(function () {
      const $eachMemberProduct = $(this);
      const memberProductArray: BundleConfigurationProductData[] = [];

      $eachMemberProduct
        .find(".js-team-bundle__member-configuration")
        .each(function () {
          const $eachMemberProductConfiguration = $(this);
          const $eachMemberProductConfigItem =
            $eachMemberProductConfiguration.find(
              ".js-team-bundle__member-configuration-item"
            );

          const eachMemberProductConfig: BundleConfigurationProductData = {
            item: $eachMemberProductConfiguration.data("item") || null,
          };

          if ($eachMemberProductConfigItem.length !== 0) {
            eachMemberProductConfig.configurationsKeyValueMap =
              getProductConfiguration($eachMemberProductConfigItem);
          }
          memberProductArray.push(eachMemberProductConfig);
        });
      selectedMemberConfig.push({
        id: $eachMemberProduct.data("id") || null,
        products: memberProductArray,
        bundleType: "print",
      });
    });

    window.BundleGlobal.bundleDataJSONConfig.print = selectedMemberConfig;
    selectedMemberConfigMembers();
  };

  const selectedMemberConfigMembers = () => {
    const members: BundleConfigurationMemberData[] = [];
    const teamMemberProduct =
      window.BundleGlobal.bundleDataJSONConfig.groups.filter((product) => {
        return product.bundleType === "print";
      })[0];
    const teamMemberInformation: BundleConfigurationMemberData = {
      leafId: teamMemberProduct.id,
      item: teamMemberProduct.products[0].item,
      // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | undefined' is not assigna... Remove this comment to see the full error message
      initials: getPrintConfig(
        "initials",
        window.BundleGlobal.bundleDataJSONConfig.print
      ),
      firstname: getPrintConfig(
        "firstname",
        window.BundleGlobal.bundleDataJSONConfig.print
      ),
      lastname: getPrintConfig(
        "lastname",
        window.BundleGlobal.bundleDataJSONConfig.print
      ),
      // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | undefined' is not assigna... Remove this comment to see the full error message
      number: getPrintConfig(
        "playernbr",
        window.BundleGlobal.bundleDataJSONConfig.print
      ),
      ean: teamMemberProduct.products[0].ean,
    };
    members.push(teamMemberInformation);
    window.BundleGlobal.bundleDataJSONConfig.members = members;
  };

  const teamBundleAdminSetMembersConfig = () => {
    teamBundleConfigureInitialProductGroups();
    const $memberItems = $(".js-bundle-admin__team-member-item--selected");
    const membersObject = $memberItems
      .map(function () {
        const member = {
          initials: $(this)
            .find(".js-team-bundle-admin__member-list-initials")
            .text(),
          firstname: $(this)
            .find(".js-team-bundle-admin__member-list-item-name")
            .data("firstname") as string,
          lastname: $(this)
            .find(".js-team-bundle-admin__member-list-item-name")
            .data("lastname") as string,
          number: $(this)
            .find(".js-team-bundle-admin__member-list-number")
            .text(),
          size: $(this)
            .find(".select-box__item--selected")
            .data("select-value") as string,
          item: $(this)
            .find(".select-box__item--selected")
            .data("product-code") as string,
          ean: $(this)
            .find(".select-box__item--selected")
            .data("product-ean") as number,
          userId: $(this)
            .find(".js-team-bundle-admin__member-list-item-name")
            .data("id") as string,
        };
        return member;
      })
      .get();
    window.BundleGlobal.bundleDataJSONConfig.members = membersObject;

    const productDataForPrint: BundleConfigurationGroupData[] = [];
    const $bundleElements = $(".leaf");
    $bundleElements.each(function () {
      const $bundleElement = $(this);
      const printConfig: BundleConfigurationGroupData = {
        id: $bundleElement.data("id"),
        products: [],
        bundleType: "PRINT",
      };

      $bundleElement.find(".product").each(function () {
        const $productInBundle = $(this);
        const productDataObject: BundleConfigurationProductData = {
          item: $productInBundle.data("item") || null,
        };
        printConfig.products.push(productDataObject);
      });
      productDataForPrint.push(printConfig);
    });
    window.BundleGlobal.bundleDataJSONConfig.print = productDataForPrint;
  };

  const teamBundleConfigureInitialProductGroups = () => {
    const $teamBundleProduct = $(".js-bundle__product");

    $teamBundleProduct.each((_index, productItem) => {
      const $teamBundleSelectedProductItem = $(productItem).find(
        ".js-bundle__select-size li.select-box__item.select-box__item--selected"
      );

      const selectedProductStyle = $(productItem).data("code");

      const { templateType, templateId } = $(productItem)
        .closest(".js-bundle__template")
        .data();

      const bundleTemplateType =
        templateType === "bundleproducts" ? null : templateType;

      const productSizeSelected = $teamBundleSelectedProductItem
        ? $teamBundleSelectedProductItem.data("product-code")
        : null;

      const productEanSizeSelected =
        $teamBundleSelectedProductItem.length > 0
          ? ($teamBundleSelectedProductItem.data("product-ean") as string)
          : null;

      productsBundleConfig(
        selectedProductStyle as string,
        productSizeSelected as string,
        templateId as string,
        bundleTemplateType as string,
        productEanSizeSelected as string
      );
    });
  };

  const getProductConfiguration = function ($config: JQuery) {
    const configurationsKeyValueMap: $TSFixMe = {};
    $config.each(function (this: HTMLElement) {
      const $configuration = $(this);
      const configType = $configuration.data("config-type") as $TSFixMeString;
      const configLabel = $configuration.data("config-label") as $TSFixMeString;
      const configValue = $configuration.val();
      const subMap = configurationsKeyValueMap[`${configType}`] || {};
      subMap[`${configLabel}`] = configValue;
      configurationsKeyValueMap[`${configType}`] = subMap;
    });
    return configurationsKeyValueMap;
  };

  const addUserSettingsGroup = (groupId: string) => {
    window.bundleUserSettingsHelper.id = groupId;
    window.BundleGlobal.bundleDataJSONConfig.groups.push(
      window.bundleUserSettingsHelper
    );
  };

  const fillUserSettingsGroupWithProducts = (
    group: BundleConfigurationGroupData,
    userSettingsObject: BundleConfigurationProductData,
    settingsId: string
  ) => {
    const isProductMissing =
      group.products
        .map((userSetting: BundleConfigurationProductData) => {
          return userSetting.groupId;
        })
        .indexOf(settingsId) === -1;

    if (isProductMissing) {
      group.products.push(userSettingsObject);
      return;
    }
  };

  const addUserSettingsProductData = (
    group: BundleConfigurationGroupData,
    selectedSettingSize: string,
    settingsId: string
  ) => {
    group.products
      .filter(
        (userSetting: BundleConfigurationProductData) =>
          userSetting.groupId === settingsId
      )
      .forEach((userSetting: BundleConfigurationProductData) => {
        userSetting.item = selectedSettingSize;
      });
  };

  const setBundleUserSettingsData = (
    groupId: string,
    settingsId: string,
    selectedSettingSize: string,
    selectedSettingDescription: string,
    selectedEan: string
  ) => {
    if (window.bundleUserSettingsHelper.id.length === 0) {
      addUserSettingsGroup(groupId);
    }

    const userSettingsObject: BundleConfigurationProductData = {
      groupId: settingsId,
      id: selectedSettingDescription,
      item: selectedSettingSize,
      ean: selectedEan,
    };

    window.BundleGlobal.bundleDataJSONConfig.groups
      .filter((group) => {
        return group.id === groupId;
      })
      .forEach((group) => {
        fillUserSettingsGroupWithProducts(
          group,
          userSettingsObject,
          settingsId
        );
        addUserSettingsProductData(group, selectedSettingSize, settingsId);
      });
  };

  const getAutoConfigData = (
    $checkedInputElement: JQuery<HTMLElement>
  ): {
    autoConfigData: BundleConfigurationProductData;
    settingsId?: string;
  } => {
    const groupId = $checkedInputElement.data("template-id") as $TSFixMeString;
    const item = $checkedInputElement.data("settings-code") as $TSFixMeString;
    const ean = String($checkedInputElement.data("product-ean"));
    const settingsId = $(
      '.js-bundle__settings-dropdown[data-settings-index="1"]'
    ).data("template-id") as $TSFixMeString;

    const allowConfig = $checkedInputElement.val() === "true";
    const autoConfigData = {
      configurationsKeyValueMap: {
        BOOLEAN: {
          allow_ski_config: allowConfig,
        },
      },
      groupId,
      item,
      ean,
    };

    return {
      autoConfigData,
      settingsId,
    };
  };

  const addBundleAutoConfiguratedSettings = (): void => {
    const $autoConfiguredInputs = $(
      ".js-bundle__settings-auto-configure-product input"
    );
    const $checkedInputElement = $autoConfiguredInputs.filter(
      (_index, inputElement) => {
        return $(inputElement).prop("checked") === true;
      }
    );

    const { settingsId, autoConfigData } =
      getAutoConfigData($checkedInputElement);

    if (settingsId !== undefined) {
      window.BundleGlobal.bundleDataJSONConfig.groups
        .filter((group) => {
          return group.id === settingsId;
        })
        .forEach((group) => {
          const hasProductConfig = group.products.some(
            (product: BundleConfigurationProductData) =>
              product.configurationsKeyValueMap
          );
          const isAutoConfigSelected =
            autoConfigData.configurationsKeyValueMap?.BOOLEAN.allow_ski_config;

          if (!hasProductConfig && isAutoConfigSelected) {
            group.products.push(autoConfigData);
            return;
          }

          group.products = group.products.filter((product) =>
            product.configurationsKeyValueMap !== undefined
              ? isAutoConfigSelected
              : true
          );

          group.products
            .filter((product) => product.configurationsKeyValueMap)
            .forEach((product) => {
              if (product.configurationsKeyValueMap?.BOOLEAN === undefined) {
                return;
              }

              product.configurationsKeyValueMap.BOOLEAN.allow_ski_config = true;
            });
        });
    }
  };

  const servicesBundleConfig = (
    $clickedElement: JQuery,
    addingService: boolean
  ) => {
    const serviceItem = $clickedElement.data("size") as $TSFixMeString;
    const serviceStyle = $clickedElement.data("style") as $TSFixMeString;

    const serviceObject: BundleConfigurationProductData = {
      item: serviceItem,
      style: serviceStyle,
    };

    const servicesInBundleConfigGroup =
      window.BundleGlobal.bundleDataJSONConfig.groups.filter((group) => {
        return group.id === "accessories";
      });

    if (addingService) {
      if (servicesInBundleConfigGroup.length === 0) {
        window.bundleServicesHelper.products.push(serviceObject);
        window.BundleGlobal.bundleDataJSONConfig.groups.push(
          window.bundleServicesHelper
        );
      } else {
        servicesInBundleConfigGroup.forEach((group) => {
          const isProductMissing =
            group.products
              .map(function (product) {
                if (product.style === serviceStyle) {
                  return false;
                }
              })
              .indexOf(false) === -1;

          if (isProductMissing) {
            group.products.push(serviceObject);
            return;
          }

          group.products
            .filter((product) => {
              return product.style === serviceStyle;
            })
            .forEach((product) => {
              product.item = serviceItem;
            });
        });
      }
    } else {
      window.BundleGlobal.bundleDataJSONConfig.groups
        .filter((group) => {
          return group.id === "accessories";
        })
        .forEach((group) => {
          group.products = group.products.filter((product) => {
            if (
              product.item !== serviceItem &&
              product.style !== serviceStyle
            ) {
              return product;
            }
          });
        });
    }
  };

  return {
    resetBundleJsonConfig,
    servicesBundleConfig,
    setBundleUserSettingsData,
    productsBundleConfig,
    addBundleAutoConfiguratedSettings,
    selectedMemberConfigure,
    teamBundleAdminSetMembersConfig,
  };
})();
