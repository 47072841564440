window.BundleInit = (function () {
  const NUMBER_OF_ROWS_TO_SHOW = 2;
  const stickyHeaderElement = document.getElementById("js-sticky-header");
  if (stickyHeaderElement !== null) {
    stickyHeaderElement.classList.add("header--non-sticky");
  }

  const hideNonActiveProducts = () => {
    const $productImagesWrapper = $(".js-bundle-init__product");
    const templateHiddenClass = "bundle__template--hidden";
    const templateVisibleClass = "bundle__template--visible";
    $productImagesWrapper
      .not(".bundle__template-product--active")
      .removeClass(templateVisibleClass)
      .addClass(templateHiddenClass);
  };

  const toggleTopBundleDescription = () => {
    const $bundleTopDescription = $(".js-bundle__top-description");
    const $bundleTopReadMore = $(".js-bundle__top-read-more");
    const numberOfCharsToShow = 250;
    const numberOfCharsInMobile = 150;

    window.Toggle.toggleParagraphOnChars(
      numberOfCharsToShow,
      $bundleTopDescription,
      $bundleTopReadMore,
      numberOfCharsInMobile
    );
  };

  const toggleEachBundleProductDescription = () => {
    const $bundleProducts = $(".js-bundle__product");
    $bundleProducts.each((_index, productElement) => {
      const $elementToToggle = $(productElement).find(
        ".js-bundle__product-description"
      );
      const $readMoreButton = $(productElement).find(
        ".js-bundle-init__read-more"
      );
      window.Toggle.toggleParagraphOnRows(
        NUMBER_OF_ROWS_TO_SHOW,
        $elementToToggle,
        $readMoreButton
      );
    });
  };

  const initializeToggleElements = () => {
    toggleEachBundleProductDescription();
    toggleTopBundleDescription();
  };

  const enableDisableCartBtn = () => {
    const $addToCartBtn = $(".js-bundle__add-to-cart");
    const $bundleSteps = $(".bundle__template.js-bundle__template");
    const stepDoneClass = "bundle-steps__step--done";
    const templateDoneClass = "bundle__template--done";

    const hasRemainingSteps = $bundleSteps
      .toArray()
      .map((element) => $(element))
      .some(
        ($element) =>
          !(
            $element.hasClass(stepDoneClass) ||
            $element.hasClass(templateDoneClass)
          )
      );

    const isExitDenmark = window.location.host.includes("www.xxl.dk");
    $addToCartBtn.prop("disabled", hasRemainingSteps || isExitDenmark);
  };

  const initialize = function () {
    const $bundlePage = $(".js-bundle");

    if ($bundlePage.length === 0) {
      return;
    }

    initializeToggleElements();
    enableDisableCartBtn();
    hideNonActiveProducts();
    window.Cart.updateGlobalServicesAndCrossSalesFlags();
  };

  return {
    initialize,
    enableDisableCartBtn,
    initializeToggleElements,
    NUMBER_OF_ROWS_TO_SHOW,
  };
})();

$(() => {
  window.BundleInit.initialize();
});
