import type { XXLCookie, CartDetailsCookie } from "react-app/src/global";
import type { CookieName, ConsentCookie } from "react-app/src/utils/Cookie";

window.Cookie = (function () {
  const TEN_MINUTES_IN_MILLIS = 10 * 60 * 1000;
  const EMPTY_JSON_OBJECT = "{}";

  function parseSerializedCookie<T>(xxlCookie: string): T {
    return JSON.parse(xxlCookie.replace(/\\/g, "")) as T;
  }

  const getCookieParsed = <T>(name: CookieName): T | null => {
    const cookie = window.Cookies.get(name) ?? null;
    if (cookie === null) {
      return null;
    }

    return parseSerializedCookie<T>(cookie);
  };

  const isCookieReadyToUpdate = (cookie: $TSFixMe) => {
    const now = Date.now();

    const definedCookie = {
      totalItems: 0,
      lastModified: now,
      ...cookie,
    };

    return (
      definedCookie.totalItems > 0 &&
      now - definedCookie.lastModified > TEN_MINUTES_IN_MILLIS
    );
  };

  const getSerializedCookie = (cookie: CookieName) => {
    return window.Cookies.get(cookie) ?? EMPTY_JSON_OBJECT;
  };

  const getBase64EncodedSerializedCookie = (cookie: CookieName) => {
    const cookieValue = window.Cookies.get(cookie);
    try {
      return cookieValue !== undefined
        ? window.atob(cookieValue)
        : EMPTY_JSON_OBJECT;
    } catch (e) {
      console.error(
        `Unable to parse base64 encoded cookie ${cookie} '${String(
          cookieValue
        )}'`,
        e
      );
    }
    return EMPTY_JSON_OBJECT;
  };

  const deleteCookie = (cookie: CookieName): void => {
    window.Cookies.remove(cookie, { domain: window.location.hostname });
    window.Cookies.remove(cookie);
  };

  const getCartDetailsCookie = (): CartDetailsCookie => {
    const serializedCookie = getBase64EncodedSerializedCookie("cartDetails");

    try {
      return parseSerializedCookie<CartDetailsCookie>(serializedCookie);
    } catch (err) {
      console.error(
        `Unable to parse cartDetails cookie '${String(serializedCookie)}'`,
        err
      );
    }
    return {};
  };
  const getCookieInformationConsent = (): ConsentCookie | null =>
    getCookieParsed("CookieInformationConsent");

  const getCookie = (): XXLCookie => {
    let cookie: XXLCookie = {
      memberNumber: undefined,
      subscribedToNewsletter: false,
    };
    const serializedCookie = getSerializedCookie("xxl");

    try {
      cookie = {
        ...cookie,
        ...parseSerializedCookie<XXLCookie>(serializedCookie),
      };
    } catch (err) {
      console.error(
        `Unable to parse cookie '${String(serializedCookie)}'`,
        err
      );
    }

    return cookie;
  };

  let refreshCookieTokenjqXHR: $TSFixMe = null;

  const refreshCookieToken = () => {
    if (
      getCookie().token === null ||
      getCookie().token === undefined || // no token, don't refresh
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      new Date(getCookie().token.expires).getTime() > Date.now()
    ) {
      // new enough token, don't refresh
      return new Promise<void>((resolve) => resolve());
    }
    if (refreshCookieTokenjqXHR !== null) {
      // refresh request in progress, use it
      return new Promise((resolve) => refreshCookieTokenjqXHR.always(resolve));
    }
    refreshCookieTokenjqXHR = $.ajax({
      type: "GET",
      url: "/cookie/",
      headers: {
        Accept: "text/html; charset=UTF-8",
        "Content-Type": "application/json; charset=UTF-8",
      },
      error(jqXHR, textStatus, errorThrown) {
        window.Login.userLoginCheck();
        console.error(jqXHR);
        console.error(textStatus);
        console.error(errorThrown);
      },
    });
    return new Promise((resolve) =>
      refreshCookieTokenjqXHR.always(() => {
        refreshCookieTokenjqXHR = null;
        return resolve(getSerializedCookie("xxl"));
      })
    );
  };

  let getXxlCookieJqXHR: $TSFixMe = null;

  function isCookieValid(xxlCookie: string): boolean {
    return (
      Boolean(xxlCookie) &&
      parseSerializedCookie<XXLCookie>(xxlCookie).cookieVersion ===
        window._sharedData.cookieVersion
    );
  }

  const getSerializedXxlCookie = () => {
    const xxlCookie = window.Cookies.get("xxl") ?? "";
    if (isCookieValid(xxlCookie)) {
      return new Promise((resolve) => resolve(xxlCookie));
    }
    if (refreshCookieTokenjqXHR !== null) {
      // refresh request in progress, use it
      return new Promise((resolve) => refreshCookieTokenjqXHR.always(resolve));
    }
    if (getXxlCookieJqXHR !== null) {
      // get cookie request in progress, use it
      return new Promise((resolve) => getXxlCookieJqXHR.always(resolve));
    }
    getXxlCookieJqXHR = $.ajax({
      type: "GET",
      url: "/cookie/xxl",
      headers: {
        Accept: "text/html; charset=UTF-8",
        "Content-Type": "application/json; charset=UTF-8",
      },
      error(jqXHR, textStatus, errorThrown) {
        window.Login.userLoginCheck();
        console.error(jqXHR);
        console.error(textStatus);
        console.error(errorThrown);
      },
    });
    return new Promise((resolve) =>
      getXxlCookieJqXHR.always(() => {
        getXxlCookieJqXHR = null;
        return resolve(getSerializedCookie("xxl"));
      })
    );
  };

  return {
    isCookieReadyToUpdate,
    refreshCookieToken,
    getCookie,
    getSerializedXxlCookie,
    deleteCookie,
    getCartDetailsCookie,
    getCookieInformationConsent,
  };
})();
