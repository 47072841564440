$(() => {
  if ($(".js-team-bundle").length > 0) {
    window.TeamBundle.initialize();
  }
});

window.TeamBundle = (function () {
  const initialize = () => {
    fetchTeamBundleCustomerType();
  };

  const fetchTeamBundleCustomerType = () => {
    const $productDetailsFooterInformation = $(
      ".js-team-bundle__product-information-footer"
    );
    void $.ajax({
      url: "/teambundle/teamdata",
      dataType: "json",
      success(customerTypeData) {
        const isCustomerAdmin = customerTypeData.isAdmin;
        const isBundleReset = false;
        if (isCustomerAdmin) {
          //remove product footer information and select box inside if customer is administrator
          $productDetailsFooterInformation.first().remove();
          window.TeamBundleAdminMember.displayTeamBundleAdminMembers(
            customerTypeData.teams
          );
          const $step1 = $("#step1");
          window.Bundle.updateBundleDetailSteps($step1, isBundleReset);
          window.Bundle.updateBundleStepsHeader($step1, isBundleReset);
        } else {
          window.TeamBundleMember.displayTeamBundleMember(
            customerTypeData.members
          );
          const $teamdetails = $("#teamdetails");
          window.Bundle.updateBundleDetailSteps($teamdetails, isBundleReset);
          window.Bundle.updateBundleStepsHeader($teamdetails, isBundleReset);
        }

        $productDetailsFooterInformation.show();
      },
      error(error) {
        console.error(error);
      },
    });
  };

  /*
   * Utils related to Step 1: Product size
   */
  const hasToChooseProductSize = () => $(".js-bundle__select-size").length > 0;

  /*
   * Utils related to Step 1: if Administrator has configurable logo with dropdown
   */

  const hasSizeAndItIsSelected = () => {
    let isSizeSelected = false;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(this: HTMLElement, _index: numb... Remove this comment to see the full error message
    $(".js-bundle__select-size").each((_index, selectSizeDropdown) => {
      if ($(selectSizeDropdown).find(".select-box__item--selected")) {
        return (isSizeSelected = true);
      }
    });
    return isSizeSelected;
  };

  /*
   * Utils related to Step 2: Team member sizes
   */
  const getMembersElements = () => {
    const $membersSummaryWrapper = $(".js-team-bundle__admin-user-summary");
    const $membersSummaryElement = $membersSummaryWrapper.find(
      ".js-team-bundle__selected-quantity-summary"
    );
    const $teamAdminMembersList = $(".js-team-bundle-admin__members-list");
    return {
      $membersSummaryWrapper,
      $membersSummaryElement,
      $teamAdminMembersList,
    };
  };

  const getNumberOfIncludedTeamMembers = () =>
    $(".js-team-bundle__checkbox.checkbox--selected").length;
  const hasAtLeastOneMember = () => getNumberOfIncludedTeamMembers() > 0;

  const getNumberOfChosenSizes = () => {
    const { $membersSummaryElement } = getMembersElements();
    return $membersSummaryElement.data("chosen-sizes");
  };

  const isAllSizesSet = () =>
    getNumberOfIncludedTeamMembers() === getNumberOfChosenSizes();

  const getIsReadyToAddToCart = () => hasAtLeastOneMember() && isAllSizesSet();

  /*
   * Maps logic to DOM step checkmark completion indicators, in turn used for the AddToCart button.
   */
  const evaluateSteps = () => {
    const $step1 = $("#step1");
    if (!hasToChooseProductSize() || hasSizeAndItIsSelected()) {
      markClosestStepDone($step1);
    } else {
      markClosestStepNotDone($step1);
    }

    const $teamdetails = $("#teamdetails");
    const isReadyToAddToCart = getIsReadyToAddToCart();
    if (isReadyToAddToCart) {
      markClosestStepDone($teamdetails);
    } else {
      markClosestStepNotDone($teamdetails);
    }
    window.Bundle.updateBundleDetailSteps($teamdetails, !isReadyToAddToCart);
    window.Bundle.updateBundleStepsHeader($teamdetails, !isReadyToAddToCart);
  };

  const markClosestStepDone = ($element: JQuery) =>
    toggleClosestStepDone($element, true);

  const markClosestStepNotDone = ($element: JQuery) =>
    toggleClosestStepDone($element, false);

  const toggleClosestStepDone = ($element: JQuery, setDone: $TSFixMe) => {
    $element
      .closest(".bundle__template.js-bundle__template")
      .toggleClass("bundle__template--done", setDone)
      .toggleClass("bundle-steps__step--done", setDone);
    window.BundleInit.enableDisableCartBtn();
  };

  return {
    initialize,
    evaluateSteps,
    getMembersElements,
    getNumberOfIncludedTeamMembers,
    markClosestStepDone,
    hasAtLeastOneMember,
  };
})();
