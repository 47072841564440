import type { AccountResponse } from "./teamsales-types";

type KlarnaUserData = {
  email?: string;
  given_name?: string;
  family_name?: string;
  postal_code?: string;
  city?: string;
  country?: string;
};

window.Checkout = (function () {
  const runAfterElementExists = function (
    jquery_selector: $TSFixMe,
    callback: $TSFixMe
  ) {
    const checker = window.setInterval(() => {
      if ($(jquery_selector).length) {
        //stop checking for the existence of this element
        clearInterval(checker);

        //call the passed in function via the parameter above
        callback();
      }
    }, 200);
  };

  const updateShippingOption = function (shippingOptionData: {
    price: number;
  }) {
    window.XxlEvent?.dispatchEvent(
      window.XxlEvent.type.XXL_SHIPPING_UPDATE,
      shippingOptionData
    );
  };

  const getAccountEmail = async (): Promise<string | undefined> => {
    return $.ajax({
      method: "POST",
      url: window._sharedData.configuration.amplifyConfig
        .aws_appsync_graphqlEndpoint,
      headers: {
        "content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify({
        query: `
          query getAccountInfo {
            account {
              email
            }
          }
        `,
      }),
    })
      .then((response: AccountResponse) => {
        const { email } = response.data.account;
        return email;
      })
      .fail((error) => {
        console.error("Error getting account info!", error);
      });
  };

  const storeCustomerData = async function (changeData: KlarnaUserData) {
    const isKlarnaEmailEmpty =
      changeData.email === undefined || changeData.email === "";
    const email =
      isKlarnaEmailEmpty && window.Login.isLoggedIn()
        ? await getAccountEmail()
        : changeData.email;

    if (
      email !== undefined &&
      (window.Login.isLoggedIn() ||
        (window.CookieInformation?.getConsentGivenFor("cookie_cat_marketing") ??
          false))
    ) {
      const { setStorageItem } = window.XxlHelpers;
      setStorageItem(
        "checkout_customer",
        JSON.stringify({
          email,
          firstName: changeData.given_name,
          lastName: changeData.family_name,
          postalCode: changeData.postal_code,
          city: changeData.city,
          country: changeData.country,
        }),
        "session"
      );
    }
  };

  const addKlarnaEvents = function () {
    runAfterElementExists("#klarna-checkout-iframe", () => {
      window._klarnaCheckout((api: $TSFixMe) => {
        api.on({
          shipping_option_change(shippingOptionData: $TSFixMe) {
            updateShippingOption(shippingOptionData);
          },
          change(changeData: KlarnaUserData) {
            storeCustomerData(changeData);
          },
          load(loadData: {
            shipping_address: KlarnaUserData;
            shipping_option_change: { price: number };
          }) {
            storeCustomerData(loadData.shipping_address);
            updateShippingOption(loadData.shipping_option_change);
          },
        });
      });
    });
  };

  const initialize = function () {
    if ($(".js-checkout-snippet").length < 1) {
      return;
    }
    addKlarnaEvents();
  };

  return {
    initialize,
  };
})();
