/**
 * This is the legacy version of storefront/react-app/src/utils/ToggleScrollbar/toggle-scrollbar.ts
 */
window.ToggleScrollbar = (function () {
  const showScrollbar = function () {
    document.body.classList.remove("site__scroll-hidden");
    document.body.style.removeProperty("margin-right");
  };

  const hideScrollbar = function () {
    const rect = document.body.getBoundingClientRect();
    const marginWidth = window.innerWidth - (rect.left + rect.right);

    document.body.style.marginRight = `${marginWidth}px`;
    document.body.classList.add("site__scroll-hidden");
  };

  return {
    showScrollbar,
    hideScrollbar,
  };
})();
