const Toggle = (function () {
  const toggleParagraph = function (
    $paragraphToShow: JQuery<HTMLElement>,
    $readMoreButton: JQuery<HTMLElement>
  ) {
    const readMoreText = $readMoreButton.data("toggleShowMoreText") as string;
    const readLessText = $readMoreButton.data("toggleShowLessText") as string;

    const DURATION_MS = 200;
    const clickFunc = () => {
      const isToggled = $paragraphToShow.hasClass("isToggled");
      $readMoreButton.text(isToggled ? readMoreText : readLessText);

      $paragraphToShow.animate(
        {
          height: isToggled ? 0 : $paragraphToShow.get(0)?.scrollHeight,
          marginTop: isToggled ? 0 : "12px",
        },
        DURATION_MS,
        () => $paragraphToShow.toggleClass("isToggled", !isToggled)
      );
    };

    $readMoreButton.off("click").on("click", clickFunc);
  };

  const toggleParagraphOnRows = (
    numberOfRowsToShow: number,
    $paragraphToShow: JQuery<HTMLElement>,
    $readMoreButton: JQuery<HTMLElement>,
    paragraphLineHeight?: number,
    $arrowIcon?: JQuery<HTMLElement>,
    showOnMobileOnly = false
  ) => {
    const paragraph = $paragraphToShow.get(0);
    if (paragraph === undefined) {
      return;
    }

    const initialScrollHeight = paragraph.scrollHeight;
    const PARAGRAPH_LINE_HEIGHT = paragraphLineHeight ?? 21; //21 is paragraphLineHeight set in global styles
    const heightLimit: number = numberOfRowsToShow * PARAGRAPH_LINE_HEIGHT;
    const MAX_MOBILE_WIDTH = 767;

    if (
      initialScrollHeight <= heightLimit ||
      (showOnMobileOnly && window.outerWidth > MAX_MOBILE_WIDTH)
    ) {
      $readMoreButton.hide();
      return;
    } else {
      $readMoreButton.show();
    }
    $paragraphToShow.css({
      "line-height": `${PARAGRAPH_LINE_HEIGHT}px`,
      overflow: "hidden",
      "max-height": `${heightLimit}px`,
      "-webkit-line-clamp": numberOfRowsToShow.toString(),
    });

    $paragraphToShow
      .children("p")
      .css("line-height", `${PARAGRAPH_LINE_HEIGHT}px`);
    $paragraphToShow.addClass("line-clamp");

    // REQUIRES toggleShowMoreText AND toggleShowLessText TO CHANGE BUTTON TEXT BETWEEN TOGGLE MODES
    const readMoreText = $readMoreButton.data("toggleShowMoreText");
    const readLessText = $readMoreButton.data("toggleShowLessText");

    const setRotated = (isRotated: boolean) => {
      $arrowIcon !== undefined &&
        $arrowIcon.toggleClass("read-more__icon--rotated", isRotated);
    };

    // Toggle read more/less
    const clickFunction = () => {
      const scrollHeight = paragraph.scrollHeight;
      if ($paragraphToShow.hasClass("isToggled")) {
        $paragraphToShow
          .animate(
            {
              maxHeight: `${heightLimit}px`,
            },
            200
          )
          .removeClass("isToggled")
          .addClass("line-clamp")
          .css("-webkit-line-clamp", numberOfRowsToShow.toString());

        if ($arrowIcon !== undefined) {
          $readMoreButton
            .find("span")
            .removeClass("isToggled")
            .text(readMoreText);
          setRotated(false);
        } else {
          $readMoreButton.removeClass("isToggled").text(readMoreText);
        }
      } else {
        $paragraphToShow
          .removeClass("line-clamp")
          .css("-webkit-line-clamp", "0")
          .animate(
            {
              maxHeight: scrollHeight,
            },
            200,
            function () {
              $(this).css("max-height", "auto").addClass("isToggled");
              $readMoreButton.addClass("isToggled");
            }
          );

        if ($arrowIcon !== undefined) {
          $readMoreButton.find("span").text(readLessText);
          setRotated(true);
        } else {
          $readMoreButton.text(readLessText);
        }
      }
    };
    $readMoreButton.off("click").on("click", clickFunction);

    //Resize function to hide/show the toggle button based on number of rows.
    let resizeTimeout: number | undefined;
    const resizeFunction = function () {
      const scrollHeight = paragraph.scrollHeight;
      if (typeof resizeTimeout === "undefined") {
        resizeTimeout = window.setTimeout(() => {
          resizeTimeout = undefined;

          if (
            ($paragraphToShow.length > 0 && !showOnMobileOnly) ||
            ($paragraphToShow.length > 0 &&
              showOnMobileOnly === true &&
              window.Base.breakpoint === "mobile")
          ) {
            $readMoreButton.toggle(scrollHeight > heightLimit);
          }
        }, 200);
      }
    };
    if (showOnMobileOnly === true) {
      $(window).on("load, breakpointChange", () => {
        if (window.Base.breakpoint === "mobile") {
          resizeTimeout = undefined;
          $paragraphToShow.css({
            "max-height": `${heightLimit}px`,
            "-webkit-line-clamp": numberOfRowsToShow.toString(),
          });
          $paragraphToShow.addClass("line-clamp");
          $readMoreButton.off("click").on("click", clickFunction);
        } else {
          $paragraphToShow.css({
            "max-height": "none",
            "-webkit-line-clamp": "none",
          });

          $paragraphToShow.removeClass("line-clamp");
          resizeTimeout = 1;
          $readMoreButton.removeAttr("style");
          $readMoreButton.off("click");
          return;
        }
      });
    }

    resizeFunction();
    $(window).on("resize", resizeFunction);
  };

  const countChars = (
    originalText: $TSFixMe,
    numberOfCharsToShow: $TSFixMe
  ) => {
    const html = originalText.split("");
    const { length } = html;
    const state = {
      in: false,
      count: 0,
      min: 0,
    };
    for (let i = 0; i < length; i++) {
      const char = html[i];
      if (char === "<") {
        state.in = true;
      } else if (char === ">") {
        state.in = false;
      } else if (!state.in) {
        state.count++;
        if (state.count > numberOfCharsToShow) {
          break;
        } else if (char === ".") {
          state.min = i + 1;
        } else if (char === " ") {
          state.min = i;
        }
      }
    }
    if (state.count > numberOfCharsToShow) {
      return state.min;
    }
    return length;
  };

  const toggleParagraphOnChars = function (
    numberOfCharsToShow: $TSFixMe,
    paragraphToShow: $TSFixMe,
    readMoreButton: $TSFixMe,
    mobileCharsToShow: $TSFixMe
  ) {
    // REQUIRES toggleShowMoreText AND toggleShowLessText TO CHANGE BUTTON TEXT BETWEEN TOGGLE MODES
    const readMoreText = $(readMoreButton).data("toggleShowMoreText");
    const readLessText = $(readMoreButton).data("toggleShowLessText");

    if ($(paragraphToShow).length === 0) {
      return;
    }

    const originalText = $(paragraphToShow).html().trim();
    const totalLength = originalText.length;
    const numOfChars = countChars(
      originalText,
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      $(window).outerWidth() > window.Base.resolutionSizes.mobile &&
        mobileCharsToShow
        ? numberOfCharsToShow
        : mobileCharsToShow
    );
    let defaultSize: $TSFixMe;

    //trim the string to the maximum length
    let trimmedString = "";

    //Resize function to hide/show the toggle button based on number of rows.
    let resizeTimeout: $TSFixMe;
    const resizeFunction = function () {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
          trimmedString = originalText.substr(0, numOfChars);

          if (totalLength > numOfChars) {
            $(readMoreButton).show();
            if (!$(paragraphToShow).hasClass("isToggled")) {
              $(paragraphToShow).html(`${trimmedString} `);
              $(paragraphToShow).css("height", "auto");
              defaultSize = $(paragraphToShow).height();
              $(paragraphToShow).css("height", defaultSize);
            }
          } else {
            $(readMoreButton).hide();
          }

          $(paragraphToShow).css("display", "inline");

          resizeTimeout = null;
        }, 200);
      }
    };

    resizeFunction();
    $(window).on("resize", resizeFunction);

    // Toggle read more/less
    const clickFunction = function () {
      //Need to display inline-block because animate won't work with inline elements.
      $(paragraphToShow).css("display", "inline-block");
      $(readMoreButton).hide();

      if ($(paragraphToShow).hasClass("isToggled")) {
        $(readMoreButton).text(readMoreText);

        $(paragraphToShow).animate(
          {
            height: `${defaultSize}px`,
          },
          200,
          function () {
            $(paragraphToShow).css("display", "inline");
            $(readMoreButton).fadeIn("fast");
            $(paragraphToShow).html(trimmedString);
            $(this).removeClass("isToggled");
            $(readMoreButton).removeClass("isToggled");
          }
        );
      } else {
        $(readMoreButton).text(readLessText);
        $(paragraphToShow).html(originalText);

        $(paragraphToShow).animate(
          {
            height: $(paragraphToShow).get(0).scrollHeight,
          },
          200,
          function () {
            $(paragraphToShow).css("display", "inline");
            $(readMoreButton).fadeIn("fast");
            $(this).height("auto");
            $(this).addClass("isToggled");
            $(readMoreButton).addClass("isToggled");
          }
        );
      }
    };

    $(readMoreButton).off("click").on("click", clickFunction);
  };

  const initialize = function () {
    const $toggleButtons = $(".js-toggle-paragraph");
    if ($toggleButtons.length > 0) {
      $.each($toggleButtons, (index, button) => {
        const $button = $(button);
        toggleParagraphOnChars(
          parseInt($button.data("number-of-chars"), 10), // Number of chars to show in description
          $button.siblings(".cart-product-info__description"), // paragraph to show
          $button, // toggle button,
          parseInt($button.data("number-of-chars-mobile"), 10)
        );
      });
    }
  };

  return {
    toggleParagraph,
    toggleParagraphOnRows,
    toggleParagraphOnChars,
    initialize,
  };
})();
