window.OrderReplace = (() => {
  const initialize = () => {
    addProductReplacement();
    selectNewProductStyle();
  };

  const addProductReplacement = () => {
    $(".js-order-replace__add-product")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        const $clickedProductButton = $(this);
        const $productForReplacement = $clickedProductButton.closest(
          ".js-order-return__product"
        );
        editSelectedProductReplacement($productForReplacement);
        configureProductForReplacement($clickedProductButton);
        changeHeaderText($productForReplacement, false);
      });
  };

  const editSelectedProductReplacement = ($productForReplacement: JQuery) => {
    const $addButton = $productForReplacement.find(
      ".js-order-replace__add-product"
    );
    $productForReplacement
      .find(".js-order-product-replace__edit")
      .off("click")
      .on("click", () => {
        configureProductForReplacement($addButton);
        changeHeaderText($productForReplacement, true);
      });
  };

  const configureProductForReplacement = ($clickedProductButton: JQuery) => {
    const $productForReplacement = $clickedProductButton.closest(
      ".js-order-return__product"
    );
    const $editSelectionButton = $productForReplacement.find(
      ".js-order-product-replace__edit"
    );
    const selectedProductForReplacementClass =
      "js-order-replace__product--selected";

    $productForReplacement.toggleClass(selectedProductForReplacementClass);
    $editSelectionButton.toggle();
    toggleReplaceProductGallery($productForReplacement);
    toggleAddSection($productForReplacement);
    addSelectedSizeAndQuantity($productForReplacement);
  };

  const toggleReplaceProductGallery = (
    $selectedProduct: JQuery,
    reset?: $TSFixMe
  ) => {
    const $productCarousel = $selectedProduct.find(".js-carousel-custom");
    const isReset = reset || null;
    $productCarousel.toggle(isReset);
  };

  const toggleAddSection = ($selectedProduct: JQuery, reset?: $TSFixMe) => {
    const $addSectionReplace = $selectedProduct.find(
      ".js-order-replace__dropdowns"
    );
    const isReset = reset || null;
    $addSectionReplace.toggle(isReset);
  };

  const addSelectedSizeAndQuantity = ($selectedProduct: JQuery) => {
    const $selectedSizeElement = getSizeSelectedForReplace($selectedProduct);
    const $selectedQuantityElement =
      getQuantitySelectedForReplace($selectedProduct);
    $selectedProduct
      .find(".js-order-product-replace__selected-size")
      .text($selectedSizeElement.data("selectName"));
    $selectedProduct
      .find(".js-order-product-replace__selected-quantity")
      .text($selectedQuantityElement.data("selectName"));

    toggleSelectedSizeAndQuantity($selectedProduct);
  };

  const toggleSelectedSizeAndQuantity = (
    $selectedProduct: JQuery,
    isVisible?: $TSFixMe
  ) => {
    const $sizeAndQuantiyContainer = $selectedProduct.find(
      ".js-order-replace__selected-details"
    );

    $sizeAndQuantiyContainer.toggle(isVisible);
  };

  const changeHeaderText = (
    $selectedProduct: JQuery,
    showOriginal: $TSFixMe
  ) => {
    const $selectedProducHeader = $selectedProduct.find(
      ".js-order-replace__header"
    );
    $selectedProducHeader
      .find(".js-order-replace__header-text-original")
      .toggle(showOriginal);
    $selectedProducHeader
      .find(".js-order-replace__header-text-selected")
      .toggle(!showOriginal);
  };

  const selectNewProductStyle = () => {
    $(".js-order-replace__product-style")
      .not(".slick-active")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        const $clickedStyleElement = $(this);
        const styleUrl = $clickedStyleElement.attr("href");
        const quantity = $clickedStyleElement.data("entry-quantity");

        void $.ajax({
          url: `/p/${styleUrl}.json?maxNumberAvailableForReplace=${quantity}`,
          type: "GET",
          dataType: "html",
          success(data) {
            let dataObject;
            if (data) {
              try {
                dataObject = JSON.parse(data);
                updateSizesDropdown(dataObject, $clickedStyleElement);
                updateSelectedProductDetails(dataObject, $clickedStyleElement);
              } catch (error) {
                alert(error);
              }
            }
          },
        }).always(() => {
          activateSelectedProductStyle($clickedStyleElement);
        });
      });
  };

  const activateSelectedProductStyle = ($clickedStyleElement: JQuery) => {
    $clickedStyleElement
      .closest(".js-carousel-custom")
      .find(".style-selection--selected")
      .removeClass("style-selection--selected");
    $clickedStyleElement.find("img").addClass("style-selection--selected");
  };

  const updateSelectedProductDetails = (
    productData: $TSFixMe,
    $clickedStyleElement: JQuery
  ) => {
    const $selectedProduct = $clickedStyleElement.closest(
      ".js-order-replace__product"
    );
    const $replaceProductContainer = $selectedProduct.find(
      ".js-order-replace__product-container"
    );
    const replaceProductTemplate = $(
      "#js-order-replace__replace-product-details-template"
    ).html();
    $replaceProductContainer.html(
      window.Mustache.render(replaceProductTemplate, productData)
    );

    initImageZoom($selectedProduct);
  };

  const showReplaceButton = ($selectedProduct: JQuery) => {
    const $showReplacementButton = $selectedProduct.find(
      ".js-order-replace__button"
    );
    $showReplacementButton.slideDown();

    $showReplacementButton.off("click").on("click", () => {
      showReplacementContent($selectedProduct, $showReplacementButton);
    });

    initImageZoom($selectedProduct);
  };

  const showReplacementContent = (
    $selectedProduct: JQuery,
    $showReplacementButton: JQuery
  ) => {
    const $replacementProductSection = $selectedProduct.find(
      ".js-order-replace__product"
    );
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    $replacementProductSection.slideDown().addClass("animate", () => {
      $showReplacementButton.addClass("hidden");
    });

    initReplaceProductGallery($selectedProduct);
    initSizeDropdownForReplacementProduct($replacementProductSection);
    initCancelReplace($selectedProduct);
  };

  const initReplaceProductGallery = ($selectedProduct: JQuery) => {
    const $productCarousel = $selectedProduct.find(".js-carousel-custom");
    if (!$productCarousel.hasClass("slick-initialized")) {
      window.Carousel.customCarousel($productCarousel);
    }
  };

  const initCancelReplace = ($selectedProduct: JQuery) => {
    const $cancelReplaceButton = $selectedProduct.find(
      ".js-order-replace__cancel"
    );

    $cancelReplaceButton.off("click").on("click", () => {
      cancelReplace($selectedProduct, true);
    });
  };

  const resetQuantityDropdownForReplaceProduct = (
    replacementProductSection: $TSFixMe
  ) => {
    const $replaceQuantityDropdownTemplateContainer = $(
      replacementProductSection
    ).find(".js-replace-dropdown-quantity__mustache-template");

    const $replaceQuantityDropdownDisabledTemplate = $(
      "#js-order-replace__quantity-dropdown-disabled-template"
    ).html();

    if (!$replaceQuantityDropdownDisabledTemplate) {
      return;
    }
    $replaceQuantityDropdownTemplateContainer.html(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-4 arguments, but got 1.
      window.Mustache.render($replaceQuantityDropdownDisabledTemplate)
    );
  };

  const cancelReplace = ($selectedProduct: JQuery, isReset: $TSFixMe) => {
    const $replaceButton = $selectedProduct.find(".js-order-replace__button");
    const $replacementProductContent = $selectedProduct.find(
      ".js-order-replace__product"
    );
    const $sizeDropdown = $replacementProductContent.find(
      ".js-select-box__size"
    );

    resetReplaceContainer($selectedProduct);
    removeReplaceClass($selectedProduct);
    hideReplaceContainer($replacementProductContent);
    $replaceButton.removeClass("hidden").toggle(isReset);

    window.SelectBox.selectBoxClearAllSelection($sizeDropdown);
    resetQuantityDropdownForReplaceProduct($replacementProductContent);
    toggleDisableReplaceButton($replacementProductContent);
  };

  const resetReplaceContainer = ($selectedProduct: JQuery) => {
    toggleSelectedSizeAndQuantity($selectedProduct, false);
    toggleReplaceProductGallery($selectedProduct, true);
    toggleAddSection($selectedProduct, true);
    changeHeaderText($selectedProduct, true);

    const $editSelectionButton = $selectedProduct.find(
      ".js-order-product-replace__edit"
    );
    $editSelectionButton.hide();
  };

  const removeReplaceClass = ($selectedProduct: JQuery) => {
    $selectedProduct.removeClass("js-order-replace__product--selected");
  };

  const hideReplaceContainer = ($replacementProductContent: JQuery) => {
    $replacementProductContent.slideUp().removeClass("animate");
  };

  const updateSizesDropdown = (
    styleData: $TSFixMe,
    $clickedStyleElement: JQuery
  ) => {
    styleData.sizeOptions.filter((option: $TSFixMe) => {
      if (option.stockStatus === "OUTOFSTOCK") {
        option.noStock = true;
        option.lowStock = false;
      } else if (option.stockStatus === "LOWSTOCK") {
        option.lowStock = true;
        option.noStock = false;
      } else {
        option.lowStock = false;
        option.noStock = false;
      }

      return option;
    });

    styleData.sizesOutOfStock = styleData.sizeOptions.every(function (
      option: $TSFixMe
    ) {
      return option.noStock;
    });

    styleData.sizeOptionOneInStock = false;
    if (!styleData.sizesOutOfStock) {
      if (styleData.sizeOptions.length === 1) {
        styleData.sizeOptionOneInStock = true;
        styleData.sizeOptionOneInStockValue = styleData.sizeOptions[0].size;
      }
    }

    const $replacementProductSection = $clickedStyleElement.closest(
      ".js-order-replace__product"
    );
    const $sizeDropdownTemplateContainer = $replacementProductSection.find(
      ".js-replace-dropdown-size__mustache-template"
    );

    const $sizeDropdownTemplate = $(
      "#js-order-replace__size-dropdown-template"
    );

    $sizeDropdownTemplateContainer.html(
      window.Mustache.render($sizeDropdownTemplate.html(), styleData)
    );

    $replacementProductSection
      .closest(".js-order-return__product")
      .removeClass("js-order-replace__product--selected");

    editSelectedProductReplacement($replacementProductSection);
    initSizeDropdownForReplacementProduct($replacementProductSection);
  };

  const isReturnsQuantityValid = ($element: JQuery): boolean => {
    const $returnQuantitySelectBox = $element
      .closest(".js-order-return__product")
      .find(".js-select-box__returns-quantity");

    return Boolean(
      $returnQuantitySelectBox.length === 0 ||
        window.SelectBox.isAnyItemSelected($returnQuantitySelectBox)
    );
  };

  const getReturnsQuantity = (element: $TSFixMe) => {
    const $returnQuantitySelectBox = $(element)
      .closest(".js-order-return__product")
      .find(".js-select-box__returns-quantity");
    return (
      Number(
        window.SelectBox.getSelectedItems($returnQuantitySelectBox).data(
          "returns-quantity"
        )
      ) || 1
    );
  };

  const isSameNumberAvailableForReplace = (
    element: $TSFixMe,
    oneValue: $TSFixMe,
    clampIfAbove: $TSFixMe
  ) => {
    let sameNumberAvailableForReplace = true;
    $(element)
      .closest(".js-order-return__product")
      .find(".js-select-box__item--size")
      .each(function () {
        if (
          Math.min(
            clampIfAbove,
            Number($(this).data("number-available-for-replace"))
          ) !== Math.min(oneValue, clampIfAbove)
        ) {
          sameNumberAvailableForReplace = false;
        }
      });
    return sameNumberAvailableForReplace;
  };

  const validateReplaceSection = ($replacementProductSection: JQuery) => {
    validateReplacementQuantityDropdown($replacementProductSection);
    toggleDisableReplaceButton($replacementProductSection);
  };

  const initSizeDropdownForReplacementProduct = (
    $replacementProductSection: JQuery
  ) => {
    const $sizeDropdown = $($replacementProductSection).find(
      ".js-select-box__size"
    );
    if (isReturnsQuantityValid($replacementProductSection)) {
      const selectedReturnsQuantity = getReturnsQuantity(
        $replacementProductSection
      );
      const $selectedSize = getSizeSelectedForReplace(
        $replacementProductSection
      );

      if ($selectedSize.length > 0) {
        initQuantityDropdownForReplaceProduct(
          $replacementProductSection,
          Math.min(
            Number($selectedSize.data("number-available-for-replace")),
            selectedReturnsQuantity
          ),
          selectedReturnsQuantity
        );
      } else {
        const firstNumberAvailableForReplace = Number(
          $($replacementProductSection)
            .find(".js-select-box__item--size")
            .first()
            .data("number-available-for-replace")
        );
        if (
          isSameNumberAvailableForReplace(
            $replacementProductSection,
            firstNumberAvailableForReplace,
            selectedReturnsQuantity
          )
        ) {
          initQuantityDropdownForReplaceProduct(
            $replacementProductSection,
            Math.min(firstNumberAvailableForReplace, selectedReturnsQuantity),
            selectedReturnsQuantity
          );
        } else {
          resetQuantityDropdownForReplaceProduct($replacementProductSection);
        }
      }
    } else {
      resetQuantityDropdownForReplaceProduct($replacementProductSection);
    }

    window.SelectBox.initialize($($sizeDropdown), (selectItem: $TSFixMe) => {
      const returnsQuantityValid = isReturnsQuantityValid($(selectItem));
      if (returnsQuantityValid) {
        const selectedReturnsQuantity = Number(getReturnsQuantity(selectItem));
        const numberAvailableForReplace = Number(
          $(selectItem).data("number-available-for-replace")
        );
        if (
          !isSameNumberAvailableForReplace(
            $(selectItem),
            numberAvailableForReplace,
            selectedReturnsQuantity
          )
        ) {
          initQuantityDropdownForReplaceProduct(
            $replacementProductSection,
            Math.min(numberAvailableForReplace, selectedReturnsQuantity),
            selectedReturnsQuantity
          );
        }
      }
      validateReplaceSection($replacementProductSection);
    });
    validateReplaceSection($replacementProductSection);
  };

  const initQuantityDropdownForReplaceProduct = (
    replacementProductSection: $TSFixMe,
    numberAvailableForReplace: $TSFixMe,
    selectedReturnsQuantity: $TSFixMe
  ) => {
    const replaceQuantityData = {
      numberAvailableForReplace: numberAvailableForReplace,
      fewer: numberAvailableForReplace < selectedReturnsQuantity,
      singleQuantityChoice: numberAvailableForReplace === 1,
      quantityChoices: [],
    };

    for (let i = 1; i <= numberAvailableForReplace; i += 1) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      replaceQuantityData.quantityChoices.push(i);
    }

    const $replaceQuantityDropdownTemplateContainer = $(
      replacementProductSection
    ).find(".js-replace-dropdown-quantity__mustache-template");

    const $replaceQuantityDropdownTemplate = $(
      "#js-order-replace__quantity-dropdown-template"
    ).html();

    if ($replaceQuantityDropdownTemplate) {
      $replaceQuantityDropdownTemplateContainer.html(
        window.Mustache.render(
          $replaceQuantityDropdownTemplate,
          replaceQuantityData
        )
      );
    }

    window.SelectBox.initialize(
      $($replaceQuantityDropdownTemplateContainer),
      function () {
        toggleDisableReplaceButton(replacementProductSection);
      }
    );
  };

  const toggleDisableReplaceButton = ($replacementProductSection: JQuery) => {
    const $replacementDropdowns =
      $replacementProductSection.find(".js-select-box");
    const $replaceButton = $replacementProductSection.find(
      ".js-order-replace__add-product"
    );
    const isValid = $replacementDropdowns
      .toArray()
      .every(window.SelectBox.isAnyItemSelected);
    $replaceButton
      .prop("disabled", !isValid)
      .toggleClass("form__button--disabled", !isValid);
  };

  const validateReplacementQuantityDropdown = (
    $replacementProductSection: JQuery
  ) => {
    const $replacementQuantityDropdown = $replacementProductSection.find(
      ".js-order-replace__quantity"
    );

    const $selectedSizeItem = getSizeSelectedForReplace(
      $replacementProductSection
    );
    const selectedItemOutOfStock = $selectedSizeItem.hasClass(
      "js-order-replace__out-of-stock"
    );
    const isValid = $selectedSizeItem.length > 0 && !selectedItemOutOfStock;

    $replacementQuantityDropdown.toggleClass(
      "order-return__form-dropdown--disabled",
      !isValid
    );
  };

  //React wants them different than Jquery, this is for react
  const getReplacementEntriesReact = () => {
    const $selectedProducts = $(
      ".js-order-return__product--selected.js-order-replace__product--selected"
    ).find(".js-order-replace__product");

    const replaceProductData = $selectedProducts.map(
      (index, replaceSection) => {
        const $selectedSizeProduct = getSizeSelectedForReplace(
          $(replaceSection)
        );
        const $selectedReplaceQuantity = getQuantitySelectedForReplace(
          $(replaceSection)
        );

        return {
          ean: String($selectedSizeProduct.data("ean")),
          quantity: String($selectedReplaceQuantity.data("select-name")),
          replacedEan: String($(replaceSection).data("ean")),
        };
      }
    );

    return replaceProductData.toArray();
  };

  const getSizeSelectedForReplace = ($replacementProductSection: JQuery) =>
    window.SelectBox.getSelectedItems(
      $replacementProductSection.find(".js-select-box__size")
    );

  const getQuantitySelectedForReplace = ($replacementProductSection: JQuery) =>
    window.SelectBox.getSelectedItems(
      $replacementProductSection.find(".js-order-replace__quantity")
    );

  const initImageZoom = ($selectedProduct: JQuery) => {
    $(".js-pswp-image")
      .off("click")
      .on("click", function () {
        const pswpElement = $(".pswp")[0];
        const $selectedImage = $selectedProduct.find(".js-pswp-image img");
        const items = [];
        const $main = $("main");
        $main.addClass("pswp__light-box-fix");
        items.push({
          src: $selectedImage.data("pswp-src"),
          w: 1200,
          h: 1200,
        });
        const zoomPhotoSwipe = new PhotoSwipe(
          pswpElement,
          window.PhotoSwipeUI_Default,
          items,
          {}
        );

        zoomPhotoSwipe.init();

        zoomPhotoSwipe.listen("close", function () {
          $main.removeClass("pswp__light-box-fix");
        });
      });
  };

  const getReturnsForReact = () => getReplacementEntriesReact();

  return {
    initialize,
    showReplaceButton,
    cancelReplace,
    getReturnsForReact,
  };
})();
