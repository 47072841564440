window.TeamBundleAdminMember = (function () {
  const memberNumberRegExp = /^\d{1,2}$/;
  const memberInitialsRegExp = /^\D{1,4}$/;

  const displayTeamBundleAdminMembers = (adminTeams: $TSFixMe) => {
    const adminTeamsConfigJson = {};
    const $placeholderForMemberData = $(".js-team-bundle__member-placeholder");
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'adminTeamsData' does not exist on type '... Remove this comment to see the full error message
    adminTeamsConfigJson.adminTeamsData = adminTeams;
    const adminTemplate = $("#js-team-bundle-admin__template").html();
    $placeholderForMemberData.append(
      window.Mustache.render(adminTemplate, adminTeamsConfigJson)
    );

    initializeAdminFunctions();
  };

  const initializeAdminFunctions = () => {
    initializeTeamDropdownFunction();
    const $teamAdminNewMemberLightboxButton = $(
      ".js-team-bundle-admin__open-add-member"
    );
    $teamAdminNewMemberLightboxButton.on("click", function () {
      void (async () => {
        await window.Lightbox.openLightbox(this);
        addNewMemberToList();
      })();
    });
    clearTeamMembersFromList();
  };

  const fetchAdminTeamMembers = (selectedTeam: $TSFixMe) => {
    const selectedTeamId = $(selectedTeam).data("select-value");
    const activeProductStyle = $(".js-bundle__product").data("code");

    void $.ajax({
      url: `/teambundle/teammembers/${selectedTeamId}`,
      data: {
        styleCode: activeProductStyle,
      },
      contentType: "text/html; charset=utf-8",
      dataType: "html",
      success(teamMembersHtml) {
        const $parsedTeamMembersView = $(teamMembersHtml);
        $(".js-team-bundle-admin__member-container").html(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'JQuery<any>' is not assignable t... Remove this comment to see the full error message
          $parsedTeamMembersView
        );
        reinitializeMemberListFunctions();
        const $teamItems = $(".js-bundle-admin__team-member-item");

        $teamItems.each((index, element) => {
          const $chosenOption = $(element).find(".select-box__item--selected");
          if ($chosenOption.length === 1) {
            const $selectHeader = $(element).find(
              ".js-bundle__select-size-header"
            );
            $selectHeader.text(
              $chosenOption.find(".select-box-items__item-size").text()
            );
          }
        });
        window.Checkbox.initialize();
      },
      error(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  };

  const initializeTeamDropdownFunction = () => {
    const $adminTeamsDropdown = $(".js-team-bundle-admin__select-team");
    window.SelectBox.initialize($adminTeamsDropdown, fetchAdminTeamMembers);
  };

  const reinitializeMemberListFunctions = () => {
    const $memberSizeDropdown = $(".js-bundle-admin__team-member-item-size");
    window.SelectBox.initialize($memberSizeDropdown, () => {
      window.BundleJsonConfig.teamBundleAdminSetMembersConfig();
      renderSummary();
    });
    window.BundleJsonConfig.teamBundleAdminSetMembersConfig();
    renderSummary();
    window.Accordion.initialize();
  };

  const clearTeamMembersFromList = () => {
    const $clearAllMembersButton = $(
      ".js-team-bundle-admin__clear-all-members"
    );

    $clearAllMembersButton.off("click").on("click", () => {
      const $teamAdminMemberContainer = $(
        ".js-team-bundle-admin__member-container"
      );
      $teamAdminMemberContainer.slideUp(function () {
        $(this).show().empty();
      });
      resetTeamMembersDropdown();
      window.BundleJsonConfig.teamBundleAdminSetMembersConfig();
      renderSummary();
    });
  };

  const renderSummary = () => {
    renderQuantitySummary();
    renderSizesSummary();
    window.TeamBundle.evaluateSteps();
  };

  const renderQuantitySummary = () => {
    const { $membersSummaryWrapper, $membersSummaryElement } =
      window.TeamBundle.getMembersElements();
    const numberTeamMembersAvailable =
      window.TeamBundle.getNumberOfIncludedTeamMembers();
    const membersQuantityText = $membersSummaryElement.data("quantity-text");
    $membersSummaryElement
      .empty()
      .append(`<p>${membersQuantityText}: ${numberTeamMembersAvailable}</p>`);
    $membersSummaryWrapper.addClass("team-bundle__admin-user-summary--visible");
  };

  const getMember = () => {
    return {
      firstname: $(".js-team-bundle-admin__member-firstname").val(),
      lastname: $(".js-team-bundle-admin__member-lastname").val(),
      initials: $(".js-team-bundle-admin__member-initials").val(),
      number: $(".js-team-bundle-admin__member-number").val(),
    };
  };

  const renderSizesSummary = () => {
    const { $membersSummaryElement, $teamAdminMembersList } =
      window.TeamBundle.getMembersElements();
    if ($teamAdminMembersList.length === 0) {
      return;
    }

    const $membersListContainer = $teamAdminMembersList.closest(
      ".js-team-bundle-admin__member-container"
    );

    const availableSizes = $membersListContainer
      .data("product-sizes")
      .split("|");
    let chosenSizes = 0;
    $.each(availableSizes, (_index, size) => {
      const selectedSizes = $(
        `.js-bundle-admin__team-member-item-size .select-box__item--selected[data-select-value="${size}"]`
      ).length;
      if (selectedSizes > 0) {
        chosenSizes += selectedSizes;
        $membersSummaryElement.append(
          `<span><b>${size}:</b> ${selectedSizes}</span>`
        );
      }
    });

    $membersSummaryElement.data("chosen-sizes", chosenSizes);
  };

  const getMembersListTemplate = () => {
    const $membersListContainer = $(".js-team-bundle-admin__member-container");
    const membersListTemplate =
      '<ul class="bundle__team-players js-team-bundle-admin__members-list"></ul>';
    $membersListContainer.append(membersListTemplate);
    return $membersListContainer.find(".js-team-bundle-admin__members-list");
  };

  const addNewMemberToList = () => {
    const $addMemberLightboxButton = $(".js-team-bundle-admin__add-member");
    $(".js-team-bundle-admin__member-firstname")
      .off("input")
      .on("input", function () {
        $(".team-bundle-form__name-wrap").toggleClass(
          "form__message--error",
          $(this).val() === ""
        );
      });
    $(".js-team-bundle-admin__member-lastname")
      .off("input")
      .on("input", function () {
        $(".team-bundle-form__surname-wrap").toggleClass(
          "form__message--error",
          $(this).val() === ""
        );
      });
    $(".js-team-bundle-admin__member-initials")
      .off("input")
      .on("input", function () {
        const isCorrect =
          Boolean($(this).val()) &&
          Boolean(memberInitialsRegExp.exec(String($(this).val())));
        $(".team-bundle-form__initials-wrap").toggleClass(
          "form__message--error",
          !isCorrect
        );
      });
    $(".js-team-bundle-admin__member-number")
      .off("input")
      .on("input", function () {
        const isCorrect =
          Boolean($(this).val()) &&
          Boolean(memberNumberRegExp.exec(String($(this).val())));
        $(".team-bundle-form__number-wrap").toggleClass(
          "form__message--error",
          !isCorrect
        );
      });

    $addMemberLightboxButton.off("click").on("click", () => {
      const member = getMember();
      const { firstname, lastname, initials, number } = member;
      let error = false;

      if (firstname === undefined || firstname === "") {
        $(".team-bundle-form__name-wrap").addClass("form__message--error");
        error = true;
      }
      if (lastname === undefined || lastname === "") {
        $(".team-bundle-form__surname-wrap").addClass("form__message--error");
        error = true;
      }
      if (
        initials === undefined ||
        initials === "" ||
        memberInitialsRegExp.exec(String(initials)) === null
      ) {
        $(".team-bundle-form__initials-wrap").addClass("form__message--error");
        error = true;
      }
      if (
        number === undefined ||
        number === "" ||
        memberNumberRegExp.exec(String(number)) === null
      ) {
        $(".team-bundle-form__number-wrap").addClass("form__message--error");
        error = true;
      }
      if (error) {
        return;
      }

      let { $teamAdminMembersList } = window.TeamBundle.getMembersElements();

      if (!$teamAdminMembersList.length) {
        $teamAdminMembersList = getMembersListTemplate();
      }

      // Check if there are any current members. If there are no members -> Add header
      if (!window.TeamBundle.hasAtLeastOneMember()) {
        const headerTemplate = $(
          "#js-team-bundle-admin__member-list-header-template"
        ).html();
        $teamAdminMembersList.append(headerTemplate);
      }
      const $firstProduct = $(".js-bundle__product").first();
      const { primaryImage, name } = $firstProduct.data();
      const product = { primaryImage, name };
      const data = { member, product };

      const teamMemberRowTemplate = $(
        "#js-team-bundle-admin__member-row-template"
      ).html();
      $teamAdminMembersList.append(
        window.Mustache.render(teamMemberRowTemplate, data)
      );
      window.Lightbox.closeLightboxFromOtherPage();

      reinitializeMemberListFunctions();
      initializeTeamDropdownFunction();
      setTimeout(() => {
        window.Checkbox.initialize();
      });
    });
  };

  const resetTeamMembersDropdown = () => {
    const $teamMembersDropdown = $(".js-team-bundle-admin__select-team");
    const $teamMembersDropdownHeader = $teamMembersDropdown.find(
      ".js-select-box__header-text"
    );

    $teamMembersDropdown
      .find(".select-box__item--selected")
      .removeClass("select-box__item--selected");
    $teamMembersDropdownHeader.text(
      $teamMembersDropdownHeader.data("default-text")
    );
  };

  return {
    displayTeamBundleAdminMembers,
    reinitializeMemberListFunctions,
  };
})();
