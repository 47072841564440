$(() => {
  //TO DO: XD-2472 WE SHOULD EXPLORE global initialize of SelectBox and maybe remove it. Since it is messing with callbacks.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'JQuery<Document>' is not assigna... Remove this comment to see the full error message
  window.SelectBox.initialize($(document));
});

window.SelectBox = (function () {
  const open = function (event: $TSFixMe) {
    const selectBox = event.currentTarget;
    if (!selectBox) {
      return;
    }

    close(selectBox);
    $(selectBox)
      .find(".js-select-box__content")
      .css({ top: $(selectBox).outerHeight() ?? 0 });
    $(selectBox)
      .find(".js-select-box__content")
      .slideToggle("fast", function () {
        if ($(this).hasClass("js-select-box__content--scroll-into-view")) {
          this.scrollIntoView({ behavior: "smooth", inline: "nearest" });
        }
      });
    $(selectBox).toggleClass("select-box--active");
  };

  const close = function (selectBox: $TSFixMe) {
    const $allSelectBoxes = $(".js-select-box");
    $allSelectBoxes.not(selectBox).each((index, eachSelect) => {
      if ($(eachSelect).hasClass("select-box--active")) {
        $(eachSelect)
          .find(".js-select-box__content")
          .css({ top: $(selectBox).height() ?? 0 });
        $(eachSelect).find(".js-select-box__content").slideUp("fast");
        $(eachSelect).removeClass("select-box--active");
      }
    });
  };

  const closeSection = function (section: $TSFixMe) {
    const selectBoxes = $(section)
      .find(".js-select-box")
      .addBack(".js-select-box");
    selectBoxes.each((index, selectBox) => close(selectBox));
  };

  const itemClick = function (item: $TSFixMe, itemClickCallback: $TSFixMe) {
    const selectBox = $(item).parents(".js-select-box");

    const wasSelected = $(item).hasClass("select-box__item--selected");
    const isOnlyOne = $(item).siblings().length === 0;
    $(selectBox)
      .find(".select-box__item--selected")
      .removeClass("select-box__item--selected");
    $(item).addClass("select-box__item--selected");
    $(selectBox)
      .find(".js-select-box__header-text")
      .text($(item).data("select-name"));
    $(selectBox).removeClass("select-box--active");
    $(selectBox).find(".js-select-box__content").slideUp("fast");
    $(selectBox)
      .find(".js-select-box__selected-value")
      .val($(item).data("select-value"));

    if ($(selectBox).hasClass("js-select-box__bundle")) {
      $(selectBox)
        .find(".select-box__header")
        .addClass("select-box__bundle--selected");
    }

    if (itemClickCallback && (!wasSelected || isOnlyOne)) {
      itemClickCallback(item);
    }
  };

  const showError = function (selectBox: $TSFixMe) {
    const errorMessage = $(selectBox).find(".js-select-box__error-message");
    $(errorMessage).show();
    selectBox.addClass("select-box--error");
  };

  const hideError = function (selectBox: $TSFixMe) {
    const errorMessage = $(selectBox).find(".js-select-box__error-message");
    $(errorMessage).hide();
    selectBox.removeClass("select-box--error");
  };

  const setSelectedText = function (selectBox: $TSFixMe, selectText: $TSFixMe) {
    $(selectBox).find(".js-select-box__header-text").text(selectText);
  };

  const initialize = function (section: $TSFixMe, itemClickCallback: $TSFixMe) {
    const selectBoxes = $(section)
      .find(".js-select-box")
      .addBack(".js-select-box");
    $(selectBoxes).off("click", open).on("click", open);

    $(selectBoxes)
      .find(".js-select-box__item")
      .off("click")
      .on("click", function (event) {
        event.stopPropagation();
        itemClick(this, itemClickCallback);

        if (
          $(this)
            .parent(".js-select-box__content")
            .hasClass("js-select-box__size-selection")
        ) {
          const button = $(this)
            .closest(".js-select-box__sibling-button-wrapper")
            .find(".js-select-box__sibling-button");

          if ($(this).hasClass("select-box__item--disabled")) {
            button.addClass("disabled-button");
          } else {
            button.removeClass("disabled-button");
          }
        }
      });

    $(selectBoxes)
      .find(".js-select-box__input-item")
      .off("click")
      .on("click", (event) => {
        event.stopPropagation();
      });

    $(selectBoxes)
      .find(".js-select-box__amount-button")
      .off("click")
      .on("click", function () {
        const item = $(this).siblings(".js-select-box__amount");
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | string[] | und... Remove this comment to see the full error message
        const value = parseInt($(item).val(), 10);
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        event.stopPropagation();

        if (value >= 0) {
          const selectBox = $(item).parents(".js-select-box");
          const previousQuantity = parseInt(
            $(selectBox).find(".js-select-box__header-text").text().trim(),
            10
          );
          $(selectBox)
            .find(".select-box__item--selected")
            .removeClass("select-box__item--selected");
          $(selectBox).find(".js-select-box__header-text").text(value);
          $(selectBox).removeClass("select-box--active");
          $(selectBox).find(".js-select-box__content").slideUp("fast");

          if (itemClickCallback && previousQuantity !== value) {
            itemClickCallback(item);
          }
        }
      });

    selectBoxes.each((index, selectBox) => {
      const SPACEBAR_KEY_CODE = [0, 32];
      const ESCAPE_KEY_CODE = 27;
      const ENTER_KEY_CODE = 13;
      const $selectBox = $(selectBox);
      const $selectBoxHeader = $selectBox.find(".select-box__header");

      $selectBox.attr("role", "button");
      $selectBoxHeader.attr("tabindex", "0");
      $selectBox.attr("aria-haspopup", "listbox");
      $selectBox.find("ul").attr("role", "listbox");
      $selectBox
        .find("li")
        .attr("role", "option")
        .attr("tabindex", "0")
        .off("keydown")
        .on("keydown", (event) => {
          if (event.keyCode === ENTER_KEY_CODE) {
            event.preventDefault();
            $(event.currentTarget).click();
          }
          if (event.keyCode === ESCAPE_KEY_CODE) {
            $(event.currentTarget).closest(".js-select-box").click();
          }
        });
      $selectBoxHeader.off("keydown").on("keydown", (event) => {
        if (SPACEBAR_KEY_CODE.includes(event.keyCode)) {
          event.preventDefault();
          $(event.currentTarget).closest(".js-select-box").click();
        }
        if (event.keyCode === ESCAPE_KEY_CODE) {
          const $selectBox = $(event.currentTarget).closest(".js-select-box");
          if ($selectBox.hasClass("select-box--active")) {
            $selectBox.click();
          }
        }
      });
    });
  };

  const getSelectedItems = (selectBox: $TSFixMe) =>
    $(selectBox)
      .not(".select-box--active")
      .find(".js-select-box__content")
      .find(".select-box__item--selected");

  const isAnyItemSelected = (selectBox: $TSFixMe) =>
    getSelectedItems(selectBox).length > 0;

  const isValidItemSelected = (selectBox: $TSFixMe) =>
    getSelectedItems(selectBox).not(".select-box__item--disabled").length > 0;

  const selectBoxClearAllSelection = (selectBox: $TSFixMe) => {
    $(selectBox)
      .find(".js-select-box__item")
      .removeClass("select-box__item--selected");

    const $selectBoxHeader = $(selectBox).find(".js-select-box__header-text");
    $selectBoxHeader.text($selectBoxHeader.data("initial-value"));
  };

  return {
    initialize,
    showError,
    hideError,
    setSelectedText,
    closeSection,
    close,
    isAnyItemSelected,
    isValidItemSelected,
    getSelectedItems,
    selectBoxClearAllSelection,
  };
})();
