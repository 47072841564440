window.Checkbox = (function () {
  let $checkboxList: JQuery;

  const toggleCheckbox = function () {
    const $checkboxSiblingButton = $(".js-checkbox__disabled-button");

    $.each($checkboxList, (index, checkboxItem) => {
      $(checkboxItem)
        .off("click")
        .on("click", function (event) {
          event.preventDefault();
          const $this = $(this);
          if ($this.hasClass("js-checkbox__newsletter")) {
            const ajaxRequestType = $this.hasClass("checkbox--selected")
              ? "delete"
              : "put";
            void $.ajax({
              url: "/account/newsletter",
              type: ajaxRequestType,
              cache: false,
              contentType: "application/json; charset=utf-8",
              success() {
                $this.toggleClass("checkbox--selected");
              },
              error(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR);
                console.log(textStatus);
                console.log(errorThrown);
              },
            });
          } else {
            $this.toggleClass("checkbox--selected");
            $checkboxSiblingButton.toggleClass("form__button--disabled");
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(this: HTMLElement, _: number, a... Remove this comment to see the full error message
            $checkboxSiblingButton.attr("disabled", (_, attr) => !attr);
            if ($this.hasClass("js-team-bundle__checkbox")) {
              $this
                .parent(".js-bundle-admin__team-member-item")
                .toggleClass("js-bundle-admin__team-member-item--selected");
              const $sizeSelect = $this
                .parent(".js-bundle-admin__team-member-item")
                .find(".js-select-box");
              if (!$this.hasClass("checkbox--selected")) {
                window.SelectBox.selectBoxClearAllSelection($sizeSelect);
              }

              window.TeamBundleAdminMember.reinitializeMemberListFunctions();
            }
            if ($this.hasClass("js-team-bundle__check-all")) {
              const $items = $this
                .parents(".js-team-bundle-admin__members-list")
                .children(".js-bundle-admin__team-member-item");
              const $inputs = $items.children(".js-team-bundle__checkbox");
              const isSelected = $this.hasClass("checkbox--selected");

              $items.toggleClass(
                "js-bundle-admin__team-member-item--selected",
                isSelected
              );
              $inputs.toggleClass("checkbox--selected", isSelected);

              window.TeamBundleAdminMember.reinitializeMemberListFunctions();
            }
          }
        });
    });
  };

  const initialize = function () {
    $checkboxList = $(".js-checkbox");

    if ($checkboxList.length === 0) {
      return;
    }

    toggleCheckbox();
  };

  return {
    initialize,
  };
})();

$(() => {
  window.Checkbox.initialize();
});
