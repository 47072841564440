//http://kenwheeler.github.io/slick/

window.Carousel = (function () {
  const arrow = (classSuffix: string, ariaLabel: string) => `
  <button class="slick-${classSuffix} slick-arrow" aria-label="${ariaLabel}" type="button">
    <svg class="icons"><use href="#slider-arrow" xlink:href="#slider-arrow" /></svg>
  </button>
`;

  const nextArrow = arrow("next", "Next");

  const prevArrow = arrow("prev", "Previous");

  const setColorThemeForSlide = ($slide: JQuery) => {
    const DEFAULT_COLOR_THEME = "black";
    const colorThemeClassPrefix = "slick-slider--color-theme-";
    const themeColor = String($slide.data("colorTheme")) || DEFAULT_COLOR_THEME;
    const colorThemeRegExp = new RegExp(
      `(^|\\s)${colorThemeClassPrefix}\\S+`,
      "gu"
    );
    $slide
      .closest(".slick-slider")
      .removeClass((_: unknown, className: string) =>
        (className.match(colorThemeRegExp) ?? []).join(" ")
      )
      .addClass(`${colorThemeClassPrefix}${themeColor}`);
  };

  const onInit = (_event: $TSFixMe, slick: $TSFixMe) => {
    const INITIAL_SLIDE_INDEX = 0;
    const $initialSlide = $(slick.$slides.get(INITIAL_SLIDE_INDEX));
    setColorThemeForSlide($initialSlide);
  };
  const onBeforeChange = (
    _event: $TSFixMe,
    slick: $TSFixMe,
    _currentSlide: $TSFixMe,
    nextSlide: $TSFixMe
  ) => {
    const $nextSlide = $(slick.$slides.get(nextSlide));
    setColorThemeForSlide($nextSlide);
  };

  const fixAriaAttributes = () =>
    $('.slick-track[role="listbox"]').attr("aria-label", "carousel");

  const oneItemCarousel = function ($carousel: JQuery) {
    if ($carousel.length === 0) {
      return;
    }

    const adaptiveheight =
      $carousel.data("adaptive-height") === true ||
      $carousel.data("adaptive-height") === null ||
      $carousel.data("adaptive-height") === undefined;
    const mobileArrows = $carousel.data("mobile-arrows") === true;

    let isAuto = true;

    $carousel
      .on("init", onInit)
      .on("beforeChange", onBeforeChange)
      .on(
        "afterChange",
        function (event, slick: JQuerySlick, currentSlide: number) {
          if (slick.slideCount === currentSlide + 1 && isAuto === true) {
            (slick.paused = true),
              setTimeout(() => {
                $carousel.slick("slickGoTo", 0);
              }, 6000);

            isAuto = false;
          }
        }
      )
      .on("click", function () {
        isAuto = false;
      })
      .slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: adaptiveheight,
        nextArrow,
        prevArrow,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: window.Base.resolutionSizes.mobile,
            settings: {
              arrows: mobileArrows,
            },
          },
        ],
      });
    fixAriaAttributes();
  };

  const multipleItemCarousel = function ($carousel: JQuery) {
    if ($carousel.length === 0) {
      return;
    }

    $carousel.slick({
      dots: false,
      arrows: true,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow,
      prevArrow,
      responsive: [
        {
          breakpoint: window.Base.resolutionSizes.tablet,
          settings: {
            slidesToShow: 3,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: window.Base.resolutionSizes.mobile,
          settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false,
          },
        },
      ],
    });
    fixAriaAttributes();
  };

  const productImageCarousel = function ($carousel: JQuery<HTMLElement>) {
    // Need this to remove adaptiveHeight because it's lagging the slider when you have overflow visible (special scenario for product page)
    if ($carousel.length) {
      const $carouselParentProduct = $carousel.closest(".js-product");
      const $carouselMiniImages = $carouselParentProduct.find(
        ".js-carousel-thumbnail img"
      );
      const numberOfImages = $carouselMiniImages.length;
      const doesImagesExists = numberOfImages > 0;
      const SLIDES_TO_SHOW = 1;
      let initElement = "";

      if (numberOfImages > 5) {
        initElement = ".js-carousel-thumbnail";

        if ($carousel.data("carousel-id")) {
          const carouselID = String($carousel.data("carousel-id")) || "";
          initElement += `[data-carousel-id='${carouselID}']`;
        }
      }

      const responsive = [
        {
          breakpoint: window.Base.resolutionSizes.mobile,
          settings: {
            dots: true,
            arrows: false,
            variableWidth: true,
            adaptiveHeight: false,
          },
        },
        {
          breakpoint: window.Base.resolutionSizes.tablet,
          settings: {
            centerMode: true,
            centerPadding: "15%",
          },
        },
      ];

      $carousel.slick({
        asNavFor: $(initElement).length > 0 ? initElement : undefined,
        dots: false,
        arrows: doesImagesExists,
        infinite: true,
        speed: 300,
        slidesToShow: SLIDES_TO_SHOW,
        nextArrow,
        prevArrow,
        appendArrows: $carousel.closest(".product__arrows-wrapper"),
        responsive,
      });
      fixAriaAttributes();

      if (numberOfImages > 1) {
        $(".js-product__images").addClass("product__images--multiple");
      }
    }
  };

  const initializeThumbnailCarousel = function (
    $carousel: JQuery<HTMLElement>
  ) {
    if ($carousel.length) {
      const $carouselParentProduct = $carousel.closest(".js-product");
      const $carouselMiniImagesWrap = $carouselParentProduct.find(
        ".js-carousel-thumbnail"
      );
      const $carouselBigImagesWrap = $carouselParentProduct.find(
        ".js-carousel-product"
      );
      const SLIDES_TO_SHOW = 5;
      const hasMoreImages =
        $carouselMiniImagesWrap.find("img").length > SLIDES_TO_SHOW;

      let initElement = ".js-carousel-product";
      if ($carousel.data("carousel-id")) {
        const carouselID = String($carousel.data("carousel-id")) || "";
        initElement += `[data-carousel-id='${carouselID}']`;
      }

      $carousel.slick({
        dots: false,
        asNavFor: initElement,
        focusOnSelect: true,
        slidesToScroll:
          $carouselBigImagesWrap.find("img").length > SLIDES_TO_SHOW
            ? 1
            : SLIDES_TO_SHOW,
        slidesToShow: SLIDES_TO_SHOW,
        infinite: hasMoreImages,
        arrows: hasMoreImages,
        prevArrow,
        nextArrow,
        appendArrows: $carouselParentProduct.find(
          ".product__mini-images-arrows"
        ),
      });

      if (!hasMoreImages) {
        $carouselBigImagesWrap.on(
          "beforeChange",
          (event, slick, slide, nextSlide) => {
            $(event.currentTarget)
              .closest(".js-product")
              .find(".js-carousel-thumbnail .slick-slide")
              .removeClass("slick-current")
              .parent()
              .find(`[data-slick-index="${String(nextSlide)}"]`)
              .addClass("slick-current");
          }
        );
      }

      fixAriaAttributes();
    }
  };

  const customCarousel = function ($carousel: JQuery) {
    if ($carousel.length > 0) {
      const defaultItemsToShow = 5;
      const showArrows = $carousel.data("show-arrows") === true;
      const initialSlide =
        parseInt($carousel.find(".js-carousel-selected").data("index"), 10) ||
        0;
      const itemsInSlick = $carousel.children().length;
      const responsiveArray = [];
      const infinite = $carousel.booleanData("infinite", true);
      const dots = $carousel.booleanData("dots", false);
      const itemsToShow = $carousel.numberData(
        "items-to-show",
        defaultItemsToShow
      );

      try {
        const numberOfItemsToShowOnMobile = $carousel.numberData(
          "mobile-items-to-show"
        );
        responsiveArray.push({
          breakpoint: window.Base.resolutionSizes.mobile,
          settings: {
            slidesToScroll: numberOfItemsToShowOnMobile,
            slidesToShow: numberOfItemsToShowOnMobile,
          },
        });
      } catch (_) {
        // Do nothing
      }
      try {
        const numberOfItemsToShowOnTablet = $carousel.numberData(
          "tablet-items-to-show"
        );
        responsiveArray.push({
          breakpoint: window.Base.resolutionSizes.tablet,
          settings: {
            slidesToScroll: numberOfItemsToShowOnTablet,
            slidesToShow: numberOfItemsToShowOnTablet,
          },
        });
      } catch (_) {
        // Do nothing
      }
      try {
        const numberOfItemsToShowOnSmallDesktop = $carousel.numberData(
          "small-desktop-items-to-show"
        );
        responsiveArray.push({
          breakpoint: window.Base.resolutionSizes.smallDesktop,
          settings: {
            slidesToScroll: numberOfItemsToShowOnSmallDesktop,
            slidesToShow: numberOfItemsToShowOnSmallDesktop,
          },
        });
      } catch (_) {
        // Do nothing
      }

      $carousel.slick({
        dots,
        focusOnSelect: false,
        slidesToScroll: itemsToShow,
        slidesToShow: itemsToShow,
        infinite,
        nextArrow,
        prevArrow,
        arrows: showArrows,
        responsive: responsiveArray,
        initialSlide: itemsToShow >= itemsInSlick ? 0 : initialSlide,
      });
      fixAriaAttributes();
    }
  };

  const initialize = function () {
    $.each(
      $('.js-one-item-carousel:not(".slick-initialized")'),
      (index, element) => {
        oneItemCarousel($(element));
      }
    );

    $.each(
      $('.js-multiple-item-carousel:not(".slick-initialized")'),
      (index, element) => {
        multipleItemCarousel($(element));
      }
    );

    $.each(
      $('.js-carousel-product:not(".slick-initialized")'),
      (index, element) => {
        productImageCarousel($(element));
      }
    );

    $.each(
      $('.js-carousel-thumbnail:not(".slick-initialized")'),
      (index, element) => {
        initializeThumbnailCarousel($(element));
      }
    );

    $.each(
      $('.js-carousel-custom:not(".slick-initialized")'),
      (index, element) => {
        customCarousel($(element));
      }
    );
  };

  return {
    initialize,
    oneItemCarousel,
    multipleItemCarousel,
    customCarousel,
    productImageCarousel,
    initializeThumbnailCarousel,
  };
})();

$(() => {
  window.Carousel.initialize();
});
