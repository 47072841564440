/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { tableMemberData } from "./teamsales-types";

window.Table = (() => {
  let $tableRowsAll: JQuery;

  const tableToggleMobile = () => {
    const $tableToggleElements = $(".js-table__row");

    $tableToggleElements.each(function () {
      const $toggleHeader = $(this).find(".js-table__list-item-header");
      const $content = $(this).find(".js-table__list-item-content");

      $toggleHeader.off("click").on("click", function () {
        $content.slideToggle("fast");
        $toggleHeader.toggleClass("table__list-item-header--toggled");
      });

      window.addEventListener("breakpointChange", () => {
        if (window.Base.breakpoint === "desktop") {
          $content.removeAttr("style");
        }
      });
    });
  };

  const addTablePaginationOnMobile = ($showMoreBtn: JQuery) => {
    const seeMoreBtnActiveClass = "table__see-more--active";
    const tableRowVisible = "table__row--visible";
    const maxRowNum = $showMoreBtn.data("rows-number");
    const $showMoreMemberNumber = $(".js-table__see-more-number");
    let showMoreNumber = $(".js-table").find(".js-table__row").length;

    if (showMoreNumber <= maxRowNum) {
      $showMoreBtn.removeClass(seeMoreBtnActiveClass);
    } else {
      showMoreNumber =
        showMoreNumber - maxRowNum > 0
          ? showMoreNumber - maxRowNum
          : showMoreNumber;
      $showMoreMemberNumber.text(`(${showMoreNumber})`);

      $showMoreBtn.off("click").on("click", () => {
        const tableRowsNumToShow = $showMoreBtn.data("current-rows");
        const $tableRowsAllInner: JQuery =
          $(".js-table").find(".js-table__row");

        $tableRowsAllInner.each((index, element) => {
          if (
            index >= tableRowsNumToShow &&
            index < Number(tableRowsNumToShow) + Number(maxRowNum)
          ) {
            $(element).addClass(tableRowVisible);
          } else {
            $showMoreBtn.data(
              "current-rows",
              Number(tableRowsNumToShow) + Number(maxRowNum)
            );
          }
        });
        const currentRowsNumber = $showMoreBtn.data("current-rows");
        showMoreNumber = $tableRowsAllInner.length - currentRowsNumber;
        $showMoreMemberNumber.text(`(${showMoreNumber})`);
        if (
          Number(tableRowsNumToShow) + Number(maxRowNum) >=
          $tableRowsAllInner.length
        ) {
          $showMoreBtn.removeClass(seeMoreBtnActiveClass);
        }
      });
    }
  };

  const showMoreMobileReset = () => {
    const $showMoreBtn = $(".js-table__see-more");
    $showMoreBtn.data("current-rows", $showMoreBtn.data("rows-number"));
  };

  const searchTable = (tableSearch: JQuery, showMoreMembersMobile: JQuery) => {
    const $searchInput = $(tableSearch);
    const tableRowVisibleClass = "table__row--visible";
    const tableRowHiddenClass = "table__row--hidden";

    $searchInput.prop("disabled", false);
    $searchInput.on("keyup", function () {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const inputValue = $(this).val().toString().toLowerCase();

      $tableRowsAll.map((_index, element) => {
        const searchString =
          window.XxlHelpers.returnAcceptableTypeFromDataAttributes(
            $(element),
            "player"
          );

        if (
          searchString !== undefined &&
          searchString.toLowerCase().indexOf(inputValue) === -1
        ) {
          $(element)
            .addClass("js-table__row--hidden")
            .addClass(tableRowHiddenClass)
            .removeClass(tableRowVisibleClass);
        } else {
          $(element)
            .removeClass("js-table__row--hidden")
            .removeClass(tableRowHiddenClass)
            .addClass(tableRowVisibleClass);
        }
      });

      if (inputValue.length > 0) {
        showMoreMembersMobile.removeClass("table__see-more--active");
      } else {
        showMoreMembersMobile.addClass("table__see-more--active");
        showMoreMobileReset();
        $($tableRowsAll).removeClass(tableRowVisibleClass);
      }

      tablePagination(
        $(".js-table__pagination"),
        $tableRowsAll.not(".js-table__row--hidden")
      );
      window.Lightbox.initialize();
      window.TeamMembers.renderDeleteLightbox();
      window.TeamMembers.renderEditLightbox();
    });
  };

  const searchTableReset = () => {
    const $tableSearch = $(".js-table__search");
    const $noSearchResultWrapper = $(".js-table-search__no-result");

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
    $tableSearch.attr("disabled", true);
    $noSearchResultWrapper.hide();
    $tableSearch.val("");
  };

  const tablePagination = (
    tablePaginationElement: JQuery,
    tableRows: $TSFixMe
  ) => {
    const tableRowsNum = tableRows.length;
    const maxRowNum = Number($(tablePaginationElement).data("max-visible"));
    const paginationActiveClass = "table__pagination--active";
    const maxNuberOfPages = Math.ceil(tableRowsNum / maxRowNum);
    const $emptyTableMessage = $(".js-table__no-members");
    const $noSearchResultWrapper = $(".js-table-search__no-result");

    $emptyTableMessage.hide();

    if (window.Base.breakpoint === "desktop") {
      tablePaginationConfig(
        tableRows,
        tableRowsNum,
        maxRowNum,
        maxNuberOfPages,
        $noSearchResultWrapper
      );

      if (tableRowsNum > maxRowNum) {
        $(tablePaginationElement).addClass(paginationActiveClass);
      } else {
        $(tablePaginationElement).removeClass(paginationActiveClass);
      }
    } else {
      tablePaginationResetMobile();

      if (tableRowsNum === 0) {
        $($noSearchResultWrapper).show();
      } else {
        $($noSearchResultWrapper).hide();
      }

      //setting css style on mobile on search when some of table rows are hidden
      $(tableRows).removeClass("table__alternate");
      $(".js-table__row:not(.js-table__row--hidden):odd").addClass(
        "table__alternate"
      );
    }
    window.Lightbox.initialize();
    window.TeamMembers.renderDeleteLightbox();
    window.TeamMembers.renderEditLightbox();
  };

  const tablePaginationConfig = (
    tableRows: $TSFixMe,
    tableRowsNum: number,
    maxRowNum: number,
    maxNuberOfPages: number,
    noSearchResultWrapper: JQuery
  ) => {
    const tableConfiguration = {
      pageNumber: 0,
      pages: [],
    };

    let pageNumberIndex = 0;

    for (let i = 0; i <= tableRows.length; i += maxRowNum) {
      const pageObject = {};
      const onePageRow = $(tableRows).slice(i, i + Number(maxRowNum));
      pageNumberIndex++;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'pageId' does not exist on type '{}'.
      pageObject.pageId = pageNumberIndex;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'pageElements' does not exist on type '{}... Remove this comment to see the full error message
      pageObject.pageElements = onePageRow;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
      tableConfiguration.pages.push(pageObject);
      tableConfiguration.pageNumber = pageNumberIndex;
    }

    renderPaginatedTable(
      tableConfiguration,
      maxNuberOfPages,
      tableRowsNum,
      maxRowNum,
      noSearchResultWrapper
    );
  };

  const renderPaginatedTable = (
    tableConfiguration: $TSFixMe,
    maxNuberOfPages: number,
    tableRowsNum: number,
    maxRowNum: number,
    noSearchResultWrapper: JQuery
  ) => {
    const $tableTemplateContainer = $(".js-table__template");

    if (tableRowsNum > 0) {
      $tableTemplateContainer.empty();
      $(noSearchResultWrapper).hide();

      $(tableConfiguration.pages).each((index, element) => {
        $tableTemplateContainer.append(
          `<ul class="table__list js-table__list${
            index === 0 ? " table__list--active" : ""
          }" data-table-page="${String(element.pageId)}"></ul>`
        );

        element.pageElements.each((index: number, list: $TSFixMe) => {
          $(
            `.js-table__list[data-table-page="${String(element.pageId)}"]`
          ).append(list);
        });
      });

      initializePagination(maxNuberOfPages, tableRowsNum, maxRowNum);
    } else {
      $(noSearchResultWrapper).show();
    }
  };

  const initializePagination = (
    maxNuberOfPages: number,
    tableRowsNum: number,
    maxRowNum: number
  ) => {
    const $allTableRowsInfo = $(".js-table__all-pages");
    const currentVisibleRowNumber = $(".js-table__pagination").find(
      ".js-table__current-page-number"
    );
    let pageRowsRatio;
    const disabledPaginationButton = "table__pagination-disabled";
    const $tablePaginationElement = $(".js-table__pagination");
    const initialPaginationMin =
      window.XxlHelpers.returnAcceptableTypeFromDataAttributes(
        $tablePaginationElement,
        "min-visible"
      );

    if (Number(initialPaginationMin) + Number(maxRowNum) > tableRowsNum) {
      pageRowsRatio = `${Number(initialPaginationMin)}-${Number(tableRowsNum)}`;
    } else {
      pageRowsRatio = `${Number(initialPaginationMin)}-${Number(maxRowNum)}`;
    }

    currentVisibleRowNumber.text(pageRowsRatio);
    $allTableRowsInfo.text(tableRowsNum);

    $('.js-table__pagination-btn[data-page="next"]').removeClass(
      disabledPaginationButton
    );
    $('.js-table__pagination-btn[data-page="previous"]').addClass(
      disabledPaginationButton
    );

    initializePaginationClickEvent(
      maxNuberOfPages,
      tableRowsNum,
      maxRowNum,
      pageRowsRatio
    );
  };

  const initializePaginationClickEvent = (
    maxNuberOfPages: number,
    tableRowsNum: number,
    maxRowNum: number,
    pageRowsRatio: string
  ) => {
    const $paginationButtons = $(".js-table__pagination-btn");

    $paginationButtons.off("click").on("click", function () {
      const buttonValue =
        window.XxlHelpers.returnAcceptableTypeFromDataAttributes(
          $(this),
          "page"
        );

      const currentPage = Number(
        window.XxlHelpers.returnAcceptableTypeFromDataAttributes(
          $(".js-table__list.table__list--active"),
          "table-page"
        )
      );
      const currentPageActiveClass = "table__list--active";
      const $currentPageNumberWrapper = $(".js-table__pagination").find(
        ".js-table__current-page-number"
      );
      const firstElementIndex = $currentPageNumberWrapper.data("min");
      const lastElementIndex = $currentPageNumberWrapper.data("max");
      let nextPage;

      $(".js-table__list.table__list--active").removeClass(
        currentPageActiveClass
      );

      if (buttonValue === "previous") {
        nextPage = currentPage - 1;
        pageRowsRatio = `${firstElementIndex - maxRowNum}-${
          lastElementIndex - maxRowNum
        }`;
        $currentPageNumberWrapper.data("min", firstElementIndex - maxRowNum);
        $currentPageNumberWrapper.data("max", lastElementIndex - maxRowNum);

        $(`.js-table__list[data-table-page="${nextPage}"]`).addClass(
          currentPageActiveClass
        );
      } else {
        nextPage = Number(currentPage) + 1;

        if (Number(lastElementIndex) + Number(maxRowNum) > tableRowsNum) {
          pageRowsRatio = `${
            Number(firstElementIndex) + Number(maxRowNum)
          }-${Number(tableRowsNum)}`;
        } else {
          pageRowsRatio = `${Number(firstElementIndex) + Number(maxRowNum)}-${
            Number(lastElementIndex) + Number(maxRowNum)
          }`;
        }

        $currentPageNumberWrapper.data(
          "min",
          Number(firstElementIndex) + Number(maxRowNum)
        );
        $currentPageNumberWrapper.data(
          "max",
          Number(lastElementIndex) + Number(maxRowNum)
        );

        $(`.js-table__list[data-table-page="${nextPage}"]`).addClass(
          currentPageActiveClass
        );
      }

      if (nextPage + 1 > maxNuberOfPages) {
        $('.js-table__pagination-btn[data-page="next"]').addClass(
          "table__pagination-disabled"
        );
      } else {
        $('.js-table__pagination-btn[data-page="next"]').removeClass(
          "table__pagination-disabled"
        );
      }

      if (nextPage - 1 <= 0) {
        $('.js-table__pagination-btn[data-page="previous"]').addClass(
          "table__pagination-disabled"
        );
      } else {
        $('.js-table__pagination-btn[data-page="previous"]').removeClass(
          "table__pagination-disabled"
        );
      }

      $(".js-table__pagination")
        .find(".js-table__current-page-number")
        .text(pageRowsRatio);
    });
  };

  const tablePaginationReset = (isDelete: boolean) => {
    const $tablePaginationElement = $(".js-table__pagination");
    const paginationActiveClass = "table__pagination--active";
    const initialPaginationMin = $($tablePaginationElement).data("min-visible");
    const initialPaginationMax = $($tablePaginationElement).data("max-visible");

    $tablePaginationElement.removeClass(paginationActiveClass);

    if (isDelete) {
      $tablePaginationElement
        .find(".js-table__current-page-number")
        .text("0-0");
      $tablePaginationElement.find(".js-table__all-pages").text("0");
    } else {
      $tablePaginationElement
        .find(".js-table__current-page-number")
        .data("min", initialPaginationMin)
        .data("max", initialPaginationMax);
    }
  };

  const tablePaginationResetMobile = () => {
    const allTableRows = $(".js-table__template").find(".js-table__row");
    const $tableTemplateContainer = $(".js-table__template");
    $tableTemplateContainer.empty();
    $tableTemplateContainer.append(
      '<ul class="table__list table__list--active js-table__list js-table__toggle-list"></ul>'
    );

    $(allTableRows).each((index, element) => {
      $(".js-table__list").append(element);
    });

    if ($(".js-table__toggle-list").length) {
      tableToggleMobile();
    }
  };

  const mustacheRender = (teamMemmberData: $TSFixMe) => {
    const $mustacheContainer = $(".js-table__mustache-container");
    const mustacheTemplate = $("#js-table__mustache-template").html();

    searchTableReset();
    showMoreMobileReset();
    tablePaginationReset(false);
    $mustacheContainer.html(
      window.Mustache.render(mustacheTemplate, teamMemmberData)
    );

    if (window.Base.breakpoint !== "desktop") {
      $(".js-table-search__wrapper").show();
      $(".js-table").show();
      $(".js-table__toggle-header").addClass("table__header--toggled");
      $(".js-table__see-more").addClass("table__see-more--active");
    }

    initialize();
    window.Lightbox.initialize();
    window.TeamMembers.renderDeleteLightbox();
    window.TeamMembers.renderEditLightbox();
  };

  const tableContentDelete = () => {
    $(".js-table__template").empty();
    $(".js-table__no-members").show();
    searchTableReset();
    showMoreMobileReset();
    tablePaginationReset(true);
  };

  const removeTeamMember = (memberId: string) => {
    //reset lightbox
    $(".js-lightbox-placeHolder").empty();
    $("body").removeClass("js-no-overflow");

    //remove member
    $(".js-table")
      .find(`.js-table__row[data-id="${String(memberId)}"]`)
      .slideUp("slow", function () {
        $(this).remove();

        //reset pagination
        tablePagination(
          $(".js-table__pagination"),
          $(".js-table").find(".js-table__row")
        );

        //update search object
        $tableRowsAll = $tableRowsAll.filter(
          (index: number, eachRow: $TSFixMe) => {
            if ($(eachRow).data("id") !== memberId) {
              return eachRow;
            }
          }
        );

        //initialize lightbox on new elements
        window.Lightbox.initialize();
        window.TeamMembers.renderDeleteLightbox();
        window.TeamMembers.renderEditLightbox();
      });
  };

  const editTeamMember = (memberId: string, memberData: tableMemberData) => {
    const editedMember = $(".js-table").find(
      `.js-table__row[data-id="${String(memberId)}"]`
    );

    const teamAdminRoleString = window.XxlHelpers.translate(
      "teamsales.members.page.player.role.team.admin"
    );
    const memberRoleString = window.XxlHelpers.translate(
      "teamsales.members.page.player.role.member"
    );

    editedMember.find(".js-team__first-name").text(memberData.firstname);
    editedMember.find(".js-team__surname").text(memberData.lastname);
    editedMember.find(".js-team__initials").text(memberData.initials);
    editedMember.find(".js-team__number").text(memberData.number);
    editedMember
      .find("a.js-team__role")
      .text(memberData.teamAdmin ? teamAdminRoleString : memberRoleString);

    //reset lightbox
    $(".js-lightbox-placeHolder").empty();
    $("body").removeClass("js-no-overflow");

    //update search object
    $tableRowsAll = $tableRowsAll.map<HTMLElement>((index: number, eachRow) => {
      if ($(eachRow).data("id") === memberId) {
        $(eachRow).data(
          "player",
          `${String(memberData.firstname)}, ${String(
            memberData.lastname
          )}, ${String(memberData.number)}, ${String(
            memberData.initials
          )}, ${String(
            memberData.teamAdmin ? teamAdminRoleString : memberRoleString
          )}`
        );
        $(eachRow).attr(
          "data-player",
          `${String(memberData.firstname)}, ${String(
            memberData.lastname
          )}, ${String(memberData.number)}, ${String(
            memberData.initials
          )}, ${String(
            memberData.teamAdmin ? teamAdminRoleString : memberRoleString
          )}`
        );
        $(eachRow).find(".js-team__initials").text(memberData.initials);
        $(eachRow).find(".js-team__first-name").text(memberData.firstname);
        $(eachRow).find(".js-team__surname").text(memberData.lastname);
        $(eachRow).find(".js-team__initials").text(memberData.initials);
        $(eachRow).find(".js-team__number").text(memberData.number);
        $(eachRow)
          .find(".js-team__role")
          .text(memberData.teamAdmin ? teamAdminRoleString : memberRoleString);
      }

      return eachRow;
    });
  };

  const initialize = () => {
    const $tableWrapper = $(".js-table");
    if ($tableWrapper.length === 0) {
      return;
    }

    const $showMoreMembersMobile = $(".js-table__see-more");
    const $tableSearch = $(".js-table__search");
    const $tablePaginationElement = $(".js-table__pagination");

    tableToggleMobile();

    if ($showMoreMembersMobile.length > 0) {
      addTablePaginationOnMobile($showMoreMembersMobile);
    }

    if ($tableSearch.length > 0) {
      $tableRowsAll = $(".js-table").find(".js-table__row");
      searchTable($tableSearch, $showMoreMembersMobile);
    }

    if ($tablePaginationElement.length > 0) {
      const $tableRows = $(".js-table__row");
      tablePagination($tablePaginationElement, $tableRows);

      window.addEventListener("breakpointChange", () => {
        tablePagination($tablePaginationElement, $tableRows);
      });
    }
  };

  return {
    mustacheRender,
    tableContentDelete,
    removeTeamMember,
    editTeamMember,
  };
})();
