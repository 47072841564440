const PRESELECTED_SIZE_QUERY_PARAMETER = "preselectedSize";
const QUANTITY_DATA = "quantity";
const $productDetailsElement = $(".js-product");

$(() => {
  if ($productDetailsElement.length) {
    window.Product.initialize();
  }
});

window.Product = (() => {
  const opt94 = () => {
    if (window.opt94 === true) {
      const opt94cut = document.getElementById("opt94testcut");
      const opt94add = document.getElementById("opt94testinhere");
      opt94add !== null && opt94cut !== null && opt94add.appendChild(opt94cut);
    }
  };
  const initialize = () => {
    //Photoswipe code for product-module.
    let isDragging = false;
    $(".js-product__large-image")
      .mousedown(function () {
        isDragging = false;
      })
      .mousemove(function () {
        isDragging = true;
      })
      .mouseup(function () {
        const wasDragging = isDragging;
        isDragging = false;
        if (!wasDragging) {
          const $currentImage = $(this);
          const $parentElement = $currentImage.closest($productDetailsElement);
          const pswpElement = $parentElement.siblings(".pswp")[0];
          const $images = $parentElement.find<HTMLVideoElement>(
            ".js-product__images img, .js-product__images video"
          );
          const imagesNotClonedBySlickSlider = $images.filter(function () {
            return !$(this).closest(".slick-cloned").length;
          });
          const items: $TSFixMe = [];
          const $main = $("main");
          $main.addClass("pswp__light-box-fix");
          $.each(imagesNotClonedBySlickSlider, function (index, image) {
            if ($(image).is("video")) {
              items.push({
                html: image.outerHTML,
                w: 1200,
                h: 1200,
              });
            } else {
              items.push({
                src: $(image).data("pswp-src"),
                w: 1200,
                h: 1200,
              });
            }
          });

          // define options (if needed)
          const options = {
            index: $currentImage.data("index"),
          };

          // Initializes and opens PhotoSwipe
          const gallery = new PhotoSwipe(
            pswpElement,
            window.PhotoSwipeUI_Default,
            items,
            options
          );
          gallery.init();

          gallery.listen("afterChange", function () {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'container' does not exist on type 'Item'... Remove this comment to see the full error message
            const $currItem = $(gallery.currItem.container);
            const video = $currItem.find<HTMLVideoElement>(
              "video.js-product-video-slider__item-video"
            );
            if (video.length) {
              video[0].currentTime = 0;
              video[0].play();
            }
          });

          gallery.listen("close", function () {
            $main.removeClass("pswp__light-box-fix");
            $(".js-product-video-slider__item-video").each(function () {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              $(this).attr("src", $(this).attr("src"));
            });
          });
        }
      });

    sizeDropdownInit();
    quantityFieldChange();
    updatedDisplayPrice($productDetailsElement);
    setCarouselBackgroundHeight();
    $(window).resize(setCarouselBackgroundHeight);
    opt94();
    if (window.opt189 === true) {
      registerSizeSelectBoxChangeEvents();
    }
    initStickyAddToCartBtn();
  };

  const buttonDisabledClass = "product__button-disable";

  const getAddToCartButton = () =>
    $productDetailsElement.find(".js-product__add-to-cart");

  const initStickyAddToCartBtn = () => {
    const addToCartBtn = document.getElementById("xxl-add-to-cart-btn");
    const stickyAddToCartBtn = document.getElementById(
      "xxl-sticky-add-to-cart-btn"
    );

    if (addToCartBtn === null) {
      console.debug("Cannot find ATC button.");
      return;
    }

    if (stickyAddToCartBtn === null) {
      console.debug("Cannot find sticky ATC button.");
      return;
    }

    const hiddenClassName = "sticky-add-to-cart--hidden";

    const toggleHiddenClass: IntersectionObserverCallback = (entries) => {
      const [firstEntry] = entries;

      if (firstEntry.isIntersecting) {
        stickyAddToCartBtn.classList.add(hiddenClassName);

        return;
      }

      stickyAddToCartBtn.classList.remove(hiddenClassName);
    };

    const observeStickyBtnVisibility = () => {
      const options = {
        rootMargin: "0px",
        threshold: 0,
      };

      const observer = new IntersectionObserver(toggleHiddenClass, options);

      observer.observe(addToCartBtn);
    };

    const isVisibleInViewport = (el: Element) => {
      const { top, left, bottom, right } = el.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      return (
        ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      );
    };

    const setInitialVisibilityOfStickyBtn = () => {
      if (isVisibleInViewport(addToCartBtn)) {
        stickyAddToCartBtn.classList.add(hiddenClassName);
      }
    };

    const createStickyBtnClickHandling = () => {
      stickyAddToCartBtn.addEventListener("click", () => {
        let scrollTimeout: number;

        const scrollCallback = () => {
          clearTimeout(scrollTimeout);
          scrollTimeout = window.setTimeout(function () {
            addToCartBtn.click();
            document.removeEventListener("scroll", scrollCallback);
          }, 100);
        };

        document.addEventListener("scroll", scrollCallback);

        addToCartBtn.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      });
    };

    createStickyBtnClickHandling();
    setInitialVisibilityOfStickyBtn();
    observeStickyBtnVisibility();
  };

  const setOptionalPreselectedSize = () => {
    const url = new URL(window.location.href);
    const preselectedSize = url.searchParams.get(
      PRESELECTED_SIZE_QUERY_PARAMETER
    );
    if (preselectedSize === null) {
      return;
    }
    $productDetailsElement
      .find(".js-product__select-box")
      .find(`li[data-select-name="${preselectedSize}"]`)
      .trigger("click");
  };

  const sizeDropdownInit = () => {
    const $sizeDropdown = $productDetailsElement.find(
      ".js-product__select-box"
    );
    const $addToCartButton = getAddToCartButton();
    const invalidSelectBoxClass = "select-box--invalid";

    //TO DO: XD-2472 WE SHOULD EXPLORE global initialize of SelectBox and maybe remove it. Since it is messing with callbacks.
    //find best ways to use it so we don't have to use timeouts.
    setTimeout(function () {
      window.SelectBox.initialize($sizeDropdown, (selectItem: $TSFixMe) => {
        $(".js-product__error-message").removeClass(
          "product__error-message--visible"
        );
        $(".js-product__info-message").removeClass(
          "product__info-message--visible"
        );
        const $sizeSelected = $(selectItem);
        const $selectBox = $sizeSelected.parents(".js-select-box");
        const selectedProductId = String($sizeSelected.data("product-code"));
        const selectedProductEan = String($sizeSelected.data("product-ean"));
        const stockStatus = String($sizeSelected.data("stock-status"));
        const productQuantityInput = $(".js-product__quantity-box-input");

        const productQuantity =
          productQuantityInput.length !== 0
            ? String($(".js-product__quantity-box-input").val())
            : undefined;
        const isItemOutOfStock = $sizeSelected.hasClass(
          "js-product__disable-buy-button"
        );

        $addToCartButton.toggleClass(buttonDisabledClass, isItemOutOfStock);

        if ($selectBox.hasClass(invalidSelectBoxClass)) {
          if (!$addToCartButton.hasClass(buttonDisabledClass)) {
            $addToCartButton.trigger("click");
          }

          $selectBox.removeClass(invalidSelectBoxClass);
        }

        _fireProductSizeSelectedEvent(
          selectedProductId,
          selectedProductEan,
          stockStatus,
          productQuantity
        );
      });
      setOptionalPreselectedSize();
    }, 10);
  };

  const _fireProductSizeSelectedEvent = (
    productSizeCodeSelected: string,
    productEan: string,
    stockStatus: string,
    productQuantitySelected?: string
  ) => {
    if (window.XxlEvent === undefined) {
      return;
    }

    window.XxlEvent.dispatchEvent(window.XxlEvent.type.XXL_SIZE_SELECTED, {
      productSizeCodeSelected,
      productEan,
      stockStatus,
      productQuantitySelected,
    });
  };

  const setCarouselBackgroundHeight = () => {
    const IMAGE_LOAD_WAIT = 800;

    setTimeout(() => {
      const imagesHeight = $productDetailsElement.find("img").height();

      if (imagesHeight === 0) {
        setTimeout(function () {
          setCarouselBackgroundHeight();
        }, IMAGE_LOAD_WAIT);
      }

      const $productImageBackground = $productDetailsElement.find(
        ".js-product-images-background"
      );
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      $productImageBackground.css("height", imagesHeight);
    }, IMAGE_LOAD_WAIT);
  };

  const _dispatchUpdatePriceDisplayEvent = (isUpdating: boolean) => {
    if (window.XxlEvent == null) {
      return;
    }
    window.XxlEvent.dispatchEvent(
      window.XxlEvent.type.XXL_UPDATE_PRICE_DISPLAY,
      { detail: isUpdating }
    );
  };

  const injectNewHtmlData = (
    $dataRibbonInformation: JQuery | null,
    $dataPriceInformation: JQuery
  ) => {
    if ($dataRibbonInformation !== null) {
      $(".js-product__countdown-and-ribbon")
        .find(".js-product__ribbon-wrap")
        .html($dataRibbonInformation.html());
    }
    const $productPriceWrap = $(".js-product__price-wrap");
    $productPriceWrap.html($dataPriceInformation.html());

    if (window.XxlHelpers.sharedDataHasProducts()) {
      const price = String($dataPriceInformation.data("price"));
      $productPriceWrap.data("price", price);
      const priceFormatted = $dataPriceInformation
        .find("#product-current-price")
        .text();
      $productPriceWrap.data("price-formatted", priceFormatted);
    }
  };

  const updatePriceOnStickyAddToCartBtn = (price: string) =>
    $("#product-sticky-current-price").text(price);

  const updatedDisplayPrice = ($product: JQuery<HTMLElement>) => {
    /** TODO: Expose a function and use that both here and in product-list.ts */
    // Check if user is anonymous
    if (!window.Login.isLoggedIn()) {
      return;
    }

    return window.Cookie.refreshCookieToken().finally(
      () =>
        void $.ajax({
          type: "GET",
          url: `/prices/${$product.data("style")}`,
          headers: {
            Accept: "text/html; charset=UTF-8",
            "Content-Type": "application/json; charset=UTF-8",
          },
          success(html) {
            const $data = $(html);
            const $dataRibbonInformation = $data.filter(
              ".js-product__ribbon-wrap"
            );
            const $dataPriceInformation = $data.filter(
              ".js-product__price-wrap"
            );

            if ($dataPriceInformation !== null) {
              injectNewHtmlData($dataRibbonInformation, $dataPriceInformation);

              const currentPrice = $dataPriceInformation
                .find("#product-current-price")
                .text();
              updatePriceOnStickyAddToCartBtn(currentPrice);

              _dispatchUpdatePriceDisplayEvent(true);

              _dispatchUpdatePriceDisplayEvent(false);
            }
          },
          error(jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
          },
        })
    );
  };

  const _fireProductQuantityChangedEvent = (
    productQuantitySelected: string,
    selectedSizeCode?: string
  ) => {
    if (window.XxlEvent == null) {
      return;
    }
    window.XxlEvent.dispatchEvent(window.XxlEvent.type.XXL_QUANTITY_CHANGED, {
      productQuantitySelected,
      selectedSizeCode,
    });
  };

  const isValidQuantityValue = (quantity: string) =>
    quantity !== "" && quantity.charAt(0) !== "0" && Number(quantity) > 0;
  const MAX_INPUT_DIGITS = 5;
  const getSlicedQuantityValue = (quantity: string) =>
    quantity.slice(0, MAX_INPUT_DIGITS);

  const quantityFieldChange = () => {
    const $addToCartButton = $productDetailsElement.find(
      ".js-product__add-to-cart"
    );
    const $quantityField = $(".js-product__quantity-box-input");

    if ($quantityField.length === 0) {
      return;
    }

    const ensureMaxDigits = (): string => {
      const quantityFieldValue = String($quantityField.val());

      if (quantityFieldValue.length <= MAX_INPUT_DIGITS) {
        const newValue = getSlicedQuantityValue(quantityFieldValue);
        $quantityField.val(newValue);
        $addToCartButton.data(QUANTITY_DATA, newValue);
        return newValue;
      }

      return quantityFieldValue;
    };

    const ensureMinValue = (): string => {
      const quantityValue = ensureMaxDigits();
      const MIN_INPUT_VALUE = $quantityField.attr("min");

      if (
        !isValidQuantityValue(quantityValue) &&
        MIN_INPUT_VALUE !== undefined
      ) {
        $quantityField.val(MIN_INPUT_VALUE);
        $addToCartButton.data(QUANTITY_DATA, MIN_INPUT_VALUE);
        return MIN_INPUT_VALUE;
      }
      $addToCartButton.data(QUANTITY_DATA, quantityValue);
      return quantityValue;
    };

    $quantityField.on("change", function () {
      const productQuantity = ensureMinValue();
      const selectedSizeCode = $(this)
        .closest(".js-product")
        .find(".js-product__select-box .select-box__item--selected")
        .stringData("product-code");
      _fireProductQuantityChangedEvent(productQuantity, selectedSizeCode);
    });

    $quantityField.on("keyup", () => {
      ensureMaxDigits();
    });

    $quantityField.on("keydown", window.KeyboardUtil.validateKeyPressed);
  };

  const registerSizeSelectBoxChangeEvents = () => {
    const $sizeSelectBox = $productDetailsElement.find(".product__select-size");
    const isMobileSize = window.XxlHelpers.isMobileScreenSize();

    if (!isMobileSize || $sizeSelectBox.length === 0) {
      return false;
    }

    const $productOverlay = $productDetailsElement.find(".product__overlay");

    $sizeSelectBox.on("mouseup", function () {
      setTimeout(function () {
        const isActive = $sizeSelectBox.hasClass("select-box--active");
        $productOverlay.toggle(isActive);
      }, 10);
    });

    $productOverlay.on("click", function () {
      $productOverlay.hide();
    });
  };

  return {
    getSlicedQuantityValue,
    initialize,
    isValidQuantityValue,
    updatedDisplayPrice,
  };
})();
