window.ProductCategories = (function () {
  const initialize = () => {
    const $categoriesList = $(".js-product-categories__list");
    const $categoriesButtonSeeAll = $(".js-product-categories__button");

    $categoriesButtonSeeAll.off("click").on("click", () => {
      $categoriesList.addClass("product-categories__list-all");
    });
  };

  return {
    initialize,
  };
})();

$(() => {
  window.ProductCategories.initialize();
});
