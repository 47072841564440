/**
 * Insert cookie policy from cookieinformation.com in <cookie-policy> element
 * NOTE: <cookie-policy> Is a custom element that may appear in faq pages created in Sanity
 */

(() => {
  const cookiePolicyElement = document.querySelector("cookie-policy");
  const { cookieCultureLanguage } = window._sharedData;
  if (cookiePolicyElement !== null) {
    const scriptUrl = "https://policy.app.cookieinformation.com/cid.js";
    const script = document.createElement("script");
    script.setAttribute("id", "CookiePolicy");
    script.dataset.culture = cookieCultureLanguage;
    script.setAttribute("src", scriptUrl);
    script.setAttribute("type", "text/javascript");
    cookiePolicyElement.append(script);
  }
})();
