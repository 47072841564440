$(() => {
  const $listItems = $(".category-list");

  $.each($listItems, (index, categoryListItem) => {
    window.CategoryList.initialize(categoryListItem);
  });
});

window.CategoryList = (function () {
  const initialize = function (categoryListItem: HTMLElement) {
    const description = $(categoryListItem).find(
      ".js-category-list__description"
    );
    const showMoreButton = $(categoryListItem).find(
      ".js-category-list__read-more"
    );
    const numberOfCharsToShow = 200;
    const numberOfCharsInMobile = 150;

    window.Toggle.toggleParagraphOnChars(
      numberOfCharsToShow, // Number of chars to show in description
      description, // paragraph to show
      showMoreButton, // toggle button,
      numberOfCharsInMobile
    );
  };

  return {
    initialize,
  };
})();
