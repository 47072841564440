$(function () {
  if ($(".js-account").length > 0) {
    window.Account.initialize();
  }
});

window.Account = (() => {
  let resizeTimeoutId: number | null = null;
  const CSS_ANIMATION_DELAY = 220;

  const initialize = function () {
    userLogoutInit();
    $(".js-account__orders-header")
      .off("click")
      .on("click", function () {
        const width = $(window).width();
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (width <= window.Base.resolutionSizes.tablet) {
          $(this).toggleClass("account__orders-header--toggled");
          $(this).parent().find(".js-account__orders-list").slideToggle("fast");
          $(".js-account__more-orders").fadeToggle("fast");
        }
      });

    $(".js-account__user-details-header")
      .off("click")
      .on("click", function () {
        const width = $(window).width();
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (width <= window.Base.resolutionSizes.tablet) {
          const $clickedHeader = $(this);
          $clickedHeader.toggleClass("account__user-details-header--toggled");
          $clickedHeader
            .parent()
            .find(".js-account__user-details-list")
            .slideToggle("fast");

          if ($clickedHeader.parents(".team-account").length <= 0) {
            return;
          } else {
            const $main = $(".js-main");
            const mainHeight = Number($main.outerHeight());
            const $content = $clickedHeader
              .parent()
              .find(".account__user-details-list");
            if (
              $clickedHeader.hasClass("account__user-details-header--toggled")
            ) {
              setTimeout(() => {
                const contentHeight = Number($content.outerHeight());
                const heightToSet = mainHeight + contentHeight;

                $main.css("height", `${heightToSet}px`);
              }, CSS_ANIMATION_DELAY);
            } else {
              const contentHeight = Number($content.outerHeight());
              const heightToSet = mainHeight - contentHeight;

              $main.css("height", `${heightToSet}px`);
            }
          }
        }
      });

    $(".js-account__more-orders")
      .off("click")
      .on("click", function () {
        const $button = $(this);
        const url = $button.data("url");
        window.AnimateElement.setLoaderAnimation($button, { isLoading: true });

        void $.get(url, function (orderHtml) {
          const $orderObj = $(orderHtml);
          $(".js-account__orders-list").append(
            $orderObj.find(".js-account__order-item")
          );
          const newButton = $orderObj.find(".js-account__more-orders");
          $button.replaceWith(newButton);
          newButton.show();
          window.Account.initialize();
          window.Lightbox.initialize();
        }).always(() => {
          window.AnimateElement.setLoaderAnimation($button, {
            isLoading: false,
          });
        });
      });

    $(window).resize(function () {
      if (resizeTimeoutId === null) {
        resizeTimeoutId = window.setTimeout(() => {
          resizeTimeoutId = null;
          const width = $(window).width();

          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          if (width >= window.Base.resolutionSizes.tablet) {
            //orders
            $(".js-account__orders-header").removeClass(
              "account__orders-header--toggled"
            );
            $(".js-account__orders-list").css("display", "");
            $(".js-account__more-orders").css("display", "");

            //User details
            $(".js-account__user-details-header").removeClass(
              "account__user-details-header--toggled"
            );
            $(".js-account__user-details-list").css("display", "");
          }
        }, 50);
      }
    });

    if (window.Login.isLoggedIn()) {
      if (needToCompleteProfile()) {
        getMoreUserInfo();
      }
    }
  };

  function clearFavoritesOnLogout() {
    localStorage.removeItem("favorites");
  }
  const logout = (logoutUrl = "/logout"): JQuery.Promise<void> =>
    $.post(logoutUrl).then(() => {
      clearFavoritesOnLogout();
      window.Login.userLoginCheck(true);
      return window.location.assign(window.location.origin);
    });

  const userLogoutInit = () => {
    const $logoutButton = $(".js-account__logout");
    $logoutButton.off("click").on("click", function (e) {
      e.preventDefault();
      const $logoutButton = $(this);
      const logoutUrl = $logoutButton.attr("href");
      window.AnimateElement.setLoaderAnimation($logoutButton, {
        isLoading: true,
      });

      void logout(logoutUrl).always(() => {
        window.AnimateElement.setLoaderAnimation($logoutButton, {
          isLoading: false,
        });
      });
    });
  };

  const needToCompleteProfile = () =>
    window.Cookie.getCookie().teamSalesData?.needToCompleteProfile;

  const getMoreUserInfo = () => {
    const $completeProfileInputField = $(".js-account__complete-profile");

    if ($completeProfileInputField.length > 0) {
      showUserInfoDialog($completeProfileInputField);
    } else {
      const $noClubAssignedInputField = $(".js-account__no-club-assigned");
      showUserInfoDialog($noClubAssignedInputField);
    }
  };

  const showUserInfoDialog = ($lightboxElement: JQuery) => {
    setTimeout(() => {
      void window.Lightbox.openLightbox($lightboxElement);
    }, 600); // 600ms, slightly longer delay then for closing the window in LightBox.js. Otherwise the window might open before the previous window has been closed.
  };

  return {
    initialize,
    getMoreUserInfo,
    logout,
    needToCompleteProfile,
    userLogoutInit,
  };
})();
