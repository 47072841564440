window.PostForm = (() => {
  const checkAndReportValidity = ($currentForm: JQuery<HTMLFormElement>) => {
    if (!$currentForm[0].checkValidity()) {
      $currentForm[0].reportValidity();
      return false;
    }
    const errors = $currentForm
      .find("input[type=email]")
      .toArray()
      .map(({ id: subject, value }: $TSFixMe) => {
        if (!value) {
          return {
            subject,
            message: window._sharedData.translations["form.field.required"],
          };
        } else if (!window.ValidateEmail(value)) {
          return {
            subject,
            message: window._sharedData.translations["form.email.invalid"],
          };
        }
      })
      .filter(Boolean);
    handleFormErrors(errors, $currentForm);
    return errors.length === 0;
  };

  const post = (actionElement: $TSFixMe) => {
    const jsonData = {};
    const $actionElement = $(actionElement);
    const $currentForm = $actionElement
      .closest(".js-post-form")
      .addBack(".js-post-form");
    const sendJson = $currentForm.hasClass("js-post-form-json");
    const $inputFields = $currentForm.find("input,textarea");
    const $postMessages = $currentForm.find(".js-form-post__message");
    const formSuccessMessage = $currentForm.find(".js-post-form__message");

    const $submitButton = $actionElement.hasClass("js-post-form__button")
      ? $actionElement
      : $actionElement.closest(".js-post-form").find(".js-post-form__button");

    const shouldAvoidClose = Boolean($submitButton.data("should-avoid-close"));

    if ($postMessages.length !== 0) {
      $postMessages.remove();
    }

    $inputFields.each((index, element) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      jsonData[element.id] = element.value;
    });

    if (!checkAndReportValidity($currentForm)) {
      return;
    }

    window.AnimateElement.setLoaderAnimation($submitButton, {
      isLoading: true,
    });
    window.AnimateElement.setAnimation($submitButton, "site__button-checkmark");
    $submitButton.prop("disabled", true);

    void $.ajax({
      type: "POST",
      url: $currentForm.attr("action"),
      ...(sendJson
        ? {
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(jsonData),
          }
        : {
            contentType: "application/x-www-form-urlencoded",
            data: jsonData,
          }),
      success() {
        window.AnimateElement.toggleAnimation({
          isActive: true,
          activationClass: "active",
          timeout: { set: false },
        });

        if (formSuccessMessage.length > 0) {
          formSuccessMessage.fadeIn(1000, () => {
            setTimeout(() => {
              postSuccess($currentForm, shouldAvoidClose);
            }, 4000);
          });
        } else {
          postSuccess($currentForm, shouldAvoidClose);
        }
      },
      error(data) {
        console.log(
          `There was an error after POSTing a form. Status: '${data.status}', text: ${data.statusText}`
        );
        const response = data.responseJSON || JSON.parse(data.responseText);
        console.log(`POST response: ${JSON.stringify(response)}`);
        if (response.errors) {
          handleFormErrors(response.errors, $currentForm);
        }
      },
      complete() {
        window.AnimateElement.setLoaderAnimation($submitButton, {
          isLoading: false,
        });
        window.AnimateElement.toggleAnimation({
          isActive: false,
          activationClass: "active",
          timeout: { set: true, length: 3000 },
        });

        $submitButton.prop("disabled", false);
      },
    });
  };

  const postSuccess = ($currentForm: JQuery, avoidClose: $TSFixMe) => {
    if (!avoidClose) {
      window.Lightbox.closeLightboxFromOtherPage();
    }

    if (window.Login.isLoggedIn()) {
      if (window.Account.needToCompleteProfile()) {
        window.Account.getMoreUserInfo();
        return;
      }

      if (window.PostUpdateForm.shouldRedirect($currentForm)) {
        window.PostUpdateForm.redirect($currentForm);
        return;
      }

      window.Login.userLoginCheck(true);
      window.Reward.hideRewardBannerIfSignedUp();
    } else if (window.PostUpdateForm.shouldRedirect($currentForm)) {
      window.PostUpdateForm.redirect($currentForm);
      return;
    }

    window.PostUpdateForm.updateData($currentForm);
    window.PostUpdateForm.updateSuccessMessage($currentForm);
  };

  const handleFormErrors = (
    errors: $TSFixMe,
    $currentForm: JQuery<HTMLFormElement>
  ) => {
    errors.forEach((error: $TSFixMe) => {
      const { subject: elementId, message } = error;
      const errorMessage = `<p class="form__message form__message--error js-form-post__message">
        <svg class="icons">
          <use href="#alert-icon" xlink:href="#alert-icon" />
        </svg>${message}
      </p>`;
      showFormErrors($currentForm, elementId, errorMessage);
    });
  };

  const showFormErrors = (
    $currentForm: JQuery<HTMLFormElement>,
    elementId: $TSFixMe,
    errorMessage: $TSFixMe
  ) => {
    if (elementId) {
      $currentForm.find(`#${elementId}`).after(errorMessage);
    } else {
      const $globalMessageElement = $(".js-form-post__global-error");
      $currentForm.find($globalMessageElement).append(errorMessage);
      $globalMessageElement.show();
    }

    $currentForm
      .find(".js-form-post__message")
      .slideDown()
      .css("display", "inline-flex");
  };

  const initializeClicks = ($currentForms: JQuery<HTMLElement>) => {
    const $submitButtons = $currentForms.find(".js-post-form__button");
    const $inputFields = $currentForms.find("input");

    $currentForms.on("submit", (event: Event) => {
      event.preventDefault();
    });

    $submitButtons
      .off("click")
      .on("click", function (this: JQuery<HTMLElement>) {
        post($(this));
      });

    $inputFields.each(function (index: number, element: HTMLElement) {
      const isRequired = $(element).hasClass("input--required");

      if (isRequired) {
        const $label = $(element).parents(".form-row").find("label");
        $label.addClass("label--required");
      }
    });

    $inputFields.off("keyup").on("keyup", function (this, event) {
      if (event.keyCode === 13 && !$submitButtons.attr("disabled")) {
        post($(this));
      }
    });
  };

  const initialize = () => {
    const $currentForms = $(".js-post-form");

    if (!$currentForms.length) {
      return;
    }

    initializeClicks($currentForms);
  };

  return {
    initialize,
  };
})();

$(() => {
  window.PostForm.initialize();
});
