window.BundleGlobal = {
  bundleDataJSONConfig: {
    bundle: "",
    bundleEan: "",
    groups: [],
    members: [],
    print: [],
  },
  bundleEan: "",
};

window.bundleUserSettingsHelper = {
  id: "",
  products: [],
};

window.bundleServicesHelper = {
  id: "accessories",
  products: [],
};
