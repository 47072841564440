import type { FeatureToggles } from "../../../../../../../react-app/src/global";
import type { TranslationKey } from "../../../../../../../react-app/src/translations";

window.XxlHelpers = {
  /**
   * Request user position
   * @returns {Promise<Position | void>} Promise object with geolocation position
   */
  requestUserPosition: () => {
    const TIME_TO_CACHE_GEOLOCATION = 600000; // 10 minutes
    const options = {
      maximumAge: TIME_TO_CACHE_GEOLOCATION,
    };
    return new Promise((resolve, reject) => {
      return navigator.geolocation.getCurrentPosition(
        resolve,
        undefined,
        options
      );
    });
  },

  /**
   * Resolves a key from the `window._sharedData.translations` object
   * See `script.tag` for how to pass `messages.properties` onto this JS object.
   * @param {TranslationKey} key Translation key to resolve
   * @param {string} [fallback=key] Fallback value if no string found. Defaults to returning the passed key.
   * @returns {string} Translated string
   */
  translate: (key: TranslationKey, fallback: string = key) => {
    const { translations } = window._sharedData;
    return translations[key] ?? fallback;
  },

  /**
   * Dummy empty function for "no operation" ("no-op")
   */
  noop: () => {
    return;
  },

  /**
   * Converts a Base64 encoded data URI into a Blob of specified mimeType
   * @param {string} dataURI Base64 encoded data URI, including the prefix
   * @param {string} mimeType For example `application/pdf`
   * @returns {Blob} A Blob payload
   */
  dataURItoBlob: (dataURI: string, mimeType: string) => {
    const [_prefix, data] = dataURI.split(",");
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: `${mimeType};charset=utf-8;`,
    });
  },

  /**
   * @param {number || string} number Number or number string which must be parsed
   * @returns {number} Parsed number
   */
  parseNumber: (number) => parseInt(number, 10),

  isFeatureToggleEnabled: (key: keyof FeatureToggles): boolean => {
    const { featureToggles } = window._sharedData;
    return Boolean(featureToggles[key]);
  },

  /**
   * Check whether screen size is inside mobile boundry
   * @returns {boolean}
   */
  isMobileScreenSize: () => {
    const { innerHeight, innerWidth } = window;
    const isLandscape = innerWidth >= innerHeight;
    const mobileScreenThreshold = window.Base.resolutionSizes.mobile;
    return (
      innerWidth <= mobileScreenThreshold ||
      (isLandscape && innerHeight <= mobileScreenThreshold)
    );
  },

  /**
   * Check if device is iPhone
   * @returns {boolean}
   */
  isIphoneDevice: () => {
    const { userAgent } = navigator;
    return userAgent.includes("iPhone");
  },

  /**
   * Check if device is Android
   * @returns {boolean}
   */
  isAndroidDevice: () => {
    const { userAgent } = navigator;
    return userAgent.includes("Android");
  },

  isCypress: window.Cypress !== undefined,

  /**
   * Replace \n with <br/>
   * @returns {string}
   */
  replaceNewLineCharactersWithBrElements: (text) =>
    text.replace(/\n+/g, "<br/>"),

  getPreviousPriceLabel: (type = ""): string => {
    if (["PROMOTION", "CAMPAIGN"].includes(type)) {
      return window.XxlHelpers.translate("product.campaign.price");
    }
    if (type === "PREVIOUS_PRICE") {
      return window.XxlHelpers.translate("product.was.price");
    }
    return "";
  },
  returnAcceptableTypeFromDataAttributes: (
    $jqueryElement: JQuery,
    elementDataAttribute: string
  ) =>
    $jqueryElement.data(elementDataAttribute) !== undefined
      ? String($jqueryElement.data(elementDataAttribute))
      : undefined,
  sharedDataHasProducts: () => window._sharedData.products.length > 0,
  setStorageItem: () => {
    return;
  },
};
