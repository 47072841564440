window.Lightbox = (() => {
  const doOpenLightbox = ($lightboxButton: JQuery) => {
    let isUserAllowedToCloseLightbox = true;
    window.Checkbox.initialize();

    if ($(".js-lightbox-cart-product-info").length > 0) {
      window.Carousel.initialize();
      window.SelectBox.initialize($(".lightbox__container")); // TO DO: XD-2472 Check could these be initialized on opening lightbox and not clutering lightbox here
      window.Toggle.initialize();
    }

    const $lightboxOrderReturn = $(".js-lightbox-order-return");
    if ($lightboxOrderReturn.length > 0) {
      $lightboxOrderReturn
        .parents(".js-lightbox-placeHolder")
        .removeAttr("aria-hidden");
      $lightboxButton.parents('[role="dialog"]').attr("aria-hidden", "true");

      window.OrderReturn.initialize();
      window.SelectBox.initialize($(".lightbox__container"));
      window.XxlEvent?.dispatchEvent("xxl-order-return-open");
    }

    if ($(".js-lightbox-complete-profile").length > 0) {
      isUserAllowedToCloseLightbox = false;
      window.SelectBox.initialize($(".lightbox__container"));
      window.TeamBundle.initialize();
    }

    if ($(".js-lightbox__no-club-assigned").length > 0) {
      isUserAllowedToCloseLightbox = false;
      window.Account.userLogoutInit();
    }

    if ($("div.team-account__join-team > .js-lightbox-button").length > 0) {
      window.SelectBox.initialize($(".lightbox__container"));
    }

    if ($(".js-lightbox-login").length > 0) {
      $(".js-lightbox-login #username").focus();
    }

    if ($(".js-lightbox-guide").length > 0) {
      window.XxlEvent?.dispatchEvent("xxl-pdp-guide-open");
    }

    const $thisLightbox = $(".js-lightbox");
    const $buttonInsidelightbox = $thisLightbox.find<
      HTMLAnchorElement | HTMLButtonElement
    >(".js-lightbox-button");

    if ($buttonInsidelightbox.length > 0) {
      openLightboxClick($buttonInsidelightbox);
    }

    const $postForm = $thisLightbox.find(".js-post-form");

    if ($postForm.length > 0) {
      window.PostForm.initialize();
    }

    if (isUserAllowedToCloseLightbox) {
      closeLightbox();
      closeLightboxWrapper();
      $(document).bind("keyup", escapeEvent);
    } else {
      $(".js-lightbox__close").remove();
    }
  };

  const openLightboxClick = (
    $lightboxButton: JQuery<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    $lightboxButton.off("click").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      window.LockScreen.lock();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      void openLightbox(this);
    });
  };

  const openLightboxWithLoader = () => {
    const loadingTemplate = wrapTemplateInContainer(`
            <div class="lightbox__wrapper js-lightbox__wrapper"></div>
            <div class="lightbox lightbox--small lightbox__loading-container js-lightbox">
                <img class="lightbox__loading-animation" src="/static/images/XXL-loader.gif" />
            </div>
        `);
    $(".js-lightbox-placeHolder").append($(loadingTemplate));
    window.LockScreen.lock();
  };

  const openLightbox = (
    lightboxButton: HTMLAnchorElement | HTMLButtonElement,
    url?: string
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const result = openLightboxJQuery($(lightboxButton), url);
    return result !== undefined ? result.promise() : Promise.resolve();
  };

  const openLightboxJQuery = (
    $lightboxButton: JQuery<HTMLAnchorElement | HTMLButtonElement>,
    url?: string
  ) => {
    const _url = url ?? ($lightboxButton.data("url") as string | undefined);
    if (_url !== undefined) {
      return $.ajax({
        type: "GET",
        // @ts-expect-error ts-migrate(7041) FIXME: The containing arrow function captures the global ... Remove this comment to see the full error message
        context: this,
        url: _url,
        beforeSend: openLightboxWithLoader,
        success(data) {
          const templateToUse = $lightboxButton.data("template");
          const template = templateToUse ? $(templateToUse).html() : data;
          const $lightboxPlaceHolder = $(".js-lightbox-placeHolder");

          $lightboxPlaceHolder.html(
            window.Mustache.render(wrapTemplateInContainer(template), data)
          );
          doOpenLightbox($lightboxButton);
        },
        error(jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          closeDialog();
        },
      });
    }

    const divSelector = $lightboxButton.data("content-element");
    const divContent = divSelector ? $(divSelector).html() : null;
    if (divContent) {
      $(".js-lightbox-placeHolder").html(wrapTemplateInContainer(divContent));
      doOpenLightbox($lightboxButton);
    }
  };

  const closeLightbox = () => {
    $(".js-lightbox__close")
      .off("click")
      .on("click", function (event) {
        event.preventDefault();
        const $buttonClose = $(this);
        const $lightboxComponent = $buttonClose.parent(".js-lightbox");
        const $lightboxWrapper = $lightboxComponent.siblings(
          ".js-lightbox__wrapper"
        );

        fadeOutLightboxElementsAndReset($lightboxComponent, $lightboxWrapper);
      });
  };

  const closeLightboxWrapper = () => {
    $(".js-lightbox__wrapper")
      .off("click")
      .on("click", function () {
        const $lightboxWrapper = $(this);
        const $lightboxComponent = $lightboxWrapper.siblings(".js-lightbox");

        fadeOutLightboxElementsAndReset($lightboxComponent, $lightboxWrapper);
        window.LockScreen.unlock();
      });
  };

  const closeLightboxFromOtherPage = () => {
    closeDialog();
  };

  const escapeEvent = (e: $TSFixMe) => {
    if (e.key === "Escape") {
      closeDialog();
    }
  };

  const closeDialog = () => {
    const $lightboxComponent = $(".js-lightbox");
    const $lightboxWrapper = $lightboxComponent.siblings(
      ".js-lightbox__wrapper"
    );

    fadeOutLightboxElementsAndReset($lightboxComponent, $lightboxWrapper);
  };

  const initiateInformationPopup = ({
    selectedElement,
    closeFromOutside,
  }: $TSFixMe) => {
    $(selectedElement).addClass("js-lightbox__confirmation-popup");
    $(selectedElement).attr("data-testid", "order-return-popup-confirmation");

    if (closeFromOutside) {
      $(selectedElement).addClass(
        "js-lightbox__confirmation-popup__outside-close"
      );
    }
  };

  const fadeOutLightboxElementsAndReset = (
    $lightboxWrapper: JQuery,
    $lightboxComponent: JQuery
  ) => {
    const $informationPopup = $(".js-lightbox__confirmation-popup");
    const $orderReturnPopup = $(".js-lightbox-order-return");
    if ($orderReturnPopup.length > 0) {
      window.XxlEvent?.dispatchEvent("xxl-order-return-close");
    }
    if ($informationPopup.length > 0) {
      $informationPopup.fadeIn("fast", () => {
        if ($(".js-lightbox__confirmation-popup__outside-close").length > 0) {
          $(document).on("click", () => {
            closePopupFromOutside($informationPopup);
          });
        }

        deactivateInformationPopup();
        window.LockScreen.unlock();
      });
    } else {
      const FADEOUT_TIME = 400;
      $lightboxWrapper.fadeOut(FADEOUT_TIME);
      $lightboxComponent.fadeOut(FADEOUT_TIME, () => {
        window.XxlEvent?.dispatchEvent("xxl-pdp-guide-close");
        $(".js-lightbox-placeHolder").empty();
        window.LockScreen.unlock();
      });

      $(document).unbind("keyup", escapeEvent);
      window.SelectBox.closeSection($(".lightbox__container"));
      return false;
    }
  };

  const closePopupFromOutside = ($informationPopup: JQuery) => {
    if ($informationPopup.length && $informationPopup.is(":visible")) {
      $informationPopup.fadeOut();
    }
  };

  const deactivateInformationPopup = () => {
    const $informationPopup = $(".js-lightbox__confirmation-popup");
    $informationPopup.removeClass(
      "js-lightbox__confirmation-popup js-lightbox__confirmation-popup__outside-close"
    );
  };

  const wrapTemplateInContainer = (template: string) =>
    `<div class="lightbox__container" id="lightbox-container">${template}</div>`;

  const onForgotPasswordEvent = () => {
    // Temporary fake element until react component is used instead of jq lightbox.
    const fakeElement = $<HTMLButtonElement>("<button></button>");
    void openLightboxJQuery(fakeElement, "/resetpasswordlink/form");
  };

  const initialize = () => {
    const $lightboxTriggerButton = $<HTMLAnchorElement | HTMLButtonElement>(
      ".js-lightbox-button"
    );

    $.each($lightboxTriggerButton, (_index, element) => {
      openLightboxClick($(element));
    });

    document.body.addEventListener(
      "xxl-forgot-password",
      onForgotPasswordEvent
    );
  };

  return {
    initialize,
    closeLightboxFromOtherPage,
    openLightbox,
    openLightboxJQuery,
    openLightboxWithLoader,
    initiateInformationPopup,
    deactivateInformationPopup,
  };
})();

$(() => {
  window.Lightbox.initialize();
});
