/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { GiosgProduct } from "../../../../../../react-app/src/global";

window.Tracking = (function () {
  const sendTrackingEvent = function (
    this: JQuery.ClickEvent<HTMLAnchorElement>
  ): void {
    const $this = $(this);
    window.Gtm.productClick($this);

    const trackingURL: unknown = $this.data("clicktrackingurl");
    const targetURL = $this.attr("href");
    if (
      typeof trackingURL === "string" &&
      trackingURL.length > 0 &&
      trackingURL !== targetURL
    ) {
      if (window.XxlHelpers.isCypress) {
        // Synchronous Ajax call to other domains will make Cypress freeze
        return;
      }
      void $.ajax({
        method: "GET",
        url: trackingURL,
        async: false,
      });
    }
  };

  const removeGiosgCart = function () {
    try {
      const { giosg } = window;
      if (typeof giosg !== "undefined") {
        void giosg.api?.shoppingCart.freeze();
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        window.Cookies.set("xxlcart", null);
      }
    } catch (err) {
      console.error("Error in removeGiosgCart", err);
    }
  };

  const submitGiosgCart = function (cart: GiosgProduct[]) {
    try {
      const { giosg } = window;
      if (typeof giosg !== "undefined") {
        giosg.api?.shoppingCart.setCurrency(window._sharedData.siteCurrency);

        if (cart.length === 0) {
          void giosg.api?.shoppingCart.clearCart();
        } else {
          void giosg.api?.shoppingCart.submit(cart);
        }
      }

      window.Cookies.set("xxlcart", JSON.stringify(cart));
    } catch (err) {
      console.error("Error in submitGiosgCart", err);
    }
  };

  const initialize = function () {
    $("body").on("click", ".js-tracking", sendTrackingEvent);

    const $confirmationPage = $(".js-order-confirmation");
    if ($confirmationPage.length > 0) {
      removeGiosgCart();
    }
  };

  const getGiosgCartCookie = function (): GiosgProduct[] {
    const cookiesCart = window.Cookies.get("xxlcart");
    return cookiesCart !== undefined
      ? (JSON.parse(cookiesCart) as GiosgProduct[])
      : [];
  };
  type GiosgProductExtended = {
    productCode: string;
    productName: string;
    quantity: number;
  } & GiosgProduct;
  const addToGiosgCart = function (cartItem: GiosgProductExtended) {
    const cart = getGiosgCartCookie();
    const itemIndex = cart.findIndex(
      (obj: $TSFixMe) => obj.productCode === cartItem.productCode
    );

    if (itemIndex > -1) {
      cart[itemIndex].quantity = cart[itemIndex].quantity + cartItem.quantity;
    } else {
      cart.push(cartItem);
    }

    submitGiosgCart(cart);
  };

  const updateGiosgCart = function (quantity: number, productSize?: string) {
    const cart = getGiosgCartCookie();
    const itemIndex = cart.findIndex(
      (element: $TSFixMe) => element.productCode === productSize
    );

    if (productSize !== undefined) {
      if (quantity > 0 && itemIndex !== -1) {
        cart[itemIndex].quantity = quantity;
      } else {
        cart.splice(itemIndex, 1);
      }
    }

    submitGiosgCart(cart);
  };

  return {
    initialize,
    addToGiosgCart,
    updateGiosgCart,
  };
})();

$(() => {
  window.Tracking.initialize();
});
