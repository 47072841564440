window.Header = (function () {
  function showStickyHeader(): void {
    const reactHeaderElement = document.getElementById("js-react-header");
    const stickyHeaderElement = document.getElementById("js-sticky-header");

    if (reactHeaderElement !== null) {
      reactHeaderElement.classList.remove("header-hidden");
    }
    if (stickyHeaderElement !== null) {
      stickyHeaderElement.classList.remove("header-hidden");
    }
  }
  return {
    showStickyHeader,
  };
})();
