const PAGE_PARAMETER_NAME = "page";

const setPageParameterInUrl = (page: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(PAGE_PARAMETER_NAME, page);
  window.history.replaceState(window.history.state, "", url);
};

const hasVisibleDisplayProperty = (_: number, element: HTMLElement) =>
  $(element).css("display") !== "none";

window.GuideArticlesList = (() => {
  const getGuideArticles = () => $(".js-guide-articles-list");
  const $showMoreButton = $(".js-guide-articles-show-more");

  const getNumberOfArticlesShown = () => {
    const screenWidth = $(window).width();
    if (screenWidth && screenWidth <= window.Base.newResolutionSizes.tablet) {
      return 3;
    } else if (
      screenWidth &&
      screenWidth <= window.Base.newResolutionSizes.desktop
    ) {
      return 6;
    } else {
      return 9;
    }
  };

  const getAllArticles = () => getGuideArticles().children("li");

  const toggleShowMoreBtn = () => {
    const $visibleArticles = getGuideArticles()
      .children("li")
      .filter(hasVisibleDisplayProperty);
    const areAllArticlesVisible =
      $visibleArticles.length === getAllArticles().length;

    $showMoreButton.toggle(!areAllArticlesVisible);
  };

  const getInitialPageNumber = (): number => {
    const pageParameterString = window.Url.getUrlParameter(PAGE_PARAMETER_NAME);
    return (
      (pageParameterString &&
        window.XxlHelpers.parseNumber(pageParameterString)) ||
      1
    );
  };

  const showArticles = (currentPageNumber: number) => {
    const GUIDE_LIST_ITEM = "js-guide-articles-list__item";
    const articleShownByDefault = getNumberOfArticlesShown();
    $(`.${GUIDE_LIST_ITEM}`).css("display", "none");
    $(
      `.${GUIDE_LIST_ITEM}:nth-child(-n+${
        articleShownByDefault * currentPageNumber
      })`
    ).css("display", "block");
  };

  const setupPage = (isNext = false) => {
    const initialPageNumber = getInitialPageNumber();
    const currentPageNumber = isNext
      ? initialPageNumber + 1
      : initialPageNumber;

    if (currentPageNumber > 1) {
      setPageParameterInUrl(currentPageNumber.toString());
      showArticles(currentPageNumber);
    }
    toggleShowMoreBtn();
  };

  const loadMoreButtonClickEvent = () => {
    $showMoreButton.on("click", () => {
      setupPage(true);
    });
  };

  const initialize = () => {
    if (getGuideArticles().length === 0) {
      return;
    }
    setupPage(false);
    loadMoreButtonClickEvent();

    $(".guide-articles-section").resize(() => {
      setupPage(false);
    });

    $(window).resize(() => {
      setupPage(false);
    });
  };

  return {
    initialize,
    setupPage,
  };
})();

$(window.GuideArticlesList.initialize);
