/* eslint-disable @typescript-eslint/no-use-before-define */
import type { ProductData } from "@xxl/pim-api";
import type {
  CartEventData,
  AddToCartEventData,
  AddConfigurableProductToCartEventData,
} from "../../../../../../react-app/src/global";
import type { Configuration as ConfigurableProductConfigurations } from "../../../../../../react-app/src/components/Product/ConfigurableProduct/ConfigurationModal/ConfigurationForm/types";

const SERVICES_ADD_ON_SALES_PERSONALIZED_KEY = "services-add-on-sales";
const QUANTITY_ONE = 1;

type ApiResponseWrapper = {
  response: ServicesResponse[];
};

type ConfigurableProductData = Record<string, Record<string, $TSFixMe>>;

type ServicesResponse = {
  key: string;
  numFound: number;
  productList: ProductData[];
};

function tryParseInt(productPrice: string | undefined, fallback = 0) {
  let priceAsNumber;
  try {
    priceAsNumber = parseInt(productPrice ?? "0");
  } catch (error) {
    console.error("Cannot parse price.", productPrice);
    priceAsNumber = fallback;
  }
  return priceAsNumber;
}

window.Cart = (() => {
  const dispatchServicesExistEvent = () => {
    if (window.XxlEvent === undefined) {
      return;
    }
    window.XxlEvent.dispatchEvent(
      window.XxlEvent.type.XXL_SERVICES_PRODUCT_EXIST,
      { services: true }
    );
  };

  const animateAddToCartLoading = (
    isCartLoading: $TSFixMe,
    loadElement: $TSFixMe
  ) => {
    setMiniCartIconLoading({ isLoading: isCartLoading });
    window.AnimateElement.setLoaderAnimation(loadElement, {
      isLoading: isCartLoading,
    });
  };

  const configurableProductSendToCartFromPDP = (
    configurableProductData: ConfigurableProductData,
    configurations: AddConfigurableProductToCartEventData["configurations"]
  ) => {
    const $productBaseElement = $(".js-product");
    const $selectedSizeElement = $(
      ".js-product__select-box .select-box__item--selected"
    );

    const eanCode = $selectedSizeElement.attr("data-product-ean");
    const storeId = $("#xxl-add-to-cart-btn").data("storeId") as
      | string
      | undefined;
    if (
      window.XxlEvent !== undefined &&
      eanCode !== undefined &&
      configurations.length > 0
    ) {
      const eventData: AddConfigurableProductToCartEventData = {
        parentEan: eanCode,
        configurations,
        graphQLEndpointUrl:
          window._sharedData.configuration.amplifyConfig
            .aws_appsync_graphqlEndpoint,
        graphQLApiKey:
          window._sharedData.configuration.amplifyConfig.aws_appsync_apiKey,
        isClickAndCollect: storeId !== undefined,
        categoryCode: $productBaseElement.attr("data-category-code") ?? "",
        styleCode: $selectedSizeElement.attr("data-product-code") ?? "",
        price: tryParseInt($selectedSizeElement.attr("data-price")),
        brandName: $productBaseElement.attr("data-brand") ?? "",
        imageUrl: $productBaseElement.attr("data-main-image-url") ?? "",
        name: $selectedSizeElement.attr("data-product-name") ?? "",
        salesPrice: tryParseInt($selectedSizeElement.attr("data-price")),
        salesPriceFormatted:
          $selectedSizeElement.attr("data-price-formatted") ?? "",
        sizeName: $selectedSizeElement.attr("data-select-name") ?? "",
      };
      window.XxlEvent.dispatchEvent<AddConfigurableProductToCartEventData>(
        window.XxlEvent.type.XXL_ADD_CONFIGURABLE_PRODUCT_TO_CART,
        eventData
      );
    }
  };
  const configurableProductObjectMapping = (
    productConfigFromReact: ConfigurableProductConfigurations
  ) => {
    const configurableProductData: ConfigurableProductData = {
      configurationsKeyValueMap: {},
    };
    configurableProductData.configurationsKeyValueMap.PRODUCT =
      Object.fromEntries(
        productConfigFromReact.configurations.map((values) => [
          values.qualifier,
          values.value,
        ])
      );
    const configurations: AddConfigurableProductToCartEventData["configurations"] =
      productConfigFromReact.configurations.flatMap(({ ean }) =>
        ean === null ? [] : [{ ean }]
      );
    configurableProductSendToCartFromPDP(
      configurableProductData,
      configurations
    );
  };

  const removeFromCart = (entryNumber: $TSFixMe, callback: $TSFixMe) => {
    const $cartProducts = $(".js-cart-product");

    void $.ajax({
      async: false,
      url: `/cart/${window.XxlHelpers.parseNumber(entryNumber)}`,
      method: "DELETE",
      beforeSend() {
        setMiniCartIconLoading({ isLoading: true });
        callOnEachElement($cartProducts, disableCartProductEdit);
      },
      success(data) {
        setMiniCartIconLoading({ isLoading: false });
        updateCartCount();
        eventStreamRemoveFromCart(data);

        if (callback) {
          callback();
        }
      },
      complete() {
        callOnEachElement($cartProducts, enableCartProductEdit);
      },
    });
  };

  const removeBundleFromCart = (
    productSize: string,
    bundleNumber: string,
    callback?: () => void
  ) => {
    const $cartProducts = $(".js-cart-product");

    void $.ajax({
      async: false,
      url: `/cart/bundles/${window.XxlHelpers.parseNumber(bundleNumber)}`,
      method: "DELETE",
      beforeSend() {
        setMiniCartIconLoading({ isLoading: true });
        callOnEachElement($cartProducts, disableCartProductEdit);
      },
      success() {
        updateCartCount();
        setMiniCartIconLoading({ isLoading: false });
        eventStreamRemoveBundleFromCart(productSize);

        if (callback === undefined) {
          return;
        }

        callback();
      },
      complete() {
        callOnEachElement($cartProducts, enableCartProductEdit);
      },
    });
  };

  function callOnEachElement(
    $elements: JQuery,
    functionToCall: ($arg0: JQuery) => void
  ) {
    $elements.each(function (this: HTMLElement) {
      functionToCall.call(this, $(this));
    });
  }

  function disableCartProductEdit($cartProduct: JQuery) {
    $cartProduct.find(".js-cart-product__remove").prop("disabled", true);
    $cartProduct.find(".js-cart-product__quantity").prop("disabled", true);
    $cartProduct
      .find<HTMLButtonElement>(".js-cart-product__update-quantity")
      .each(function () {
        $(this).prop("disabled", true);
      });
  }

  function enableCartProductEdit($cartProduct: JQuery) {
    $cartProduct.find(".js-cart-product__remove").prop("disabled", false);
    $cartProduct.find(".js-cart-product__quantity").prop("disabled", false);
    $cartProduct
      .find<HTMLButtonElement>(".js-cart-product__update-quantity")
      .each(function () {
        $(this).prop("disabled", false);
      });
  }

  const updateGlobalServicesFlag = () => {
    const addOnSalesNodes = document.getElementsByClassName(
      "js-react-add-on-sales"
    );
    const servicesNode = document.getElementById("js-react-cart-services");
    if (addOnSalesNodes.length > 0 || servicesNode !== null) {
      const servicesElement =
        addOnSalesNodes.length > 0 ? addOnSalesNodes[0] : servicesNode ?? null;

      const servicesQuery =
        servicesElement !== null
          ? {
              numResults: 1,
              strategy: "services",
              styleId: $(servicesElement).attr("data-product"),
              filters: {
                categories: [$(servicesElement).attr("data-category")],
              },
              key: SERVICES_ADD_ON_SALES_PERSONALIZED_KEY,
              price: $(servicesElement).attr("data-price"),
            }
          : undefined;

      const formData = JSON.stringify([servicesQuery].filter(Boolean));

      void $.ajax({
        url: `${window._sharedData.configuration.recommendationsApi.basePath}/sites/${window._sharedData.siteUid}/recommendations/personalize`,
        type: "post",
        cache: false,
        data: formData,
        processData: false,
        contentType: "application/json;charset=UTF-8",
        success: (data: ApiResponseWrapper) => {
          if (Array.isArray(data.response) && data.response.length > 0) {
            data.response.forEach((personalized) => {
              switch (personalized.key) {
                case SERVICES_ADD_ON_SALES_PERSONALIZED_KEY:
                  {
                    const hasServices = personalized.numFound > 0;
                    window.xxlServiceProducts.hasServices = hasServices;
                    hasServices && dispatchServicesExistEvent();
                  }
                  break;
              }
            });
          }
        },
      });
    } else {
      window.xxlServiceProducts.hasServices = false;
    }
  };

  const addToCart = (
    addToCartButton: JQuery<HTMLButtonElement> | HTMLButtonElement,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    const $clickedCartButton = $(addToCartButton);
    const quantity = $clickedCartButton.data("quantity") as string | undefined;
    const storeId = $clickedCartButton.data("storeId") as string | undefined;
    const isClickAndCollectProduct = storeId !== undefined;
    const animateAddToCartBtn = !isClickAndCollectProduct;
    const $productBaseElement = $clickedCartButton.parents(".js-product");
    const $selectBox = $clickedCartButton
      .parent(".js-product__klarna-container")
      .siblings(".js-cart__product-select");
    const $selectedItem = $selectBox.find(".select-box__item--selected");
    const styleCode = $productBaseElement.attr("data-code");
    const brandName = $productBaseElement.attr("data-brand");
    const categoryCode = $productBaseElement.attr("data-category-code");
    const mainImageUrl = $productBaseElement.attr("data-main-image-url");

    const $currentPrice = $productBaseElement.find(".js-product__price-wrap");
    const price = $currentPrice.data("price");
    const priceFormatted = $currentPrice.data("price-formatted");

    const isConfigurableProductAdded = $clickedCartButton.hasClass(
      "js-product__configure"
    );
    const selectedItemExists = Boolean($selectedItem.length > 0);
    const productCode = selectedItemExists
      ? $selectedItem.attr("data-product-code")
      : null;
    const productName = selectedItemExists
      ? $selectedItem.attr("data-product-name")
      : null;
    const sizeName = selectedItemExists
      ? $selectedItem.attr("data-select-name")
      : undefined;
    const priceType = $clickedCartButton.attr("data-price-type");
    const eanCode = selectedItemExists
      ? String($selectedItem.attr("data-product-ean"))
      : null;

    if (Boolean(productCode) === true) {
      const $productOverlay = $productDetailsElement.find(".product__overlay");
      window.XxlHelpers.isMobileScreenSize() && $productOverlay.hide();

      if (isConfigurableProductAdded) {
        dispatchOpenConfigurationEvent();
      } else {
        if (animateAddToCartBtn) {
          window.AnimateElement.setAnimation(
            $clickedCartButton,
            "site__button-checkmark"
          );
        }
        if (
          window.XxlEvent !== undefined &&
          eanCode !== null &&
          quantity !== undefined
        ) {
          const eventData: AddToCartEventData = {
            $productBaseElement,
            brandName,
            categoryCode: categoryCode ?? "",
            eanCode,
            imageUrl: mainImageUrl ?? "",
            priceType: priceType,
            productName: productName ?? "",
            quantity: parseInt(quantity),
            salesPrice: tryParseInt(price),
            salesPriceFormatted: priceFormatted ?? "n/a",
            sizeCode: productCode ?? undefined,
            sizeName,
            styleCode: styleCode ?? "",
            ...(storeId !== undefined && {
              storeId: String(storeId as string | number),
            }),
            onSuccess,
            onError,
          };
          window.XxlEvent.dispatchEvent<AddToCartEventData>(
            window.XxlEvent.type.XXL_ADD_TO_CART,
            eventData
          );
        }
      }
    } else {
      openSizeDropdown($selectBox);
    }
  };

  const setAddToCartClickListener = () => {
    $<HTMLButtonElement>(".js-cart__add")
      .off("click")
      .on("click", function () {
        addToCart($(this));
      });

    $(".js-cart__accessories-add")
      .off("click")
      .on("click", function () {
        const $clickedButton = $(this);
        const $productBaseElement = $clickedButton.parents(
          ".js-cart__accessory"
        );
        const $selectedItem = $productBaseElement
          .find(".js-cart__product-select")
          .find(".select-box__item--selected");
        const selectedItemExists = Boolean($selectedItem.length > 0);

        if (selectedItemExists) {
          const styleCode = $productBaseElement.attr("data-product-style");
          const productCode = $selectedItem.attr("data-product-code");
          const productName = $productBaseElement.attr("data-name");
          const productPrice = $productBaseElement.attr("data-price");
          const priceType = $clickedButton.attr("data-price-type");
          const eanCode = $selectedItem.attr("data-product-ean");
          const categoryCode = $productBaseElement.attr("data-category");
          const brandName = $productBaseElement.attr("data-brand");

          window.AnimateElement.setAnimation(
            $clickedButton,
            "site__button-checkmark"
          );

          if (window.XxlEvent !== undefined && eanCode !== undefined) {
            const priceAsNumber = tryParseInt(productPrice);
            const eventData: AddToCartEventData = {
              $productBaseElement,
              brandName,
              categoryCode: categoryCode ?? "",
              eanCode,
              imageUrl: "", // not needed for accessories, consider updating event type or create new.
              priceType,
              productName: productName ?? "",
              quantity: QUANTITY_ONE,
              salesPrice: priceAsNumber,
              salesPriceFormatted: "", // not needed for accessories, consider updating event type or create new.
              sizeCode: productCode,
              styleCode: styleCode ?? "",
            };
            window.XxlEvent.dispatchEvent<AddToCartEventData>(
              window.XxlEvent.type.XXL_ADD_TO_CART,
              eventData
            );
          }
        } else {
          const $productSelect = $productBaseElement.find(
            ".js-cart__product-select"
          );
          openSizeDropdown($productSelect);
        }
      });
  };

  const openSizeDropdown = ($selectBox: JQuery) => {
    const $productOverlay = $productDetailsElement.find(".product__overlay");
    $productOverlay.on("click", function () {
      $productOverlay.hide();
    });

    setTimeout(() => {
      window.XxlHelpers.isMobileScreenSize() && $productOverlay.show();

      $selectBox.addClass("select-box--invalid");
      $selectBox.trigger("click");
      $selectBox.find(".js-select-box__item").first().trigger("focus");
    }, 1);
  };

  const updateCartQuantity = (
    entryNumber: number,
    productSize: string,
    quantity: number,
    callback: (result: $TSFixMe) => void
  ) => {
    void $.ajax({
      async: false,
      url: `/cart/update/${entryNumber}`,
      method: "PUT",
      data: { quantity },
      success(result) {
        if (result.statusCode === "success") {
          if (result.quantityAdded !== 0) {
            window.Tracking.updateGiosgCart(quantity, productSize);
          }
        }

        if (callback) {
          callback(result);
        }
      },
    });
  };

  const setMiniCartIconLoading = ({ isLoading }: $TSFixMe) => {
    const $basketIcon = $(".js-cart__basket");
    const $addBasketIcon = $(".js-cart__add-basket");

    if (isLoading) {
      $basketIcon.hide();
      $addBasketIcon.show();
    } else {
      $basketIcon.show();
      $addBasketIcon.hide();
    }
  };

  const updateCartCount = (forceServerUpdate = false) => {
    if (forceServerUpdate) {
      window.Cookie.deleteCookie("cartDetails");
    }
    const { totalItems } = window.Cookie.getCartDetailsCookie();
    const cartEventData: CartEventData = {
      count: totalItems,
    };

    window.XxlEvent?.dispatchEvent<CartEventData>(
      window.XxlEvent.type.XXL_CART_UPDATE,
      cartEventData
    );
  };

  const updateTeamAdminEntries = () => {
    $(".js-cart-product-bundle__team-admin-product-quantity").each(function () {
      const $quantityElement = $(this);
      const $teamMembers = $quantityElement
        .closest(".js-cart-product")
        .find(".js-cart-product-bundle__team-player-entry");
      const allSizesIndividualCount: Record<string, $TSFixMe> = {};
      let totalQuantity = 0;

      const updateQuantities = () => {
        $teamMembers.each(function () {
          const $member = $(this);
          let memberQuantity = window.XxlHelpers.parseNumber(
            $member.attr("data-quantity")
          );
          memberQuantity = !isNaN(memberQuantity) ? memberQuantity : 0;
          totalQuantity += memberQuantity;
          updateIndividualSizeQuantity(
            $member.attr("data-size"),
            memberQuantity
          );
        });
      };

      const updateIndividualSizeQuantity = (
        size: $TSFixMe,
        quantity: $TSFixMe
      ) => {
        if (typeof allSizesIndividualCount[size] === "undefined") {
          allSizesIndividualCount[size] = quantity;
          return;
        }

        allSizesIndividualCount[size] += quantity;
      };

      const setTotalQuantityCount = () => {
        const $totalQuantityHeader = $quantityElement.siblings(
          ".js-cart-product-bundle__team-admin-product-total-quantity"
        );
        const totalCountText = `${$totalQuantityHeader.text()}: ${totalQuantity}`;
        $totalQuantityHeader.text(totalCountText);
      };

      const setAllSizesQuantityCount = () => {
        Object.entries(allSizesIndividualCount).forEach(([size, quantity]) => {
          $quantityElement.append(
            `<li><b>${size}:</b> ${window.XxlHelpers.parseNumber(
              quantity
            )} </li>`
          );
        });
      };

      updateQuantities();
      setTotalQuantityCount();
      setAllSizesQuantityCount();
    });
  };

  const updateTeamBundleSettings = () => {
    const $settingsBtns = $(".js-cart-product-bundle__team-settings-btn");

    const getProductsListHeight = ($productsList: JQuery) =>
      $productsList.height() === 0 ? $productsList[0].scrollHeight : 0;

    const getProductsList = ($button: JQuery) =>
      $button.siblings(".js-cart-product-bundle__products").first();

    const toggleArrowAndText = ($button: JQuery) => {
      $button.find("span").toggleClass("expanded");
      $button.find(".js-cart-product-bundle__team-settings").toggle();
    };

    $settingsBtns.off("click").on("click", function () {
      const $clickedButton = $(this);
      const $productsList = getProductsList($clickedButton);

      toggleArrowAndText($clickedButton);
      $productsList.css(
        "height",
        `${window.XxlHelpers.parseNumber(
          getProductsListHeight($productsList)
        )}px`
      );
    });

    $settingsBtns.each(function () {
      const $button = $(this);
      const $productsList = getProductsList($button);
      const maxNrOfRowsToShow = 10;

      if ($productsList.children().length < maxNrOfRowsToShow) {
        const originalTransition = $productsList.css("transition");

        $productsList
          .css("transition", "unset")
          .css(
            "height",
            `${window.XxlHelpers.parseNumber(
              getProductsListHeight($productsList)
            )}px`
          );

        requestAnimationFrame(() => {
          $productsList.css("transition", originalTransition);
        });

        toggleArrowAndText($button);
      }
    });
  };

  const getEAN = (data: $TSFixMe) => {
    if (window.XxlHelpers.sharedDataHasProducts()) {
      const productData: ProductData = data.entry.product;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return window._sharedData.products
        .find((product) => product.id === productData.selectedStyle)
        .sizes.find((size: $TSFixMe) => size.code === productData.code).ean;
    }
    return null;
  };

  const getEventProperties = (data: $TSFixMe) => [
    data.entry.product.selectedStyle,
    data.entry.product.code,
    data.entry.product.ean || getEAN(data),
    data.entry.entryNumber,
    data.entry.product?.brand?.code ?? "",
    data.productPrice,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    data.eventId,
  ];

  const eventStreamRemoveFromCart = (data: $TSFixMe) => {
    if (!window.Eventstream) {
      return;
    }
    window.Eventstream.removeFromCart(...getEventProperties(data));
  };

  const eventStreamRemoveBundleFromCart = (productSize: string) => {
    if (!window.Eventstream) {
      return;
    }
    const $bundleOrderLine = $(
      `.js-cart-product[data-id='${window.XxlHelpers.parseNumber(
        productSize
      )}']`
    );
    const $bundleProducts = $bundleOrderLine.find(
      ".js-cart-product-bundle__product"
    );
    const $accessory = $bundleOrderLine.next().find(".js-cart-product");
    window.Eventstream.removeProductFromCart(
      $bundleOrderLine,
      $bundleProducts,
      $accessory
    );
  };

  const dispatchOpenConfigurationEvent = () => {
    window.XxlEvent?.dispatchEvent(
      window.XxlEvent.type.XXL_CONFIG_PRODUCT_OPEN
    );
  };

  const bindReadMoreServiceDescription = function () {
    const $toggleButtons = $(".js-toggle-paragraph");
    if ($toggleButtons.length > 0) {
      $.each($toggleButtons, (index, button) => {
        const $description = $(button).siblings(".cart-service__description");
        window.Toggle.toggleParagraph($description, $(button));
      });
    }
  };

  const initialize = function () {
    setAddToCartClickListener();
    updateCartCount();
    updateTeamAdminEntries();
    updateTeamBundleSettings();
    updateGlobalServicesFlag();
    bindReadMoreServiceDescription();
  };

  return {
    initialize,
    updateCartQuantity,
    removeFromCart,
    removeBundleFromCart,
    updateCartCount,
    configurableProductObjectMapping,
    animateAddToCartLoading,
    disableCartProductEdit,
    enableCartProductEdit,
    openSizeDropdown,
    updateGlobalServicesAndCrossSalesFlags: updateGlobalServicesFlag,
    bindReadMoreServiceDescription,
    setMiniCartIconLoading,
    addToCart,
  };
})();

$(() => {
  window.Cart.initialize();
});
