window.ProductList = (function () {
  const getCurrentPage = () =>
    (window.Url.getUrlParameter("pages") &&
      parseInt(window.Url.getUrlParameter("pages"))) ||
    0;
  let $showMoreButton: JQuery;
  let maxItems: number;

  const updateSearchPath = function (html: $TSFixMe) {
    const newSearchPathElement = $(html).find("#js-product-list__search-path");
    if (newSearchPathElement) {
      const newSearchPath = newSearchPathElement.val();
      if (newSearchPath) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
        $("#js-product-list__search-path")[0].value = newSearchPath;
        return;
      }
    }

    console.error(
      "Unable to update search path. Provided jQuery object or html string doesn't have a value in #js-product-list__search-path"
    );
  };

  const getSearchPath = () => {
    const searchPathFromElement = $("#js-product-list__search-path").val();
    if (
      typeof searchPathFromElement === "string" &&
      searchPathFromElement.length > 0
    ) {
      return searchPathFromElement;
    }
    const { pathname } = window.location;
    // Some pages ends with trailing slash
    const pathnameWithoutTrailingSlash = pathname.replace(/\/$/, "");
    return `${pathnameWithoutTrailingSlash}/products${window.location.search}`;
  };

  const getSearchPathWithoutPage = () => {
    const searchPath = getSearchPath();
    const pageAgnosticSearchPath = searchPath.replace(
      /(&pages?=[\d]+)|(pages?=[\d]+&?)/g,
      ""
    );
    if (pageAgnosticSearchPath.endsWith("?")) {
      return pageAgnosticSearchPath.slice(0, -1);
    }
    return pageAgnosticSearchPath;
  };

  const getFetchMoreUrlAndNewHistoryUrl = () => {
    const nextPage = getCurrentPage() + 1;
    let searchPath = getSearchPathWithoutPage();
    let historyUrl = window.location.pathname + window.location.search;
    if (window.location.search === "") {
      searchPath += `?page=${nextPage}`;
      historyUrl += `?pages=${nextPage}`;
    } else {
      if (searchPath.includes("?")) {
        searchPath += `&page=${nextPage}`;
      } else {
        searchPath += `?page=${nextPage}`;
      }
      if (window.location.search.includes("pages=")) {
        historyUrl = historyUrl.replace(
          `pages=${getCurrentPage()}`,
          `pages=${nextPage}`
        );
      } else {
        historyUrl += `&pages=${nextPage}`;
      }
    }
    return [searchPath, historyUrl];
  };

  const fetchUpdatedPricesHtml = (productCodes: $TSFixMe) => {
    const batchSize = 40;
    const promises = [];
    for (let index = 0; index < productCodes.length; index += batchSize) {
      promises.push(
        $.ajax({
          type: "POST",
          url: "/prices",
          data: JSON.stringify(productCodes.slice(index, index + batchSize)),
          headers: {
            Accept: "text/html; charset=UTF-8",
            "Content-Type": "application/json; charset=UTF-8",
          },
        })
      );
    }

    return Promise.all(promises).then((values) => values.join());
  };

  const updateDOMProductsWithNewPrice = (
    updatedPricesHtml: string,
    $productsWithMultiplePrices: JQuery[]
  ) => {
    const $updatedPricesHtml = $(updatedPricesHtml);

    for (const $product of $productsWithMultiplePrices) {
      const priceDisplay = $updatedPricesHtml.find(
        `[data-id=${$product.data("id")}]`
      );
      if (priceDisplay) {
        $product
          .find(".js-product-list__update-price-ribbon")
          .html(
            priceDisplay.find(".js-product-list__update-price-ribbon").html()
          );
        $product
          .find(".js-product-list__update-price-price")
          .html(
            priceDisplay.find(".js-product-list__update-price-price").html()
          );
      }
    }

    return $productsWithMultiplePrices;
  };

  const updateRemainingProductCount = function ($productList: JQuery) {
    const $productListCount = $(".js-product-list__count");
    const $productItems = $productList.find(".js-product-list__item");
    const remainingProductCount = maxItems - $productItems.length;

    if ($productItems.length < maxItems) {
      $showMoreButton.show();
      $productListCount.text(remainingProductCount);
    } else {
      $showMoreButton.hide();
    }
  };

  const loadMoreProducts =
    ($productList: JQuery) =>
    (
      event: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>
    ) => {
      event.preventDefault();
      const [fetchMoreProductsUrl, newHistoryUrl] =
        getFetchMoreUrlAndNewHistoryUrl();

      window.AnimateElement.setLoaderAnimation(event.currentTarget, {
        isLoading: true,
      });

      const scrollYBeforeAddingProducts = window.pageYOffset;

      $.get(fetchMoreProductsUrl)
        .then((newProductsHtml) => {
          updateSearchPath(newProductsHtml);
          return $(newProductsHtml).find(".js-product-list");
        })
        .then(updateProductPrices)
        .then((newProductsWithUpdatedPriceHtml) => {
          window.history.replaceState(window.history.state, "", newHistoryUrl);
          $productList.append(newProductsWithUpdatedPriceHtml.html());
          updateRemainingProductCount($productList);
          window.Sticky.assignHeight();
          if (window.XxlEvent !== undefined) {
            window.XxlEvent.dispatchEvent("xxl-product-loaded");
          }
          return true;
        })
        .always(() => {
          window.AnimateElement.setLoaderAnimation(event.currentTarget, {
            isLoading: false,
          });
          if (scrollYBeforeAddingProducts) {
            window.scrollTo(0, scrollYBeforeAddingProducts);
          }
        })
        .catch(console.log);
    };

  const updateProductPrices = function ($productList: JQuery) {
    const $productsWithMultiplePrices = $productList.find(
      ".js-product-list__update-price-item a"
    );

    if (!window.Login.isLoggedIn() || !$productsWithMultiplePrices.length) {
      return Promise.resolve($productList);
    }

    const $productItems = $productsWithMultiplePrices
      .toArray()
      .map((productItem) => $(productItem));
    const productCodes = $productItems.map(($jQItem) => $jQItem.data("id"));

    return fetchUpdatedPricesHtml(productCodes)
      .then((updatedPricesHtml) =>
        updateDOMProductsWithNewPrice(updatedPricesHtml, $productItems)
      )
      .then(() => $productList);
  };

  const initialize = function () {
    const $productList = $(".js-product-list");
    if (!$productList.length) {
      return;
    }

    $showMoreButton = $(".js-product-list__show-more");
    $showMoreButton.off("click").on("click", loadMoreProducts($productList));
    maxItems = parseInt($showMoreButton.data("product-count"));
    updateRemainingProductCount($productList);
  };

  return {
    getSearchPath,
    initialize,
    updateProductPrices,
    updateRemainingProductCount,
    updateSearchPath,
  };
})();

$(() => {
  window.ProductList.initialize();
});
