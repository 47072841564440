window.PostUpdateForm = (function () {
  const updateData = function ($formElement: JQuery) {
    if (!$formElement.hasClass("js-post-update-form")) {
      return;
    }

    const updateClass = $formElement.data("updateClass");
    const updateUrl = $formElement.data("updateUrl");
    const updateTemplate = $formElement.data("updateTemplate");

    if (!updateClass || !updateUrl || !updateTemplate) {
      return;
    }

    const $updateElement = $(`.${updateClass}`);

    if (!$updateElement.length) {
      return;
    }

    $.ajax({
      type: "GET",
      url: updateUrl,
      success(data) {
        $updateElement.html(
          window.Mustache.render(`{{${String(updateTemplate)}}}`, data)
        );
      },
    });
  };

  const updateSuccessMessage = function ($formElement: JQuery) {
    if (!$formElement.hasClass("js-post-show-success")) {
      return;
    }

    const elementClassToHide = $formElement.data("elementToHide");
    const elementClassToShow = $formElement.data("elementToShow");

    if (elementClassToHide && elementClassToShow) {
      $(`.${elementClassToHide}`).hide();
      $(`.${elementClassToShow}`).show();
    }
  };

  const getRedirect = ($formElement: JQuery) =>
    $formElement.data("post-redirect");

  const shouldRedirect = ($formElement: JQuery) =>
    Boolean(getRedirect($formElement));

  const redirect = ($formElement: JQuery) => {
    if (!shouldRedirect($formElement)) {
      return;
    }

    window.location.replace(getRedirect($formElement));
  };

  return {
    shouldRedirect,
    redirect,
    updateData,
    updateSuccessMessage,
  };
})();
