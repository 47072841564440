window.promotions = (function () {
  const $promotionsForm = $(".js-promotions__form");

  const enableButton = function () {
    const promotionsInput = $promotionsForm.find(".js-promotions__input");

    promotionsInput.on("keyup", function () {
      const disabledButton = $(this)
        .closest($promotionsForm)
        .find(".js-promotions__submit");
      const allInputFields = $(this)
        .closest($promotionsForm)
        .find(".js-promotions__input");
      let isEmpty = false;

      allInputFields.each((index, input) => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if ($(input).val().length === 0) {
          isEmpty = true;
        }
      });

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
      disabledButton.attr("disabled", isEmpty);
    });
  };

  const updateCheckoutSnippet = function () {
    const checkoutWrapper: HTMLElement | null = document.querySelector(
      ".js-checkout-snippet"
    );
    if (checkoutWrapper !== null) {
      window.LoadContent.getContent(checkoutWrapper);
    }
  };

  const suspendCheckoutSnippet = function () {
    window._klarnaCheckout((api: $TSFixMe) => {
      api.suspend();
    });
  };

  const updateSummary = function () {
    suspendCheckoutSnippet();
    $.ajax({
      url: "/checkout/summary/cart",
      type: "GET",
      success(response) {
        $(".js-cart-summary").html(response);
        updateCheckoutSnippet();
      },
      error(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        // TODO show a global error asking the user to reload the page EHD-752
      },
    });
  };

  const submitCoupon = function () {
    const promotionsSubmitButton = $promotionsForm.find(
      ".js-promotions__coupon-submit"
    );

    promotionsSubmitButton.on("click", function (e) {
      e.preventDefault();

      const $clickedButton = $(this);
      const $promotionForm = $clickedButton.closest($promotionsForm);
      const $removeButton = $promotionForm.find(
        ".js-promotions__coupon-remove"
      );
      const inputValue = $promotionForm.find(".js-promotions__input").val();

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      $clickedButton.attr("disabled", true);
      $.ajax({
        url: "/checkout/coupons",
        type: "POST",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        data: {
          couponCode: inputValue,
        },
        success(response) {
          updateSummary();
          $clickedButton.removeAttr("disabled");
          if (response.success) {
            $promotionForm
              .find(".js-promotions__message--success span")
              .html(response.message);
            showMessage(
              $promotionForm.find(".js-promotions__message--success")
            );
            $clickedButton.hide();
            $removeButton.show();
          } else {
            $promotionForm
              .find(".js-promotions__message--error span")
              .html(response.message);
            showMessage($promotionForm.find(".js-promotions__message--error"));
          }
        },
        error() {
          $clickedButton.removeAttr("disabled");
          showMessage(
            $promotionForm.find(".js-promotions__message--unknown-error")
          );
        },
      });
    });
  };

  const removeCoupon = function () {
    const removeButton = $promotionsForm.find(".js-promotions__coupon-remove");

    removeButton.on("click", function (e) {
      e.preventDefault();

      const $clickedButton = $(this);
      const $promotionForm = $clickedButton.closest($promotionsForm);
      const $confirmButton = $promotionForm.find(
        ".js-promotions__coupon-submit"
      );

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      $clickedButton.attr("disabled", true);
      $.ajax({
        url: "/checkout/coupons",
        type: "DELETE",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success(response) {
          updateSummary();
          $clickedButton.removeAttr("disabled");
          if (response.success) {
            $promotionForm.find(".js-promotions__input").val("");
            $promotionForm
              .find(".js-promotions__message--success span")
              .html(response.message);
            showMessage(
              $promotionForm.find(".js-promotions__message--success")
            );
            $clickedButton.hide();
            $confirmButton.show();
          } else {
            $promotionForm
              .find(".js-promotions__message--error span")
              .html(response.message);
            showMessage($promotionForm.find(".js-promotions__message--error"));
          }
        },
        error() {
          $clickedButton.removeAttr("disabled");
          showMessage(
            $promotionForm.find(".js-promotions__message--unknown-error")
          );
        },
      });
    });
  };

  const submitGiftCard = function () {
    const promotionsSubmitButton = $promotionsForm.find(
      ".js-promotions__gift-card-submit"
    );

    promotionsSubmitButton.on("click", function (e) {
      e.preventDefault();
      const $clickedButton = $(this);
      const inputValue = [];
      const removeButton = $($clickedButton)
        .closest($promotionsForm)
        .find(".js-promotions__gift-card-remove");
      const allFormElements = $($clickedButton)
        .closest($promotionsForm)
        .find(".js-promotions__input");

      allFormElements.each((index, element) => {
        inputValue.push($(element).val());
      });

      //TODO
      //ajax call
      //if success
      //promotions-gift-card__summary populate and show
      showMessage(
        $($clickedButton)
          .closest($promotionsForm)
          .find(".js-promotions__message--success")
      );
      removeButton.show();
      $clickedButton.hide();
      //if error  showMessage($(clickedButton).closest(promotionsForm).find('.js-promotions__message--error'));
    });
  };

  const removeGiftCard = function () {
    const removeButton = $promotionsForm.find(
      ".js-promotions__gift-card-remove"
    );

    removeButton.on("click", function (e) {
      e.preventDefault();
      const $clickedButton = $(this);
      const confirmButton = $($clickedButton)
        .closest($promotionsForm)
        .find(".js-promotions__gift-card-submit");
      const allFormElements = $($clickedButton)
        .closest($promotionsForm)
        .find(".js-promotions__input");

      //TODO
      //ajax call
      allFormElements.each((index, element) => {
        $(element).val("");
      });

      //if success
      //promotions-gift-card__summary remove
      $clickedButton.hide();
      confirmButton.addClass("promotions__submit-button--disabled").show();
    });
  };

  var showMessage = function (messageElement: $TSFixMe) {
    const visibleTime = 2000;
    messageElement.css("display", "flex");

    setTimeout(() => {
      messageElement.slideUp();
    }, visibleTime);
  };

  const initialize = function () {
    if (!$promotionsForm.length) {
      return;
    }

    enableButton();
    submitCoupon();
    removeCoupon();
    submitGiftCard();
    removeGiftCard();
  };

  return {
    initialize,
  };
})();

$(() => {
  window.promotions.initialize();
});
