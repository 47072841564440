window.BackMessage = (function () {
  const initialize = function () {
    const $backMessageElement = $(".js-back-message__item");

    if (!$backMessageElement.length) {
      return;
    }

    if (window.Cookie.getCookie().acceptanceBauMessage === true) {
      $backMessageElement.hide();
    } else {
      $backMessageElement.fadeIn();

      $(".js-back-message__close").on("click", (event) => {
        event.preventDefault();
        void $.ajax({
          type: "PUT",
          url: "/acceptanceBauMessage",
          success() {
            $backMessageElement.fadeOut();
          },
        });
      });
    }
  };

  return {
    initialize,
  };
})();

$(() => {
  window.BackMessage.initialize();
});
