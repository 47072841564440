$(() => {
  if ($(".js-category-list-sort").length > 0) {
    window.CategoryListSort.initialize();
  }
});

window.CategoryListSort = (function () {
  const initialize = function () {
    $(".js-category-list-sort__sort-mobile-button")
      .off("click")
      .on("click", () => {
        openSort();
      });

    $(".js-category-list-sort__mobile-close-dialog")
      .off("click")
      .on("click", () => {
        closeSort();
      });

    $(".js-category-list-sort__item")
      .off("click")
      .on("click", function () {
        $(this)
          .siblings(
            ".category-list-sort__item.category-list-sort__item--selected"
          )
          .removeClass("category-list-sort__item--selected");
        $(this).addClass("category-list-sort__item--selected");
        const url = $(this).data("url");
        $.get(url, (html) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          window.history.pushState(null, null, getFilterUrl(url));
          renderProductList(html);
          closeSort();
          window.ProductList.updateSearchPath(html);
          window.ProductList.updateRemainingProductCount($(".js-product-list"));
          window.Sticky.assignHeight();
          window.Filter.renderFilter(html);
        });
      });
  };

  var closeSort = function () {
    const $navWrapper = $(".js-category-list-sort__items-wrap");
    window.ToggleScrollbar.showScrollbar();
    $("main").removeClass("main-overflow");
    $navWrapper.css({ left: 0 }).animate({ left: "100%" }, 100, () => {
      $(".js-category-list-sort__items-wrap").removeClass(
        "category-list-sort__items-wrap--active"
      );
    });
    $($navWrapper).find(".category-list-sort__mobile-close-dialog").hide();
  };

  var openSort = function () {
    const $navWrapper = $(".js-category-list-sort__items-wrap");
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'left' does not exist on type 'Coordinate... Remove this comment to see the full error message
    const { left } = $navWrapper.offset();
    $navWrapper.css({ left }).animate({ left: "0px" }, 200, function () {
      $(this).find(".category-list-sort__mobile-close-dialog").fadeIn("fast");
    });
    $(".js-category-list-sort__items-wrap").addClass(
      "category-list-sort__items-wrap--active"
    );
    window.ToggleScrollbar.hideScrollbar();
    $("main").addClass("main-overflow");
  };

  var getFilterUrl = function (url: $TSFixMe) {
    let query = "";
    const index = url.indexOf("?");
    if (index > 0) {
      query = `?${url.substr(index + 1)}`;
    }

    return location.pathname + query;
  };

  var renderProductList = function (html: $TSFixMe) {
    const ajaxProductList = $(html).find(".js-product-list");
    $(".js-product-list").html(ajaxProductList.html());
    const ajaxProductListCounter = $(html).find(
      ".js-category-list-sort__total-items"
    );
    $(".js-category-list-sort__total-items").text(
      ajaxProductListCounter.text()
    );
  };

  return {
    initialize,
  };
})();
