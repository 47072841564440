(function () {
  const initialize = function ($categoryListItem: JQuery<HTMLElement>) {
    const $description = $categoryListItem.find(".js-hero-brand__description");
    if ($description.length === 0) {
      return;
    }
    if ($description.html().indexOf("<div>") === -1) {
      const text = window.XxlHelpers.replaceNewLineCharactersWithBrElements(
        $description.text()
      );
      const textArray = text.split("<br/>");
      textArray.forEach((item: string, index: number) => {
        if (item.trim().length !== 0) {
          textArray[index] = `<div>${item.trim()}</div>`;
        }
      });
      $description.html(textArray.join(""));
    }
    const $showMoreButton = $categoryListItem.find(".js-hero-brand__read-more");
    const numberOfCharsInFirstDiv = $description
      .find("div")
      .first()
      .text()
      .trim().length;
    const numberOfCharsToShow = Math.min(numberOfCharsInFirstDiv, 300);

    window.Toggle.toggleParagraphOnChars(
      numberOfCharsToShow, // Number of chars to show in description
      $description, // text to show
      $showMoreButton, // toggle button,
      numberOfCharsToShow
    );
  };

  $(() => {
    const $listItem = $(".js-hero-brand");
    initialize($listItem);
  });
})();
