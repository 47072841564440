import type {
  GtmProduct,
  ListProduct,
  ProductMetaData,
  OrderConfirmationEventData,
} from "react-app/src/global";

const PERSONALIZED_PRODUCT_LIST_QUERY_NAME = "gtmProductListName";

/*
 DEPRECATED for React and Next - use context useTracking() and gtm-tracking.ts
*/

/* gtm (Google Tag Manager) */
window.Gtm = (function () {
  const push = function (event: $TSFixMe) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.dataLayer !== undefined && window.dataLayer.push(event);
  };

  const getProduct = function ($item: JQuery): GtmProduct {
    const product = {
      name: $item.data("name"),
      id: $item.data("id"),
      brand: $item.data("brand"),
      category: $item.data("google-category"),
      price: String($item.data("price")),
      variant: $item.data("variant"),
    };

    return product;
  };

  const getListType = function (
    $item: JQuery
  ): "carousel" | "search" | "unknown" {
    const selectorsToLookFor = [
      "js-react-search",
      "personalized-product-list",
      "product-list-rr",
    ];
    selectorsToLookFor.forEach((selector) => {
      if (
        $item.closest(`.${selector}`).attr("class") !== undefined &&
        $item.closest(`.${selector}`).attr("class") !== ""
      ) {
        if (selector === "js-react-search") {
          return "search";
        } else {
          return "carousel";
        }
      }
    });
    return "unknown";
  };

  const getProductPosition = function ($item: JQuery): number {
    return $item.closest("li").index();
  };

  const getProductMetaData = function ($item: JQuery): ProductMetaData {
    const list = getListType($item);
    const position = getProductPosition($item);
    const pageType = window._sharedData.pageType;
    return { list, position, pageType };
  };

  const getProductClickEventData = function (
    product: ListProduct,
    callback: $TSFixMe,
    list = ""
  ) {
    return {
      event: "productClick",
      ecommerce: {
        currencyCode: window._sharedData.siteCurrency,
        click: {
          actionField: { list },
          products: [product],
        },
      },
      eventCallback: callback,
    };
  };

  const productClick = function (
    $item: JQuery,
    callback?: $TSFixMeFunction
  ): void {
    const cb = callback ?? window.XxlHelpers.noop;

    if ($item.length !== 0) {
      const product: GtmProduct = getProduct($item);
      const productMetaData: ProductMetaData = getProductMetaData($item);
      const listProduct: ListProduct = { ...product, ...productMetaData };
      const event = getProductClickEventData(listProduct, cb);

      push(event);
    }
  };

  const addToCart = function ($item: JQuery, priceType: string) {
    const product = getProduct($item);
    const { productListName: list } = window._sharedData.gtmData;
    const event = {
      event: "addToCart",
      ecommerce: {
        currencyCode: window._sharedData.siteCurrency,
        add: {
          actionField: { list },
          products: [product],
        },
      },
      priceType,
    };
    push(event);
  };

  const orderConfirmation = function ({
    order,
    replacementFor,
    label,
  }: OrderConfirmationEventData): void {
    const isReturnConfirmation = Boolean(replacementFor);
    // eslint-disable-next-line
    const products = order?.entries?.map((entry: $TSFixMe) => ({
      name: entry.product.baseProductName,
      id: entry.product.selectedStyle,
      category: entry.product.googleCategory,
      brand: entry.product.brand.name,
      variant: `${entry.product.selectedColour}; ${entry.product.selectedSize}`,

      price: entry.unitPrice
        ? entry.unitPrice.value.toFixed(2)
        : entry.basePrice.value.toFixed(2),

      quantity: entry.quantity.toFixed(),
    }));
    // eslint-disable-next-line
    const revenue = order?.totalPrice?.value?.toFixed(2);
    const event = {
      event: isReturnConfirmation ? "ExchangeOrder" : "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: order.guid,
            affiliation: "XXL",
            tax: order.totalTax?.value?.toFixed(2),
            shipping: order.deliveryMode?.deliveryCost?.value?.toFixed(2),
            currency: order.totalPrice.currencyIso,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            revenue,
          },
          products,
        },
        ...(isReturnConfirmation ? { replacementFor } : undefined),
      },
    };
    if (label) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'eventLabel' does not exist on type '{ ev... Remove this comment to see the full error message
      event.eventLabel = label;
    }
    push(event);
    window.xxlHotjar.sendIdentifyMsg(revenue);
  };

  //This function will send a simple page view event with id gtm.dom but only if the given page url doesn't contain /p/ since that would be a product view event which has to be handled differently
  const pushPageViewEvent = (url: $TSFixMe) => {
    if (window.history.pushState && !url.includes("/p/")) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      window.history.pushState(null, null, url);
      const event = {
        event: "gtm.dom",
      };
      push(event);
      window.history.back();
    }
  };

  const url = new URL(window.location.href);
  url.searchParams.delete(PERSONALIZED_PRODUCT_LIST_QUERY_NAME);
  window.history.replaceState(window.history.state, "", url);

  return {
    addToCart,
    orderConfirmation,
    productClick,
    pushPageViewEvent,
  };
})();
