$(() => {
  if ($(".js-recommendation-banner").length > 0) {
    window.RecommendationBanner.bindClickEvents();
  }
});

window.RecommendationBanner = (function () {
  const bindClickEvents = function () {
    setTimeout(() => {
      $(".js-recommendation-banner .slick-slider").on(
        "beforeChange",
        (event, slick, currentSlide, nextSlide) => {
          $(".recommendation-banner__dot-item--active").removeClass(
            "recommendation-banner__dot-item--active"
          );
          $(".js-recommendation-banner__dot-item")
            .eq(nextSlide)
            .addClass("recommendation-banner__dot-item--active");
        }
      );

      $(".js-recommendation-banner__dot-item").on("click", function (e) {
        e.preventDefault();
        $(".recommendation-banner__dot-item--active").removeClass(
          "recommendation-banner__dot-item--active"
        );
        $(this).addClass(".recommendation-banner__dot-item--active");
        const targetSlide = String($(this).data("target"));
        $(".js-recommendation-banner .slick-slider").slick(
          "slickGoTo",
          targetSlide
        );
      });
    }, 1);
  };

  return {
    bindClickEvents,
  };
})();
