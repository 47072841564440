type StoreData = {
  id: string;
  storeHTMLElement: HTMLElement;
  name: string;
  town: string;
  place: string;
  latitude: number;
  longitude: number;
  distance?: number;
};

window.StoreFinder = (() => {
  const storesList: StoreData[] = [];
  let isUserLocationKnown = false;

  const setStoreData = () => {
    const $stores = $(".js-store-finder__store");
    $stores.map((_index, storeHTMLElement) => {
      const { id, name, town, place, latitude, longitude } =
        storeHTMLElement.dataset;
      if (
        id !== undefined &&
        name !== undefined &&
        town !== undefined &&
        place !== undefined &&
        latitude !== undefined &&
        longitude !== undefined
      ) {
        storesList.push({
          id,
          storeHTMLElement,
          name,
          town,
          place,
          latitude: Number(latitude),
          longitude: Number(longitude),
        });
      }
    });
  };

  const setComponentsVisibility = (searchValue: string) => {
    const isSearchEmpty = searchValue.length === 0;
    const $searchResultSection = $(".js-store-finder__search-results");
    const $closestStoresSection = $(".js-store-finder__closest-stores");
    const hideClosestStores = !isSearchEmpty || !isUserLocationKnown;

    $closestStoresSection.toggleClass("hidden", hideClosestStores);
    $searchResultSection.toggleClass("hidden", isSearchEmpty);
  };

  const sortStoresByDistance = (
    currentLatitude: number,
    currentLongitude: number
  ) => {
    // Get distance to each store
    const storesWithDistance = storesList.map((store) => ({
      ...store,
      distance: window.CoordinatesDistance.get(
        currentLatitude,
        currentLongitude,
        store.latitude,
        store.longitude
      ),
    }));

    // Sort stores by distance
    storesWithDistance.sort((a: StoreData, b: StoreData) => {
      if (a.distance !== undefined && b.distance !== undefined) {
        return a.distance - b.distance;
      }
      return 0;
    });
    return storesWithDistance;
  };

  const populateClosestStores = (closestStoresData: StoreData[]) => {
    const $closestStoresWrap = $(".js-store-finder__closest-stores-list");
    const NUMBER_OF_STORES_TO_SHOW = 3;
    closestStoresData.map((storeData, index) => {
      if (index < NUMBER_OF_STORES_TO_SHOW) {
        $(storeData.storeHTMLElement).clone().appendTo($closestStoresWrap);
      }
    });

    setComponentsVisibility("");
  };

  const getUserGeolocation = () => {
    new Promise<void>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const closestStores = sortStoresByDistance(
              position.coords.latitude,
              position.coords.longitude
            );
            isUserLocationKnown = true;
            populateClosestStores(closestStores);
            resolve();
          },
          () => {
            reject();
          },
          { timeout: 5000 }
        );
      } else {
        resolve();
      }
    }).catch(console.error);
  };

  const populateSearchResult = (storesThatMatchSearch: HTMLElement[]) => {
    const noResult = storesThatMatchSearch.length === 0;
    const $searchResultListWrapper = $(".js-store-finder__search-result-list");
    const $noResultsMessage = $(".js-store-finder__search-result-empty");

    $searchResultListWrapper.toggleClass("hidden", noResult);
    $noResultsMessage.toggleClass("hidden", !noResult);
    $searchResultListWrapper.empty().append($(storesThatMatchSearch).clone());
  };

  const populateSearchHeader = (inputValue: string) => {
    const $headline = $(".js-store-finder__search-text-value");
    $headline.text(inputValue);
  };

  const initStoreSearch = () => {
    $(".js-store-finder__search").on("keyup", function () {
      const rawInputValue = String($(this).val());
      const inputValue = rawInputValue.toLowerCase();

      const storesThatMatchSearch = storesList
        .filter((storeData) => {
          return (
            storeData.name.toLowerCase().includes(inputValue) ||
            storeData.place.toLowerCase().includes(inputValue) ||
            storeData.town.toLowerCase().includes(inputValue)
          );
        })
        .map((storeData) => storeData.storeHTMLElement);

      populateSearchResult(storesThatMatchSearch);
      populateSearchHeader(rawInputValue);
      setComponentsVisibility(rawInputValue);
    });
  };

  const initialize = () => {
    if ($(".js-store-finder").length === 0) {
      return;
    }

    setStoreData();
    getUserGeolocation();
    initStoreSearch();

    const $storeSearchBox = $(".js-store-finder__search");
    if ($storeSearchBox.val() !== undefined) {
      $storeSearchBox.trigger("keyup");
    }
  };

  return {
    initialize,
  };
})();

$(() => {
  window.StoreFinder.initialize();
});
