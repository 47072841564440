$(() => {
  readMore();
});

function readMore() {
  const $readMoreButton = $(".js-read-more__btn");
  if ($readMoreButton.length === 0) {
    return;
  }

  $readMoreButton.each((i, item) => {
    if ($(item).hasClass("guide__menu-level1-link--active")) {
      $(item)
        .find(".js-read-more__icon")
        .toggleClass("read-more__icon--rotated");
    }
    if ($(item).hasClass("guide__menu-level2-link--active")) {
      $(item)
        .find(".js-read-more__icon")
        .toggleClass("read-more__icon--rotated");
    }
  });

  const toggleIcon = $readMoreButton.find(".js-read-more__icon");

  if (toggleIcon.length) {
    $(toggleIcon).addClass("read-more__icon");
  }

  $readMoreButton.on("click", function (event) {
    event.preventDefault();
    const $clickedElement = $(this);
    const elementsParentContainer = $clickedElement.closest(
      ".js-read-more__parent-container"
    );

    $clickedElement.find(".js-read-more__text").toggle();
    $clickedElement.next(".js-read-more__paragraph").slideToggle();
    $clickedElement
      .find(".js-read-more__icon")
      .toggleClass("read-more__icon--rotated");

    if (
      $clickedElement.attr("data-not-sibling") &&
      elementsParentContainer.length > 0
    ) {
      elementsParentContainer.find(".js-read-more__paragraph").slideToggle();
      elementsParentContainer.toggleClass("read-more--open");
    }

    if ($clickedElement.attr("data-active-style")) {
      const activeClass = `${String(
        $clickedElement.attr("data-active-style")
      )}--active`;
      $clickedElement.toggleClass(activeClass);
    }
  });
}
