/**
 * Redirect to home page while exiting Denmark
 */
(() => {
  const { href } = window.location;
  const URLS = [
    "https://www.xxl.dk/account",
    "https://www.xxl.dk/checkout",
    "https://www.xxl.dk/login",
  ];
  const shouldRedirect = URLS.some((url) => href.includes(url));
  if (!shouldRedirect) {
    return;
  }
  window.location.href = "/";
})();

/* Add class name on html element if PROD DK */
(() =>
  window.location.host.includes("www.xxl.dk") &&
  window.location.pathname === "/" &&
  document.querySelector("html")?.classList.add("exit-denmark"))();
