window.TeamBundleMember = (function () {
  const displayTeamBundleMember = (teamBundleMemberData: $TSFixMe) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-member-access
    if (!(teamBundleMemberData && teamBundleMemberData.length)) {
      return;
    }

    const teamMemberConfigJson = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'teamMembers' does not exist on type '{}'... Remove this comment to see the full error message
    teamMemberConfigJson.teamMembers = teamBundleMemberData;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'numberOfTeamMembers' does not exist on t... Remove this comment to see the full error message
    teamMemberConfigJson.numberOfTeamMembers = teamBundleMemberData.length;
    if (teamBundleMemberData.length > 1) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'multiMemberConfig' does not exist on typ... Remove this comment to see the full error message
      teamMemberConfigJson.multiMemberConfig = true;
    }

    const memberTemplate = $("#js-team-bundle-member__template").html();
    $(".js-team-bundle__member-placeholder").append(
      window.Mustache.render(memberTemplate, teamMemberConfigJson)
    );

    initializeTeamBundleMemberFunctions();
  };

  const initializeTeamBundleMemberFunctions = () => {
    const $selectTeamMemberDropdown = $(".js-team-bundle-member__dropdown");

    if (isSingleConfiguration()) {
      const $singleMember = $(".js-team-bundle-member__single");
      displaySelectedMemberConfig($singleMember);
    } else {
      window.SelectBox.initialize(
        $selectTeamMemberDropdown,
        (selectedTeamMember: $TSFixMe) => {
          displaySelectedMemberConfig(selectedTeamMember);
          window.BundleJsonConfig.selectedMemberConfigure();
        }
      );
    }
  };

  const displaySelectedMemberConfig = (selectedTeamMember: $TSFixMe) => {
    const $selectedMemberDisplay = $(".js-team-bundle-member__selected-list");
    const $selectedTeamMember = $(selectedTeamMember);

    const setDOMVal = (...attributes: $TSFixMe[]) => {
      attributes.forEach((attr) => {
        const data = $selectedTeamMember.data();
        const attrCamelCased = attr.charAt(0).toUpperCase() + attr.slice(1);
        const value = data[attr] || data[`select${attrCamelCased}`];
        $selectedMemberDisplay
          .find(`.js-team-bundle-member__list-${attr}`)
          .val(value)
          .text(value);
      });
    };

    setDOMVal("firstname", "lastname", "name", "initials", "number");

    $selectedMemberDisplay.show();

    if (isSingleConfiguration() || isValidConfigurationChosen()) {
      window.TeamBundle.markClosestStepDone($selectedMemberDisplay);
    }
  };

  const isSingleConfiguration = () =>
    $(".js-team-bundle-member__single").length === 1;

  const isValidConfigurationChosen = () =>
    window.SelectBox.isValidItemSelected($(".js-team-bundle-member__dropdown"));

  return {
    displayTeamBundleMember,
  };
})();
