window.Sticky = (function () {
  let $sticky: JQuery;
  let $stickySection: JQuery;
  let $stickyElementWrapper: JQuery;
  let $topElementPositioning; //when there is element above sticky part that is toggling and changes position of sticky
  let stickyHeight: $TSFixMe;
  let minContainerHeight: $TSFixMe;
  let scrolling: $TSFixMe;
  let top: $TSFixMe;
  let asideHeight: $TSFixMe;
  let browserWidth: $TSFixMe;

  const stickyBox = function () {
    browserWidth = $(window).width();
    const stickySectionHeight = $stickySection.height();

    if (
      browserWidth > window.Base.resolutionSizes.tablet &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      stickySectionHeight > minContainerHeight
    ) {
      top = updateTopPosition();
      scrolling = true;
    } else {
      scrolling = false;
      $sticky.attr("style", "");
      $stickyElementWrapper.attr("style", "");
    }
  };

  const getStickySectionHeight = () => {
    let totalHeight = 0;
    $stickySection.children(":not(.js-sticky__wrapper)").each(function (
      this: $TSFixMe
    ) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      totalHeight += $(this).outerHeight(true);
    });
    return totalHeight;
  };

  const assignHeight = function () {
    if (scrolling) {
      setTimeout(() => {
        const $additionalElementHeight = $(".js-category-list__description");
        if ($additionalElementHeight.length !== 0) {
          const desc = $additionalElementHeight.outerHeight();
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          asideHeight = getStickySectionHeight() - desc;
        } else {
          asideHeight = getStickySectionHeight();
        }

        updateStickiWrapperHeight();
      }, 800);
    }
  };

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const updateTopPosition = () => $sticky.parent().offset().top;
  const updateStickiWrapperHeight = () =>
    $stickyElementWrapper.attr("style", `height:${asideHeight}px`);

  const updateStickyPosition = function (this: $TSFixMe) {
    if ($topElementPositioning.length > 0) {
      top = updateTopPosition();
    }

    const scrollPosition = $(this).scrollTop();
    const bottom = top + asideHeight - stickyHeight;
    updateStickiWrapperHeight();

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (scrollPosition >= top) {
      $sticky.attr(
        "style",
        `position:fixed; top: 0px; width:${$sticky.width()}px`
      );
    } else {
      $sticky.attr("style", "");
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (scrollPosition >= bottom) {
      $sticky.addClass("js-sticky--bottom");
    } else {
      $sticky.removeClass("js-sticky--bottom");
    }
  };

  const initialize = function () {
    $sticky = $(".js-sticky");

    if (!$sticky.length) {
      return false;
    }

    $stickySection = $(".js-sticky__section");
    $stickyElementWrapper = $(".js-sticky__wrapper");
    $topElementPositioning = $(".js-sticky--position"); //when there is element above sticky part that is toggling and changes position of sticky
    stickyHeight = $sticky.outerHeight(true);
    minContainerHeight = 500;
    scrolling = false;
    top = 0;

    stickyBox();

    $(window).resize(() => {
      stickyBox();
    });

    if (browserWidth > window.Base.resolutionSizes.tablet) {
      assignHeight();
    }

    $(window).scroll(() => {
      if (!scrolling) {
        return false;
      }

      if (browserWidth > window.Base.resolutionSizes.tablet) {
        updateStickyPosition();
      }
    });
  };

  return {
    initialize,
    assignHeight,
  };
})();

$(() => {
  window.Sticky.initialize();
});
