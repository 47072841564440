type TimeoutObject = { set: false } | { set: true; length: number };

type ToggleAnimationConfig = {
  isActive: boolean;
  activationClass?: string;
  timeout: TimeoutObject;
};

/** ADDING CHECKBOX TO ELEMENT ******
 * Add this element to container to get checkbox animation
 * <span class="checkmark draw"></span>
 */

/** ADDING LOADER TO ELEMENT
 * 1. Add html for loader below to get loader component
 * 2. Set position of the loader parent to relative, or absolute
 * 3. Use SetLoaderAnimation method below:
 *
 * TO SHOW LOADER:
 * `AnimateElement.setLoaderAnimation(containerElement, {isLoading: true});`
 *
 * TO HIDE LOADER:
 * `AnimateElement.setLoaderAnimation(containerElement, {isLoading: false});`
 *
 * WHITE & BLACK LOADER ICON - svg is filled with current text color of its parent element
 *
 * `<svg class="base__loading-circle">
 *    <use href="#loader" xlink:href="#loader" />
 *  </svg>`
 */

window.AnimateElement = (() => {
  let $animateElement: HTMLElement | JQuery;
  let animationClassElement: string;
  const defaultActiveClass = "active";

  /**
   * Set animation class on element
   * WARNING! This module works in a Singleton-ish way, only keeping track of 1 element at a time.
   * i.e. The order and durations of calls and animations matter - you may get unexpected behaviour with multiple parallel animations.
   * @param {HTMLElement|JQuery} element Element to animate. Will be converted to jQuery if not already.
   * @param {string} animationClass CSS animation class to set on the element
   */
  const setAnimation = (
    element: HTMLElement | JQuery,
    animationClass: string
  ) => {
    $animateElement = $(element);
    animationClassElement = animationClass;
    $animateElement.addClass(animationClass);
  };

  /**
   *
   * Toggle an animation between active and inactive
   * @param {any} arguments - Argument object for the animation toggling
   * @param {boolean} arguments.isActive - Whether to activate or deactivate animation
   * @param {string} [arguments.activationClass=active] - CSS class to use to indicate active animation
   * @param {Object} [arguments.timeout] - Object for setTimeout use: `{ set: true, length: 1000 }`
   * @param {HTMLElement|JQuery} [element] Element to animate. If not passed, it defaults to the last element called setAnimation upon.
   */
  const toggleAnimation = (
    {
      isActive,
      activationClass = defaultActiveClass,
      timeout,
    }: ToggleAnimationConfig,
    element = $animateElement
  ) => {
    const $element = $(element);
    const removeClasses = () =>
      $element.removeClass(activationClass).removeClass(animationClassElement);

    const addClass = () => $element.addClass(activationClass);

    const fn = isActive ? addClass : removeClasses;
    if (timeout.set) {
      setTimeout(fn, timeout.length);
      return;
    }

    fn();
  };

  /**
   * Set loader animation on element
   * @param {HTMLElement|JQuery} element Element to animate. Will be converted to jQuery if not already.
   * @param {any} loadingArguments Settings for loading
   * @param {boolean} loadingArguments.isLoading Whether to mark ongoing loading
   */
  const setLoaderAnimation = (
    element: HTMLElement | JQuery,
    { isLoading }: { isLoading: boolean }
  ) => {
    const $selectedLoader = $(element).find(
      ".js-animate__loading-circle-wrapper"
    );
    $selectedLoader.css("display", isLoading ? "flex" : "none");
  };

  return {
    setAnimation,
    toggleAnimation,
    setLoaderAnimation,
  };
})();
