const CampaignPage = (() => {
  const initCampaignHeroText = () => {
    const $campaignHeroComponent = $(".js-campaign-page-hero");
    if ($campaignHeroComponent.length === 0) {
      return;
    }

    const numberOfRowsToShow = 3;
    const $elementToToggle = $campaignHeroComponent.find(
      ".js-campaign-page-hero__text"
    );
    const $readMoreButton = $campaignHeroComponent.find("button");
    const $arrowIcon = $campaignHeroComponent.find(
      ".js-campaign-page-hero__icon"
    );

    window.Toggle.toggleParagraphOnRows(
      numberOfRowsToShow,
      $elementToToggle,
      $readMoreButton,
      undefined,
      $arrowIcon,
      true
    );
  };

  $(() => {
    initCampaignHeroText();
  });
})();
