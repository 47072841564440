$(() => {
  window.WindowEvents.initialize();
});

window.WindowEvents = (function () {
  const windowClickEvents = function () {
    $(window)
      .off("click")
      .on("click", (targetClicked) => {
        const $selectBoxesElement = $(".js-select-box");
        const $filterElement = $(".js-filter");

        if ($selectBoxesElement.length) {
          const closestSelectBox = $(targetClicked.target).closest(
            $selectBoxesElement
          );
          window.SelectBox.close(closestSelectBox);
        }

        if ($filterElement.length) {
          window.Filter.closeDropdown(targetClicked.target);
        }
      });
  };

  /**
   * Fix scroll issue on input fields for Android devices
   * https://stackoverflow.com/questions/23757345/android-does-not-correctly-scroll-on-input-focus-if-not-body-element
   */
  const windowResizeEvent = function () {
    if (/Android/.test(navigator.userAgent)) {
      window.addEventListener("resize", function () {
        if (document.activeElement === null) {
          return;
        }
        if (["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)) {
          document.activeElement.scrollIntoView({ block: "center" });
        }
      });
    }
  };

  const initialize = function () {
    windowClickEvents();
    windowResizeEvent();
  };

  return {
    initialize,
  };
})();
