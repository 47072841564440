const hiddenClass = "grid-container--hidden";
const showMoreBtnClass = "js-show-more";
const $showMoreButtons = $(`.${showMoreBtnClass}`);
const $productBanners = $(".js-product-banner");
const expandedParamName = "expanded_section";

const expandSection = (section: Element) => {
  const containers = section.querySelectorAll(`.${hiddenClass}`);
  const button = section.querySelector(`.${showMoreBtnClass}`) as
    | HTMLElement
    | null
    | undefined;

  const containersArr = Array.from(containers);
  if (containersArr.length > 0) {
    containersArr.forEach((item) => {
      item.classList.remove(hiddenClass);
    });

    button !== null && button !== undefined
      ? (button.style.display = "none")
      : undefined;
  }
};

$(() => {
  const url = new URL(location.href);
  const expandedSectionId = url.searchParams.getAll(expandedParamName);
  expandedSectionId.forEach((id) => {
    const section = document.getElementById(id);
    section !== null && expandSection(section);
  });

  $productBanners.click((event) => {
    const $banner = event.currentTarget;
    const clickedClass = "js-product-banner__clicked";
    $banner.classList.add(clickedClass);
    setTimeout(function () {
      $banner.classList.remove(clickedClass);
    }, 100);
  });
  $showMoreButtons.click((event) => {
    const button = event.target;
    const section = button.closest(".js-grid-section");
    const sectionId = section?.getAttribute("id");

    if (section === null) {
      return;
    }
    expandSection(section);

    if (sectionId !== null && sectionId !== undefined) {
      url.searchParams.append(expandedParamName, sectionId);
      history.replaceState(history.state, "", url.href);
    }
  });
});
