$(() => {
  const state: {
    activeIndexes: number[];
    activeInterval?: number;
  }[] = [];
  const AUTOPLAY_TIME = 2000;
  const setActive = (item: Element) => {
    if (item.classList.contains("active")) {
      return;
    }
    item.classList.add("active");
  };

  const removeActive = (item: Element) => {
    item.classList.remove("active");
  };

  const changeItems = (
    allItems: NodeListOf<Element>,
    activeItemsIndexes: number[],
    breakpoint: typeof window.Base.breakpoint,
    currentModuleIndex: number
  ) => {
    switch (breakpoint) {
      case "mobile":
        activeItemsIndexes.forEach((itemIndex: number) => {
          if (itemIndex <= allItems.length - 2) {
            state[currentModuleIndex].activeIndexes = [itemIndex + 1];
            return;
          }

          state[currentModuleIndex].activeIndexes = [0];
        });
        break;
      case "tablet":
        if (activeItemsIndexes[0] === 0 && allItems.length === 4) {
          state[currentModuleIndex].activeIndexes = [2, 3];
        } else if (activeItemsIndexes[0] === 0 && allItems.length === 3) {
          state[currentModuleIndex].activeIndexes = [2];
        } else {
          state[currentModuleIndex].activeIndexes = [0, 1];
        }
        break;
    }
  };

  const clearActiveInterval = (index: number) => {
    if (!state[index].activeInterval) {
      return;
    }
    clearInterval(state[index].activeInterval);
    state[index].activeInterval = undefined;
  };

  const setActiveItems = (items: NodeListOf<Element>, index: number) => {
    items.forEach(removeActive);
    state[index].activeIndexes.forEach((item) => {
      setActive(items[item]);
    });
  };

  const prepareIndicesArray = (items: NodeListOf<Element>) => {
    const array = [];
    for (let i = 0; i < items.length; i += 1) {
      if (
        window.Base.breakpoint === "desktop" ||
        (window.Base.breakpoint === "tablet" && i < 2) ||
        (window.Base.breakpoint === "mobile" && i < 1)
      ) {
        array.push(i);
      }
    }

    return array;
  };

  const setIncompleteNumberClassName = (
    items: NodeListOf<Element>,
    element: HTMLElement
  ) => {
    if (
      element.classList.contains("usp-teasers--less-teasers") ||
      items.length >= 4
    ) {
      return;
    }
    element.classList.add("usp-teasers--less-teasers");
  };

  const updateUsps = (items: NodeListOf<Element>, index: number) => {
    clearActiveInterval(index);
    state[index].activeIndexes = prepareIndicesArray(items);
    setActiveItems(items, index);
    if (
      !(
        (window.Base.breakpoint === "tablet" && items.length > 2) ||
        (window.Base.breakpoint === "mobile" && items.length > 1)
      )
    ) {
      return;
    }

    state[index].activeInterval = window.setInterval(() => {
      changeItems(
        items,
        state[index].activeIndexes,
        window.Base.breakpoint,
        index
      );
      setActiveItems(items, index);
    }, AUTOPLAY_TIME);
  };

  const changeUsps = (element: HTMLElement, index: number) => {
    state[index].activeIndexes = [0, 1, 2, 3];
    const items = element.querySelectorAll(".usp-teasers__item");
    setIncompleteNumberClassName(items, element);
    state[index].activeIndexes = prepareIndicesArray(items);
    updateUsps(items, index);

    $(window).on("breakpointChange", () => {
      updateUsps(items, index);
    });
  };

  const init = () => {
    $(window).on("load", () => {
      $.each($(".js-usp-teasers"), (index, element) => {
        state.push({
          activeIndexes: [],
        });
        changeUsps(element, index);
      });
    });
  };

  init();
});
