window.CoordinatesDistance = (function () {
  const deg2rad = function (deg: number) {
    return deg * (Math.PI / 180);
  };

  // Get distance between two coordinates (in km)
  // http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
  const get = function (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    if (typeof lat2 === "undefined" || typeof lon2 === "undefined") {
      return Number.MAX_VALUE;
    }

    const earthRadius = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = earthRadius * c;
    return d;
  };

  return {
    get,
  };
})();
