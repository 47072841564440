/**
 * Initializing images lazily, using https://github.com/aFarkas/lazysizes:
 *
 *  `<img class="lazyload" data-src="{url}">` --> `<img src="{url}" class="lazyloaded">`
 *
 *  `<div data-bg="{url}"/>` --> `<div style="background-image: url('{url}');"/>`
 *
 * Also contains specific logic for images that are *within* initial viewport bounds, but still should be deferred.
 * For example, Carousel/Slick images not yet slided in, and images in the MegaMenu dropdown.
 */
(() => {
  const DELAY_MS = 3000;
  const INITIAL_DISTANCE_PX = 10;
  const DEFERRED_DISTANCE_PX = 3000;
  // TODO: Heuristics refined on window.Base.resolutionSizes? For reference, Chromium native lazy loading distance values:
  // https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/renderer/core/frame/settings.json5;l=971

  const lazyClass = "lazyload";
  const lazyImages = "img[data-src]";
  const lazyBackgrounds = "[data-bg]";

  const _setConfig = (config: { expand: number }) => {
    if (window.lazySizes !== undefined) {
      Object.assign(window.lazySizes.cfg, config);
    } else {
      window.lazySizesConfig = config;
    }
  };

  // Time: `window.onLoad`
  // Distance: Viewport + `INITIAL_DISTANCE_PX`
  const initEarly = () =>
    components.forEach((c) => c.initEarly && c.initEarly());

  // Time: `window.onLoad` + `DELAY_MS`
  // Distance: Viewport + `DEFERRED_DISTANCE_PX`
  const initLate = () => {
    _setConfig({
      expand: DEFERRED_DISTANCE_PX,
    });
    components.forEach((c) => c.initLate && c.initLate());
    initLazyBackgrounds();
  };

  const initLazyBackgrounds = (
    element: HTMLElement | JQuery = document.body
  ) => {
    $(element)
      .find(lazyBackgrounds)
      .toArray()
      .forEach((el) => {
        Object.assign(el.style, {
          "background-image": `url('${el.dataset.bg}')`,
        });
        delete el.dataset.bg;
        if (isDesktopOrLarger()) {
          $(el).show();
        }
      });
  };

  const _initManualLoadEvents = () =>
    components.forEach((c) => c.initManual && c.initManual());

  const initialize = () => {
    const EVENT_NAME = "scroll";
    _setConfig({
      expand: INITIAL_DISTANCE_PX,
    });

    window.addEventListener("load", () => {
      initEarly();
      setTimeout(() => {
        initLate();
        window.removeEventListener(EVENT_NAME, loadImmediatelyOnScroll);
      }, DELAY_MS);
    });

    const loadImmediatelyOnScroll = () => {
      initEarly();
      initLate();
      window.removeEventListener(EVENT_NAME, loadImmediatelyOnScroll);
    };
    window.addEventListener(EVENT_NAME, loadImmediatelyOnScroll);

    _initManualLoadEvents();
  };

  // Component specific element handling only in this array
  const components = [
    {
      name: "Slick",
      initEarly: () =>
        $('div.slick-slide[aria-hidden="false"]')
          .find(lazyImages)
          .addClass(lazyClass),
      initLate: () =>
        $('div.slick-slide[aria-hidden="true"]')
          .find(lazyImages)
          .addClass(lazyClass),
      initManual: () => {
        const $heroSlick = $(".test-hero-component__carousel");
        $heroSlick.on("beforeChange", () => initLazyBackgrounds($heroSlick));
      },
    },
  ];

  const isDesktopOrLarger = () =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    $(window).width() >= window.Base.resolutionSizes.desktop;

  $(() => initialize());
})();
