/**
 * Adds a nudge (scroll animtaion) on a scrollable element when element becomes visible in view (only run once)
 * Note: This function may be referenced in content-api
 */

const INTERSECTION_THRESHOLD = 0.5;
const SCROLL_LEFT_DURATION = 300;
const SCROLL_LEFT_PIXELS = 10;

$(() => {
  $(".js-horizontal-nudge-when-visible").each((_, element) => {
    const intersectionCallback: IntersectionObserverCallback = (
      entries,
      observer
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(entry.target)
            .animate({ scrollLeft: SCROLL_LEFT_PIXELS }, SCROLL_LEFT_DURATION)
            .animate({ scrollLeft: 0 });
          observer.unobserve(entry.target);
        }
      });
    };
    const observer = new window.IntersectionObserver(intersectionCallback, {
      threshold: INTERSECTION_THRESHOLD,
    });
    observer.observe(element);
  });
});
