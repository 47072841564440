$(() => {
  const $element = $(".js-product-search--header");
  if ($element.length) {
    window.ProductSearchHeader($element);
  }
});

window.ProductSearchHeader = function ($productSearchHeader: JQuery) {
  const { $inputField, $dropdown, $overlay, MINIMUM_QUERY_LENGTH } =
    window.ProductSearch($productSearchHeader);

  const autocompleteHeaderDropdownClose = function () {
    $inputField.parent().find(".js-product-search__dropdown").slideUp(200);
  };

  $productSearchHeader.on("getResultSuccess", () => {
    $dropdown.slideDown(200);
    $overlay.fadeIn();
  });

  $inputField
    .on("keyup", function () {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if ($(this).val().length < MINIMUM_QUERY_LENGTH) {
        autocompleteHeaderDropdownClose();
      }
    })
    .on("focus", $overlay.fadeIn)
    .on("blur", () => {
      $productSearchHeader.removeClass("site-product__search-wrapper--close");
      $dropdown.slideUp(200);
      $overlay.fadeOut();
    });
};
