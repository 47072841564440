window.KindlyChatbot = (function () {
  const {
    kindlyChatbotKey,
    isTeamsales,
    featureToggles: { toggle_kindly_chatbot },
    requestMapping: { customerService, faq },
  } = window._sharedData;

  // CHAT INIT - Temporary mapping for soft launch.
  const isWhitelisted =
    document.location.pathname === "/" ||
    [`${customerService}`, `${faq}`].some((p) =>
      document.location.pathname.includes(p)
    );

  const isKindlyChatbotVisible =
    toggle_kindly_chatbot && isWhitelisted && !isTeamsales;

  const initialize = function () {
    if (isKindlyChatbotVisible) {
      window.kindlyOptions = {
        zIndex: 100, //zIndex["kindly-chat"]
      };

      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://chat.kindlycdn.com/kindly-chat.js";
      s.defer = true;
      s.setAttribute("data-shadow-dom", "1");
      s.setAttribute("data-bot-key", kindlyChatbotKey);
      s.id = "kindly-chat";
      window.addEventListener("CookieInformationConsentGiven", () => {
        const isFunctionalCookieEnabled =
          window.CookieInformation?.getConsentGivenFor(
            "cookie_cat_functional"
          ) ?? false;

        if (isFunctionalCookieEnabled) {
          $("head").append(s);
        }
      });
    }
  };

  return {
    initialize,
    isKindlyChatbotVisible,
  };
})();

$(() => {
  window.KindlyChatbot.initialize();
});
