$(() => {
  const $filterItems = $(".js-filter");

  $.each($filterItems, (index, filterItem) => {
    window.Filter.initialize(filterItem);
  });
});

window.Filter = (function () {
  let filterElement: $TSFixMe;
  const openDialog = function (dialog: $TSFixMe) {
    if (!dialog) {
      return;
    }

    $(dialog).slideToggle("fast");
    $(dialog).toggleClass("filter__group-dialog--active");
  };

  const getFilterUrl = function (url: string) {
    return `${location.origin}${url.replace(
      /\/products|\/personalised|\/fallback/,
      ""
    )}`;
  };

  const renderFilter = function (html: $TSFixMe) {
    const ajaxFilterGroups = $(html).find(".js-filter__group");
    $(ajaxFilterGroups).each((index, ajaxFilterGroup) => {
      const $filterGroup = $(
        `.js-filter__group[data-name="${$(ajaxFilterGroup).data("name")}"]`
      );
      $filterGroup
        .find(".js-filter__group-dialog")
        .html($(ajaxFilterGroup).find(".js-filter__group-dialog").html());
      $filterGroup
        .find(".filter__header--mobile")
        .html($(ajaxFilterGroup).find(".filter__header--mobile").html());
      $filterGroup
        .find(".js-filter__group-header")
        .html($(ajaxFilterGroup).find(".js-filter__group-header").html());

      if ($filterGroup.length <= 0) {
        $(filterElement)
          .find(".js-filter__group-items-wrap")
          .append($(ajaxFilterGroup));
      }
    });

    $(".js-category-list-sort")
      .find(".js-filter__open-mobile-filter")
      .html(
        $(html)
          .find(".js-category-list-sort")
          .find(".js-filter__open-mobile-filter")
          .html()
      );

    $(".filter__close-group-wrapper")
      .find(".js-filter__mobile-close-dialog")
      .html(
        $(html)
          .find(".filter__close-group-wrapper")
          .find(".js-filter__mobile-close-dialog")
          .html()
      );

    let filterGroups = $(filterElement).find(".js-filter__group");
    $.each(filterGroups, (indexInArray, filterGroup) => {
      const ajaxFilterGroup = $(html).find(
        `.js-filter__group[data-name="${$(filterGroup).data("name")}"]`
      );
      if (ajaxFilterGroup.length <= 0) {
        filterGroup.remove();
      }
    });

    // Need to update filterGroups again after removing them in the code above
    filterGroups = $(filterElement).find(".js-filter__group");
    const showMoreButton = $(filterElement).find(".js-filter__show-more");
    filterGroups.length >= 6
      ? showMoreButton.addClass("filter__show-more--active")
      : showMoreButton.removeClass("filter__show-more--active");

    const ajaxSelectedFilter = $(html).find(".js-filters__selected-filter");
    $(".js-filters__selected-filter").html($(ajaxSelectedFilter).html());

    initializeClicks();
  };

  const renderProductList = function (html: $TSFixMe) {
    const ajaxProductList = $(html).find(".js-filter__product-list");
    $(".js-filter__product-list").html(ajaxProductList.html());
    const ajaxProductListCounter = $(html).find(
      ".js-category-list-sort__total-items"
    );
    $(".js-category-list-sort__total-items").text(
      ajaxProductListCounter.text()
    );

    const ajaxSortWrap = $(html).find(".js-category-list-sort__items-wrap");
    $(".js-category-list-sort__items-wrap").html(ajaxSortWrap.html());
    window.ProductList.initialize();
    window.ProductList.updateSearchPath(html);
    window.CategoryListSort.initialize();
  };

  const closeDropdown = function ($selectedElement: JQuery) {
    const width = $(window).width();

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (width > window.Base.resolutionSizes.mobile) {
      const selectedElementHeader = $selectedElement.closest(
        ".js-filter__group-header"
      );
      const selectedElementDropdown = $selectedElement.closest(
        ".js-filter__group-dialog-wrapper"
      );

      if (!selectedElementDropdown) {
        $(filterElement)
          .find(".js-filter__group-header")
          .not(selectedElementHeader)
          .siblings(".js-filter__group-dialog-wrapper")
          .slideUp("fast");
        $(filterElement)
          .find(".js-filter__group-header")
          .not(selectedElementHeader)
          .siblings(".js-filter__group-dialog-wrapper")
          .removeClass("filter__group-dialog--active");
        $(filterElement).removeClass("filter--dialog-group-open");
      }
    }
  };

  var initializeClicks = function () {
    $(filterElement)
      .find(".js-filter__show-more")
      .off("click")
      .on("click", function () {
        $(this).text(
          $(this).data(
            $(filterElement).hasClass("filter--show-all")
              ? "show-all"
              : "show-less"
          )
        );
        $(filterElement).toggleClass("filter--show-all");
      });

    $(filterElement)
      .find(".js-filter__group-header")
      .off("click")
      .on("click", function () {
        closeDropdown($(this));
        openDialog($(this).siblings(".js-filter__group-dialog-wrapper"));
        $(filterElement).toggleClass("filter--dialog-group-open");
      });

    $(filterElement)
      .find(".js-filter__item")
      .off("click")
      .on("click", function () {
        $(this).toggleClass("filter__item--selected");
        $(this).find(".js-filters-checkbox").toggleClass("checkbox--selected");

        // TODO: Investigate if this really should behave like this. The
        // function is called when $.get fails
        const url = $(this).attr("data-url") ?? "";
        $.get(url, (data) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          window.history.pushState(null, null, getFilterUrl(url));
          renderFilter(data);
          renderProductList(data);
          window.Sticky.assignHeight();
        });

        return false;
      });

    $(filterElement)
      .find(".js-filters__selected-item")
      .off("click")
      .on("click", function () {
        const $item = $(this);
        const $filterGroup = $(
          `.js-filter__group[data-name="${$item.data("name")}"]`
        );
        //Clicking on the filterItem input that will trigger filterGroupItemSelectClick()
        $filterGroup
          .find(`.js-filter__item[data-value="${$item.data("value")}"]`)
          .click();
      });

    $(filterElement)
      .find(".js-filters__clear-filters")
      .off("click")
      .on("click", function () {
        const resetUrl = $(this).data("url");

        $.get(resetUrl, (data) => {
          window.history.pushState(
            null,
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null,
            resetUrl
              .replace("/personalize", "")
              .replace("/fallback", "")
              .replace("/products", "")
          );
          renderFilter(data);
          renderProductList(data);
        });
      });

    $(filterElement)
      .find(".js-filter__close-group-button")
      .off("click")
      .on("click", function () {
        const groupDialog = $(this).parents(".js-filter__group-dialog-wrapper");
        openDialog(groupDialog);
        $(filterElement).removeClass("filter--dialog-group-open");
      });

    $(filterElement)
      .find(".js-filter__group-clear-btn")
      .off("click")
      .on("click", function () {
        const resetUrl = $(this).data("reseturl");

        $.get(resetUrl, (data) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          window.history.pushState(null, null, getFilterUrl(resetUrl));
          renderFilter(data);
          renderProductList(data);
          window.Sticky.assignHeight();
        });
      });

    $(".js-filter__open-mobile-filter")
      .off("click")
      .on("click", () => {
        $(".js-filter").addClass("filter__mobile-dialog--opened");

        window.ToggleScrollbar.hideScrollbar();
        $("main").addClass("main-overflow");
      });

    $(".js-filter__mobile-close-dialog")
      .off("click")
      .on("click", () => {
        $(".js-filter").removeClass("filter__mobile-dialog--opened");

        window.ToggleScrollbar.showScrollbar();
        $("main").removeClass("main-overflow");
      });
  };

  const initialize = function (filterItem: $TSFixMe) {
    filterElement = filterItem;
    initializeClicks();

    const filterGroups = $(filterElement).find(".js-filter__group");
    const showMoreButton = $(filterElement).find(".js-filter__show-more");
    filterGroups.length >= 6
      ? showMoreButton.addClass("filter__show-more--active")
      : showMoreButton.removeClass("filter__show-more--active");
  };

  return {
    initialize,
    renderFilter,
    closeDropdown,
  };
})();
