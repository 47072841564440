import type { BundlePriceData } from "@xxl/frontend-api";
import type { PriceData, ProductData } from "@xxl/pim-api";
import type { AddBundleToCartEventData } from "../../../../../../react-app/src/global";

interface BundleProductData extends ProductData {
  carouselId: string;
  isSizesOutOfStock: boolean;
  hasAdditionalPrice: boolean;
  hasMultipleProductImages: boolean;
}

const isATSite = () => window._sharedData.siteCountry === "at";

window.Bundle = (() => {
  // TODO: Refactor this. Logic moved to separate function for easier reading
  const getHasAdditionalPrice = (bundlePriceAddition?: PriceData): boolean => {
    if (typeof bundlePriceAddition === "undefined") {
      return false;
    } else if (bundlePriceAddition.formattedValue === "0,-") {
      return false;
    }
    return true;
  };
  const { NUMBER_OF_ROWS_TO_SHOW } = window.BundleInit;

  const autoselectOneSizeDropdowns = (
    $dropdownContainer: JQuery<HTMLElement>
  ) => {
    const $oneSizeDropdown = $dropdownContainer
      .find(".js-bundle-dropdown-one-size")
      .not(".js-bundle-dropdown-inactive");
    $oneSizeDropdown.each(function () {
      const $selectedItem = $(this).find(
        ".js-select-box__item--selected .js-select-box-items__item-size"
      );
      if ($selectedItem.length !== 0) {
        handleSelectedBundleProduct.call($selectedItem);
      }
    });
  };

  const switchProductUpsellTabs = () => {
    const $switchButton = $(".js-bundle__product-upsell-switch");

    $switchButton.off("click").on("click", function () {
      const activeUpsellTabClass = "active";
      const bundleProductHiddenClass = "hidden";
      const $clickedUpsellTab = $(this);
      const productCode = String($clickedUpsellTab.data("product-code"));
      const upsellPrice = $clickedUpsellTab.data("upsell-price") as
        | string
        | undefined;
      const upsellBrand = String($clickedUpsellTab.data("brand"));
      const $clickedBundleTemplate = $clickedUpsellTab.closest(
        ".js-bundle__template"
      );
      const $productsInBundleTemplate = $clickedBundleTemplate.find(
        ".js-bundle__product"
      );

      $clickedBundleTemplate
        .find($switchButton)
        .each((_index, eachUpsellButton) => {
          const isSelectedUpsellButton =
            $(eachUpsellButton).data("product-code") === productCode;
          $(eachUpsellButton).toggleClass(
            activeUpsellTabClass,
            isSelectedUpsellButton
          );
        });

      const $clickedUpsellProduct = $productsInBundleTemplate.filter(
        (_index, upsellProduct) => {
          const $upsellProduct = $(upsellProduct);
          const isSelectedUpsell = $upsellProduct.data("code") === productCode;
          $upsellProduct.toggleClass(
            bundleProductHiddenClass,
            !isSelectedUpsell
          );
          $upsellProduct
            .find(".js-bundle-dropdown-one-size")
            .toggleClass("js-bundle-dropdown-inactive", !isSelectedUpsell);
          return $upsellProduct.data("code") === productCode;
        }
      );

      const $parentTemplate = $clickedUpsellProduct.closest(
        ".js-bundle__template"
      );

      const $clickedUpsellProductDescription = $clickedUpsellProduct.find(
        ".js-bundle__product-description"
      );
      const $clickedProductReadMore = $clickedUpsellProduct.find(
        ".js-bundle-init__read-more"
      );

      if ($clickedProductReadMore.css("display") === "none") {
        window.Toggle.toggleParagraphOnRows(
          NUMBER_OF_ROWS_TO_SHOW,
          $clickedUpsellProductDescription,
          $clickedProductReadMore
        );
      }

      setPositionOfVisibleCarousel($clickedUpsellProduct);
      resetBundle($clickedUpsellProduct);
      eventStreamBundleTabClick({
        bundleId: String($(".js-bundle").data("product-id")),
        productCode,
        brand: upsellBrand,
      });
      autoselectOneSizeDropdowns($parentTemplate);
      upsellPrice && updateTotalBundlePrice(upsellPrice);
    });
  };

  const updateTotalBundlePrice = (upsellPrice: string): void => {
    const $currentPriceItem = $(".js-bundle__footer").find(
      ".js-bundle__total-main-price-item"
    );
    const currentPrice = window.XxlHelpers.parseNumber(
      $currentPriceItem.data("sales-price")
    );
    const newPrice = currentPrice + window.XxlHelpers.parseNumber(upsellPrice);
    $currentPriceItem.text(newPrice);
  };

  const setPositionOfVisibleCarousel = (
    $bundleProduct: JQuery<HTMLElement>
  ) => {
    const $bigCarousels = $bundleProduct.find(".js-carousel-product");
    $bigCarousels.slick("setPosition");
    const $miniCarousels = $bundleProduct.find(".js-carousel-thumbnail");
    $miniCarousels.slick("setPosition");
    const $styleCarousels = $bundleProduct.find(".js-carousel-custom");
    $styleCarousels.slick("setPosition");
  };

  const initializeMultiSizesService = () => {
    $(".js-bundle-cart__accessories-add")
      .off("click")
      .on("click", function () {
        const $clickedAccessoryButton = $(this);
        const accessoryAddedClass = "js-bundle__cart-added";
        const $accessoryWrapper = $clickedAccessoryButton.closest(
          ".js-cart__accessory"
        );
        const $selectedItem = $accessoryWrapper
          .find(".js-cart__product-select")
          .find(".select-box__item--selected");

        if (!$selectedItem.length) {
          const $accessoryProductSelect = $accessoryWrapper.find(
            ".js-bundle__accessories-select"
          );
          window.Cart.openSizeDropdown($accessoryProductSelect);
          return;
        }

        const { id, name, price } = $accessoryWrapper.data();
        const selectedAccessorySizeCode = String(
          $selectedItem.data("product-code")
        );

        if ($clickedAccessoryButton.hasClass(accessoryAddedClass)) {
          window.BundleJsonConfig.servicesBundleConfig(
            $clickedAccessoryButton,
            false
          );

          $clickedAccessoryButton.removeClass(accessoryAddedClass);
          toggleAddedAccessoriesInHeaderDetails($clickedAccessoryButton, false);
          window.AnimateElement.toggleAnimation({
            isActive: false,
            activationClass: "active",
            timeout: { set: false },
          });
        } else {
          $clickedAccessoryButton
            .data("size", selectedAccessorySizeCode)
            .data("style", id)
            .data("name", name)
            .data("price", price);

          window.BundleJsonConfig.servicesBundleConfig(
            $clickedAccessoryButton,
            true
          );
          toggleAddedAccessoriesInHeaderDetails($clickedAccessoryButton, true);
          $clickedAccessoryButton.addClass(accessoryAddedClass);
          window.AnimateElement.setAnimation(
            $clickedAccessoryButton,
            "site__button-checkmark"
          );

          window.AnimateElement.toggleAnimation({
            isActive: true,
            activationClass: "active",
            timeout: { set: false },
          });
        }
      });

    $(".js-bundle__accessories-select .js-select-box__item span").on(
      "click",
      function () {
        //Update already added accessory when choosing new size
        const $clickedAccessorySize = $(this);
        const $accessoryWrapper = $clickedAccessorySize.closest(
          ".js-cart__accessory"
        );
        const $selectedItem = $accessoryWrapper
          .find(".js-cart__product-select")
          .find(".select-box__item--selected");
        const $addButton = $accessoryWrapper.find(".js-bundle__cart-added");
        const styleCode = String($accessoryWrapper.data("id"));
        const selectedAccessorySizeCode = String(
          $selectedItem.data("product-code")
        );

        if ($addButton.length) {
          $addButton.data("size", selectedAccessorySizeCode);
          $addButton.data("style", styleCode);
          window.BundleJsonConfig.servicesBundleConfig($addButton, true);
        }
      }
    );
  };

  const toggleAddedAccessoriesInHeaderDetails = (
    $clickedService: JQuery<HTMLElement>,
    isAddedService: boolean
  ) => {
    const { name, price, style } = $clickedService.data();

    if (
      typeof style !== "string" ||
      typeof name !== "string" ||
      typeof price !== "number"
    ) {
      return;
    }

    const $accessoriesWrapper = $(
      ".js-bundle-steps__details-accessories-wrapper"
    );

    if (!$(".js-bundle-steps__details-accessories").length) {
      $accessoriesWrapper.append(
        '<ol class="bundle-steps__details-accessories js-bundle-steps__details-accessories"></ol>'
      );
    }
    const $serviceDetailsContainer = $accessoriesWrapper.find(
      ".js-bundle-steps__details-accessories"
    );

    if (isAddedService) {
      $serviceDetailsContainer.append(
        `<li data-style="${style}">${name}<span>${price}</span></li>`
      );
    } else {
      $serviceDetailsContainer.find(`li[data-style="${style}"]`).remove();
    }

    if ($serviceDetailsContainer.find("li").length !== 0) {
      $accessoriesWrapper.show();
    } else {
      $accessoriesWrapper.hide();
      $serviceDetailsContainer.remove();
    }
  };

  const configureSelectedBundle = (
    $clickedBundleProduct: JQuery<HTMLElement>
  ) => {
    const bundleType = String(
      $clickedBundleProduct
        .closest(".js-bundle__template")
        .data("template-type")
    );
    let selectedProductStyle;
    let sizeSelected;
    let groupId: string;
    let productEan: string;

    if (bundleType === "userinfo") {
      const settingsId = $clickedBundleProduct
        .closest(".js-bundle__settings-dropdown")
        .attr("data-template-id");
      const selectedSettingSize = $clickedBundleProduct.attr("data-item-size");
      const selectedSettingDescription = $clickedBundleProduct
        .closest(".js-bundle__settings-dropdown")
        .attr("data-settings-description");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      groupId = $clickedBundleProduct
        .closest(".js-bundle__template")
        .find('.js-bundle__settings-dropdown[data-settings-index="1"]')
        .attr("data-template-id");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      productEan = $clickedBundleProduct
        .closest(".js-select-box__item")
        .attr("data-product-ean");

      window.BundleJsonConfig.setBundleUserSettingsData(
        groupId,
        settingsId,
        selectedSettingSize,
        selectedSettingDescription,
        productEan
      );
    } else if (bundleType === "print") {
      selectedProductStyle = $clickedBundleProduct
        .closest(".js-bundle__product")
        .attr("data-code");
      sizeSelected = $clickedBundleProduct.attr("data-item-size");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      groupId = $clickedBundleProduct
        .closest(".js-bundle__template")
        .attr("data-template-id");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      productEan = $clickedBundleProduct
        .closest(".js-select-box__item")
        .attr("data-product-ean");

      window.BundleJsonConfig.productsBundleConfig(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        selectedProductStyle,
        sizeSelected,
        groupId,
        bundleType,
        productEan
      );
    } else {
      selectedProductStyle = $clickedBundleProduct
        .closest(".js-bundle__product")
        .attr("data-code");
      sizeSelected = $clickedBundleProduct.attr("data-item-size");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      groupId = $clickedBundleProduct
        .closest(".js-bundle__template")
        .attr("data-template-id");
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      productEan = $clickedBundleProduct
        .closest(".js-select-box__item")
        .attr("data-product-ean");

      window.BundleJsonConfig.productsBundleConfig(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        selectedProductStyle,
        sizeSelected,
        groupId,
        bundleType === "bundleproducts" ? null : bundleType,
        productEan
      );
    }

    if (!$(".js-team-bundle").length) {
      updateSteps($clickedBundleProduct, false);
    }

    if ($(".js-team-bundle__member-placeholder").length > 0) {
      window.BundleJsonConfig.selectedMemberConfigure();
    }
  };

  const updateSteps = function (
    $clickedElement: JQuery,
    isResetBundle: boolean
  ) {
    if (!$($clickedElement).hasClass("js-bundle__services-add")) {
      updateBundleSteps($clickedElement, isResetBundle);
    }
  };

  const ajaxUpdatePrice = function () {
    void $.ajax({
      url: `/p/bp/${window._sharedData.bundleCode}/price`,
      type: "POST",
      dataType: "html",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(window.BundleGlobal.bundleDataJSONConfig),
      success(data) {
        const priceData: BundlePriceData = JSON.parse(data) as BundlePriceData;
        const { priceDisplay } = priceData || {};
        const $disclaimer = $(".js-bundle__total-main-price-disclaimer");
        const $disclaimerText = $disclaimer.find(
          ".js-bundle__total-main-price-disclaimer-text"
        );
        const $salesPrice = $(".js-bundle__total-main-price-item");
        const $previousPrice = $(".js-bundle__previous-price-item");
        const $invertedPriceContainer = $(".js-bundle__inverted-price");
        const $previousPriceTranslationPlaceholder = $(
          ".js-bundle__previous-price-disclaimer-text"
        );
        const $previousPriceWrapper = $(".js-bundle__previous-price-section");

        if (priceDisplay === undefined) {
          return;
        }

        const invertedPriceExists = priceDisplay.invertedPrice !== undefined;

        const isDisclaimerVisible =
          priceDisplay.priceDisclaimer !== undefined &&
          priceDisplay.priceDisclaimer.length > 0;

        const previousPriceExists =
          priceDisplay.previousPrice !== undefined &&
          priceDisplay.salesPrice !== undefined &&
          priceDisplay.salesPrice < priceDisplay.previousPrice;

        const isPreviousPriceOrRegularType =
          priceDisplay.type === "PREVIOUS_PRICE" ||
          priceDisplay.type === "REGULAR";

        const isCampaignOrPromotionType =
          priceDisplay.type === "CAMPAIGN" || priceDisplay.type === "PROMOTION";

        const isBundleSavingType =
          priceDisplay.type === "PREVIOUS_PRICE_BUNDLE_SAVING";

        const isPreviousPriceVisible =
          (!invertedPriceExists || isATSite()) &&
          previousPriceExists &&
          (isPreviousPriceOrRegularType ||
            isCampaignOrPromotionType ||
            (isBundleSavingType && (!isDisclaimerVisible || isATSite())));

        const previousPriceTranslation = isPreviousPriceOrRegularType
          ? window._sharedData.translations["product.was.price"]
          : isCampaignOrPromotionType
            ? window._sharedData.translations["product.campaign.price"]
            : isBundleSavingType
              ? window._sharedData.translations["bundle.original.discount"]
              : "";

        $previousPriceTranslationPlaceholder.text(
          String(previousPriceTranslation)
        );

        if (
          priceDisplay.salesPriceFormatted !== undefined &&
          priceDisplay.salesPrice !== undefined
        ) {
          $salesPrice
            .text(priceDisplay.salesPriceFormatted)
            .data("sales-price", priceDisplay.salesPrice);

          const hasPreviousPrice =
            priceDisplay.previousPriceFormatted !== undefined;
          const hasUndeductedRewardDiscount =
            priceDisplay.invertedPriceFormatted !== undefined &&
            priceDisplay.type !== "PROMOTION";
          const hasLoggedInPrice =
            !hasUndeductedRewardDiscount &&
            priceDisplay.previousPriceFormatted !== undefined;
          const useDiscountColor = hasPreviousPrice || hasLoggedInPrice;
          if (useDiscountColor) {
            $salesPrice.addClass("bundle__total-main-price-item--discounted");
          }
        }

        if (priceDisplay.priceDisclaimer !== undefined) {
          $disclaimerText.text(priceDisplay.priceDisclaimer);
        }

        if (priceDisplay.invertedPriceFormatted !== undefined) {
          $invertedPriceContainer.text(priceDisplay.invertedPriceFormatted);

          if (priceDisplay.type === "PROMOTION") {
            $invertedPriceContainer.addClass("product__inverted-price--promo");
          }
        }
        $disclaimer.toggle(isDisclaimerVisible);

        if (priceDisplay.previousPriceFormatted !== undefined) {
          $previousPrice.text(priceDisplay.previousPriceFormatted);
          $previousPriceWrapper.toggleClass(
            "bundle__previous-price-section--hidden",
            !isPreviousPriceVisible
          );
        }
      },
      error(data) {
        console.log(data);
      },
    });
  };

  const updateBundleSteps = (
    $clickedElement: JQuery<HTMLElement>,
    isBundleReset: boolean
  ) => {
    const stepDone = "bundle__template--done";
    const parentBundle = $($clickedElement).closest(".js-bundle__template");
    const continueBtn = parentBundle.find(".js-bundle__next-step");
    const bundleId = parentBundle
      .find(".js-bundle__template-scroll-target")
      .attr("id");
    disableBundleOutOfStockErrorHandling();

    if (bundleId === "settings") {
      const allMandatorySettings = parentBundle.find(
        ".js-bundle__settings-dropdown"
      );
      const numberMandatorySettings = allMandatorySettings.length;
      const numberSelectedSettings = $(".select-box__bundle--selected").length;
      const dropdownsAreSelected =
        numberSelectedSettings === numberMandatorySettings;

      const $radioButtons = $(".js-bundle__settings-auto-config-section");
      const radioBtnsAreValid =
        $radioButtons.length === 0 ||
        $radioButtons.find("input[name='autoconfig']:checked").length === 1;
      const validSettings = dropdownsAreSelected && radioBtnsAreValid;
      parentBundle.toggleClass(stepDone, validSettings);

      updateBundleStepsHeader($clickedElement, !validSettings);
      updateBundleDetailSteps($clickedElement, !validSettings);

      window.BundleInit.enableDisableCartBtn();
    } else {
      if (isBundleReset) {
        parentBundle.removeClass(stepDone);
        continueBtn.prop("disabled", true);
        updateBundleStepsHeader($clickedElement, isBundleReset);
        updateBundleDetailSteps($clickedElement, isBundleReset);
      } else {
        const $selectBox = parentBundle.find("div.js-select-box:visible");
        const isValid = window.SelectBox.isValidItemSelected($selectBox);
        parentBundle.toggleClass(stepDone, isValid);
        continueBtn
          .prop("disabled", !isValid)
          .removeClass("product__button-disable");
        updateBundleStepsHeader($clickedElement, !isValid);
        updateBundleDetailSteps($clickedElement, !isValid);
      }

      window.BundleInit.enableDisableCartBtn();
    }
  };

  const updateBundleStepsHeader = (
    $clickedElement: JQuery<HTMLElement>,
    isBundleReset: boolean
  ) => {
    const headerDone = "bundle-steps__step--done";
    const $parentBundle = $clickedElement.closest(".js-bundle__template");
    const bundleId = $parentBundle
      .find(".js-bundle__template-scroll-target")
      .attr("id");
    const $headerStepDone = $(
      `.js-bundle__steps-header a[href="#${String(bundleId)}"]`
    ).closest(".js-bundle-init__header-step");

    $headerStepDone.toggleClass(headerDone, !isBundleReset);
  };

  const updateBundleDetailSteps = (
    $clickedElement: JQuery<HTMLElement>,
    isBundleReset: boolean
  ) => {
    const stepDetailsDone = "bundle-step__details--done";
    const $parentBundle = $clickedElement.closest(".js-bundle__template");
    const bundleId = $parentBundle
      .find(".js-bundle__template-scroll-target")
      .attr("id");
    const upsellsAddon = $clickedElement
      .closest(".js-bundle__product")
      .find(".js-bundle__upsell-price-display");

    const $finishedDetailsStep = $(
      `.js-bundle__details-step li[data-step-id="${String(bundleId)}"]`
    );
    const $finishedDetailsStepTextSection = $finishedDetailsStep.find(
      ".js-bundle__step-text"
    );
    const detailsText = String($finishedDetailsStep.attr("data-step-name"));
    const originalText = String(
      $finishedDetailsStep.attr("data-step-original-name")
    );

    if (isBundleReset) {
      $finishedDetailsStep.removeClass(stepDetailsDone);
      $finishedDetailsStepTextSection.text(originalText);
    } else {
      $finishedDetailsStep.addClass(stepDetailsDone);
      $finishedDetailsStepTextSection.text(detailsText);

      if (upsellsAddon.length > 0) {
        $finishedDetailsStepTextSection.html(
          `${detailsText} <strong>${upsellsAddon.text()}</strong>`
        );
      } else {
        $finishedDetailsStepTextSection.text(detailsText);
      }
    }
  };

  const resetBundle = ($clickedElement: JQuery<HTMLElement>) => {
    const stepDone = "bundle__template--done";
    const $parentBundle = $clickedElement.closest(".js-bundle__template");
    const $continueBtn = $parentBundle.find(".js-bundle__next-step");
    const $sizeDropdown = $parentBundle
      .find(".js-bundle__select-size")
      .not(".js-bundle-dropdown-one-size");

    const $sizeSelectElementHeader = $sizeDropdown.find(
      ".js-bundle__select-size-header"
    );
    const originalSelectText = String(
      $sizeSelectElementHeader.attr("data-original-text")
    );
    const isResetBundle = true;
    const groupCode = $parentBundle.attr("data-template-id");

    $parentBundle.removeClass(stepDone);
    $continueBtn.prop("disabled", true);
    $sizeSelectElementHeader.text(originalSelectText);

    disableBundleOutOfStockErrorHandling();

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    window.BundleJsonConfig.resetBundleJsonConfig(groupCode);
    updateSteps($clickedElement, isResetBundle);
  };

  const changeBundleProductStyle = ($styleTab: JQuery<HTMLElement>) => {
    const styleUrl = String($styleTab.attr("href"));
    void $.ajax({
      url: `/p/${styleUrl}.json`,
      dataType: "html",
      success(data) {
        const productDetailsStyleData = JSON.parse(data);
        const productDetailsTemplate = $(
          "#js-bundle__product-details-template"
        ).html();
        const productDetailsTemplateContainer = $styleTab.closest(
          ".js-bundle__mustache-template"
        );

        configureMustacheTemplate(
          productDetailsStyleData,
          productDetailsTemplate,
          productDetailsTemplateContainer
        );
      },
    });
  };

  const configureMustacheTemplate = function (
    productDetailsStyleData: ProductData,
    productDetailsTemplate: string,
    $productDetailsTemplateContainer: JQuery
  ) {
    productDetailsStyleData.sizeOptions?.filter((option) => {
      if (option.stockStatus === "OUTOFSTOCK") {
        // @ts-expect-error TS(2339) FIXME: Property 'noStock' does not exist on type 'SizeDat... Remove this comment to see the full error message
        option.noStock = true;
      } else if (option.stockStatus === "LOWSTOCK") {
        // @ts-expect-error TS(2339) FIXME: Property 'lowStock' does not exist on type 'SizeDa... Remove this comment to see the full error message
        option.lowStock = true;
      }
    });

    const productIdToCarousel = $($productDetailsTemplateContainer)
      .find(".js-carousel-product")
      .attr("data-carousel-id");
    const carouselId = productIdToCarousel ?? "";

    const isSizesOutOfStock = Boolean(
      productDetailsStyleData.sizeOptions?.every(
        (option) => option.stockStatus === "OUTOFSTOCK"
      )
    );

    const hasAdditionalPrice = getHasAdditionalPrice(
      productDetailsStyleData.bundlePriceAddition
    );

    const hasMultipleProductImages =
      (productDetailsStyleData.productImages ?? []).length > 1;

    const oldStyleCode = $productDetailsTemplateContainer.attr("data-code");
    const newStyleCode = productDetailsStyleData.code;
    const switchActive = $productDetailsTemplateContainer
      .closest(".js-bundle__template")
      .find(
        `.js-bundle__product-upsell-switch[data-product-code="${String(
          oldStyleCode
        )}"]`
      );

    const productDetailsStyleDataExtended: BundleProductData = {
      ...productDetailsStyleData,
      carouselId,
      hasAdditionalPrice,
      hasMultipleProductImages,
      isSizesOutOfStock,
    };

    $productDetailsTemplateContainer.html(
      window.Mustache.render(
        productDetailsTemplate,
        productDetailsStyleDataExtended
      )
    );

    if (typeof newStyleCode === "string") {
      $productDetailsTemplateContainer.attr("data-code", newStyleCode);
      switchActive.attr("data-product-code", newStyleCode);
    }

    setAnchorScrollId();
    window.smoothScroll();
    switchProductUpsellTabs();

    window.Carousel.customCarousel(
      $productDetailsTemplateContainer.find(".js-carousel-custom")
    );
    window.Carousel.productImageCarousel(
      $productDetailsTemplateContainer.find(".js-carousel-product")
    );
    window.Carousel.initializeThumbnailCarousel(
      $productDetailsTemplateContainer.find(".js-carousel-thumbnail")
    );

    window.BundleInit.initializeToggleElements();

    initializeSizeDropdownsOnBundlePage($productDetailsTemplateContainer);

    $productDetailsTemplateContainer
      .find(".js-bundle__product-style")
      .on("click", function (e) {
        e.preventDefault();
        const $styleTab = $(this);
        resetBundle($styleTab);
        changeBundleProductStyle($styleTab);
      });
  };

  const getProductNoStockObjects = () => {
    const $bundleFooter = $(".js-bundle__footer");
    const $productErrorElement = $bundleFooter.find(
      ".js-product__error-message"
    );
    const $productErrorNoStockMessage = $bundleFooter.find(
      ".js-product__error-no-stock"
    );
    return { $productErrorElement, $productErrorNoStockMessage };
  };

  const disableBundleOutOfStockErrorHandling = () => {
    const { $productErrorElement, $productErrorNoStockMessage } =
      getProductNoStockObjects();
    $productErrorElement.removeClass("product__error-message--visible");
    $productErrorNoStockMessage.removeClass(
      "product__error-message-text--visible"
    );
  };

  const addBundleToCart = function () {
    $(".js-bundle__add-to-cart").on("click", function () {
      const $clickedCartBtn = $(this);
      window.BundleJsonConfig.addBundleAutoConfiguratedSettings();

      const isTSBundle = $(this).data("bundle-with-print") === true;
      const eanCode = $("#js-bundle-data").attr("data-product-ean") ?? "";
      const bundleName = $("#js-bundle-data").attr("data-product-name") ?? "";

      const eventData: AddBundleToCartEventData = {
        eanCode,
        bundleName,
        $clickedButtonElement: $clickedCartBtn,
        isBundleWithPrintConfig: isTSBundle,
      };

      if (window.XxlEvent !== undefined) {
        window.XxlEvent.dispatchEvent(
          window.XxlEvent.type.XXL_ADD_BUNDLE_TO_CART,
          eventData
        );
        window.Cart.animateAddToCartLoading(true, $clickedCartBtn);
      }
    });
  };

  const setAnchorScrollId = () => {
    const $stepElement = $(".js-bundle__template");
    $stepElement.each((index, element) => {
      const elementId: string | undefined = $($stepElement[index + 1])
        .find(".js-bundle__template-scroll-target")
        .attr("id");

      elementId &&
        $(element)
          .find(".js-bundle__next-step")
          .attr("href", `#${String(elementId)}`);
    });
  };

  const initAutoConfigRadioBtns = function () {
    const $autoConfigs = $(".js-bundle__settings-auto-configure-product");
    $autoConfigs.off("click").on("click", function () {
      updateBundleSteps($(this), false);
      const isSelected = $autoConfigs
        .toArray()
        .some((config) => $(config).find("input").is(":checked"));
      $(".js-bundle__settings-auto-config-header").toggleClass(
        "valid",
        isSelected
      );
    });
  };

  const handleSelectedBundleProduct = function (this: JQuery) {
    configureSelectedBundle(this);
    updateBundleSteps(this, false);
  };

  const changeBundleStyles = () => {
    $(".js-bundle__product-style")
      .off("click")
      .on("click", function (event) {
        event.preventDefault();
        const $clickedStyleButton = $(this);
        const thisProductCode = $clickedStyleButton
          .closest(".js-bundle__product")
          .data("code");
        const $activeTab = $(
          `.js-bundle__product-upsell-switch[data-product-code="${String(
            thisProductCode
          )}"]`
        );
        resetBundle($activeTab);
        changeBundleProductStyle($clickedStyleButton);
      });
  };

  const initialize = () => {
    const $bundlePage = $(".js-bundle");

    if (!$bundlePage.length) {
      return;
    }
    if (!window._sharedData.bundleCode) {
      throw new Error("window._sharedData.bundleCode is missing");
    }

    const bundleEan = $bundlePage.data("product-ean");
    if (bundleEan !== undefined) {
      window.BundleGlobal.bundleEan = bundleEan;
    } else {
      throw new Error(
        `The bundle ${window._sharedData.bundleCode} has an undefined EAN`
      );
    }
    window.BundleGlobal.bundleDataJSONConfig.bundle =
      window._sharedData.bundleCode;
    window.BundleGlobal.bundleDataJSONConfig.bundleEan =
      window.BundleGlobal.bundleEan;
    switchProductUpsellTabs();
    addBundleToCart();
    setAnchorScrollId();
    initAutoConfigRadioBtns();
    changeBundleStyles();
    ajaxUpdatePrice();

    //TO DO: XD-2472 WE SHOULD EXPLORE global initialize of SelectBox and maybe remove it. Since it is messing with callbacks.
    //find best ways to use it so we don't have to use timeouts.
    const $dropdownContainer = $(".js-bundle__template");
    setTimeout(() => {
      initializeSizeDropdownsOnBundlePage($dropdownContainer);
    }, 10);
  };

  const initializeSizeDropdownsOnBundlePage = (
    $dropdownContainer: JQuery<HTMLElement>
  ) => {
    autoselectOneSizeDropdowns($dropdownContainer);
    window.SelectBox.initialize(
      $dropdownContainer.find(".js-bundle-configure"),
      (selectItem: HTMLElement) => {
        const $selectedSizeItem = $(selectItem).find(
          ".js-select-box-items__item-size"
        );
        handleSelectedBundleProduct.call($selectedSizeItem);
      }
    );
  };

  const eventStreamBundleTabClick = ({
    bundleId,
    productCode,
    brand,
  }: $TSFixMe) => {
    if (!window.Eventstream) {
      return;
    }
    const event = {
      name: "click",
      properties: {
        bundle: bundleId,
        id: productCode,
        brand: brand,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.Eventstream.enqueue(event);
  };

  return {
    initialize,
    initializeMultiSizesService,
    ajaxUpdatePrice,
    updateBundleDetailSteps,
    updateBundleStepsHeader,
  };
})();

$(() => {
  window.Bundle.initialize();
});
