/**
 * Example of usage:
 * <div class="js-accordion accordion">
 *   <div class="js-accordion__summary accordion__summary">
 *     Summary, click here to see more details
 *     <img class="accordion__arrow" src="/static/svg/arrow-down.svg" alt="arrow icon" data-inline-svg>
 *   </div>
 *   <div class="js-accordion__details accordion__details">
 *     This will be revealed after user clicked on summary above
 *   </div>
 * </div>
 */

$(() => window.Accordion.initialize());

window.Accordion = (() => {
  const SLIDE_DURATION = 200;
  const className = "js-accordion";
  const classNameInitialized = `${className}--initialized`;

  const initialize = () => {
    const elements = $(`.${className}:not(.${classNameInitialized})`);
    elements.each((_, element) => {
      const $element = $(element);
      const onClick = () => {
        $element
          .toggleClass("accordion--open")
          .find(`.${className}__details`)
          .slideToggle(SLIDE_DURATION);
      };
      $element
        .addClass(classNameInitialized)
        .find(`.${className}__summary`)
        .on("click", onClick);
    });
  };

  return {
    initialize,
  };
})();
