window.PutUpdateForm = (function () {
  const updateData = function ($formElement: JQuery) {
    if (!$formElement.hasClass("js-put-update-form")) {
      return;
    }

    const updateClass = $formElement.data("updateClass");
    const updateUrl = $formElement.data("updateUrl");
    const updateTemplate = $formElement.data("updateTemplate");

    if (!updateClass || !updateUrl || !updateTemplate) {
      return;
    }

    const $updateElement = $(`.${updateClass}`);

    if (!$updateElement.length) {
      return;
    }

    $.ajax({
      type: "GET",
      url: updateUrl,
      success(data) {
        $updateElement.html(
          window.Mustache.render(`{{${String(updateTemplate)}}}`, data)
        );
      },
    });
  };

  const updateSuccessMessage = function ($formElement: JQuery) {
    if (!$formElement.hasClass("js-put-show-success")) {
      return;
    }

    const elementClassToHide = $formElement.data("elementToHide");
    const elementClassToShow = $formElement.data("elementToShow");

    if (elementClassToHide && elementClassToShow) {
      $(`.${elementClassToHide}`).hide();
      $(`.${elementClassToShow}`).show();
    }
  };

  const reload = function (formElement: $TSFixMe) {
    if (!formElement.hasClass("js-put-reload")) {
      return;
    }

    window.location.reload();
  };

  return {
    reload,
    updateData,
    updateSuccessMessage,
  };
})();
