$(function () {
  if ($(".js-team-account").length > 0) {
    window.TeamAccount.initialize();
  }
});

window.TeamAccount = (function () {
  const DESKTOP_BREAKPOINT_NAME = "desktop";
  const CSS_ANIMATION_DELAY = 220;
  const initialize = function () {
    $(".js-team-account__team-details-header")
      .off("click")
      .on("click", function () {
        if (window.Base.breakpoint !== DESKTOP_BREAKPOINT_NAME) {
          const $teamDetailsContainer = $(this).parent();
          const $main = $(".js-main");
          const mainHeight = $main.outerHeight();
          const $content = $teamDetailsContainer.find(
            ".team-account__team-details-list"
          );

          if (
            $teamDetailsContainer.hasClass("team-account__team-details--opened")
          ) {
            const containerHeight = $content.outerHeight(true);
            $teamDetailsContainer.removeClass(
              "team-account__team-details--opened"
            );
            const heightToSet = Number(mainHeight) - Number(containerHeight);
            $main.css("height", `${String(heightToSet)}px`);
          } else {
            $teamDetailsContainer.addClass(
              "team-account__team-details--opened"
            );
            setTimeout(() => {
              const containerHeight = $content.outerHeight(true);
              const heightToSet = Number(mainHeight) + Number(containerHeight);
              $main.css("height", `${String(heightToSet)}px`);
            }, CSS_ANIMATION_DELAY);
          }
        }
      });
    $(".js-account-nav__my-account")
      .off("click")
      .on("click", function (event) {
        event.preventDefault();
        if (window.Base.breakpoint !== DESKTOP_BREAKPOINT_NAME) {
          const $myAccount = $(".js-team-account");
          const myAccountHeight = $myAccount
            .find(".team-account__mobile-wrapper")
            .outerHeight();
          const $main = $(".js-main");
          const heroHeight = $(".hero-component").outerHeight();
          $myAccount.css("top", `${String(heroHeight)}px`);
          const mainHeight = Number(heroHeight) + Number(myAccountHeight);

          $myAccount
            .animate(
              {
                left: 0,
              },
              200
            )
            .addClass("isToggled");
          $main.css("height", `${String(mainHeight)}px`);
        }
      });

    $(".js-team-account__back-button")
      .off("click")
      .on("click", function () {
        if (window.Base.breakpoint !== DESKTOP_BREAKPOINT_NAME) {
          const $myAccount = $(".js-team-account");
          const $main = $(".js-main");

          if ($myAccount.hasClass("isToggled")) {
            $myAccount
              .animate(
                {
                  left: "100%",
                },
                200
              )
              .removeClass("isToggled");
            $main.removeAttr("style");
          }
        }
      });

    $(window).on("breakpointChange", function () {
      const $myAccount = $(".js-team-account");
      const $main = $(".js-main");
      if (window.Base.breakpoint === DESKTOP_BREAKPOINT_NAME) {
        if ($myAccount.attr("style")) {
          $myAccount.removeAttr("style");
        }
        if ($main.attr("style")) {
          $main.removeAttr("style");
        }
      }
    });
  };

  return {
    initialize,
  };
})();
