(function ($) {
  const typedData = function (
    $el: JQuery,
    key: string,
    defaultValue?: string | number | boolean
  ) {
    const value: unknown = $el.data(key);

    return {
      getString() {
        if (typeof value !== "string") {
          if (typeof defaultValue !== "string") {
            throw TypeError(`Expected string, got ${typeof value}`);
          } else {
            return defaultValue;
          }
        }

        return value;
      },

      getNumber() {
        if (typeof value !== "number") {
          if (typeof defaultValue !== "number") {
            throw TypeError(`Expected number, got ${typeof value}`);
          } else {
            return defaultValue;
          }
        }

        return value;
      },

      getBoolean() {
        if (typeof value !== "boolean") {
          if (typeof defaultValue !== "boolean") {
            throw TypeError(`Expected boolean, got ${typeof value}`);
          } else {
            return defaultValue;
          }
        }

        return value;
      },
    };
  };

  $.fn.stringData = function (key: string): string {
    return typedData(this, key).getString();
  };
  $.fn.stringData = function (key: string, defaultValue?: string): string {
    return typedData(this, key, defaultValue).getString();
  };
  $.fn.numberData = function (key: string): number {
    return typedData(this, key).getNumber();
  };
  $.fn.booleanData = function (key: string, defaultValue?: boolean): boolean {
    return typedData(this, key, defaultValue).getBoolean();
  };
})(jQuery);
