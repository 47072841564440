// For Way of Working with A/B FE tests, refer to
// https://confluence.istone.se/pages/viewpage.action?pageId=126584603

type AbTest = (obj?: Record<string, unknown>) => void;

const xxlDispatchEvent = (
  eventType: string,
  props: object = {},
  element: HTMLElement = document.body
): void => {
  element.dispatchEvent(new CustomEvent(eventType, { detail: props }));
};

const tests = {
  "AB-0000-TEST": (props: unknown) => undefined, // Used for unit tests
} as const;

type AbTestId = keyof typeof tests;
type TestInfo = {
  id: AbTestId;
  config?: Record<string, unknown>;
};
const activeAbTests: AbTestId[] = [];

const setActiveAbTests = (id: AbTestId): void => {
  activeAbTests.push(id);
};

const getActiveAbTests = (): AbTestId[] => activeAbTests;

const enableTest = (testInfo: TestInfo): void => {
  const { id } = testInfo;
  try {
    if (!Object.keys(tests).includes(id)) {
      throw Error(`Invalid test id "${id}".`);
    }
    /* todo: ignore security/detect-object-injection warning */
    const test = tests[id];
    test(testInfo.config);
    setActiveAbTests(id);
  } catch (e) {
    console.error(
      `[AbTestProxy] An error occurred while enabling test with id "${id}". ${e}`
    );
  }
};

window.xxlAbTest = enableTest;
