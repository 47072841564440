/**
 * Expands textareas on click
 * Add class name `js-expand-textarea` and data attribute `data-rows-expanded="<rows to be shown>"`
 */
$(() => {
  $("body").on("click", "textarea[data-rows-expanded]", (event) => {
    const rows = $(event.target).data("rowsExpanded") || 4;
    $(event.target).attr("rows", rows);
  });
});
