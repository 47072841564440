$(() => {
  brandList();
});

function brandList() {
  const $brandListSlider = $(".js-brand-list");

  if (!$brandListSlider.length) {
    return;
  }
  const browserWidth = $(window).width();
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  if (browserWidth < window.Base.resolutionSizes.tablet) {
    $brandListSlider.addClass("js-carousel-custom");
  }
}
