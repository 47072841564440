import type {
  AutoCompleteRequest,
  AutoCompleteResponse,
  EntityAttributes,
  ScopedQueryResult,
  SimpleEntityCollection,
} from "@xxl/search-api";

type QueryArgument = { name: string; value: string };

type ActiveElementSelectionProps = {
  suggestionFacet?: string;
  url?: string;
};

type CategoryFacetProps = {
  name: string;
  value: string;
};

/**
 * Common product search constructor
 * @param {JQuery} $searchWrapper Element to initiate with
 */
window.ProductSearch = function ($searchWrapper: JQuery<HTMLElement>) {
  let searchTimeout: ReturnType<typeof setTimeout>;
  const $form = $searchWrapper.find<HTMLFormElement>(
    "form.js-product-search__form"
  );
  // NOTE: Even though the variable name says 'button' and the actual selector
  // currently selects an <i>-tag, the type should be HTMLElement, because the
  // <i>-tag doesn't have a dedicated prototype to inherit from (unlike for
  // example <body> which inherits from HTMLBodyElement), and instead inherits
  // from HTMLElement.
  const $searchCloseBtn = $searchWrapper.find<HTMLElement>(
    ".js-product-search__close"
  );
  const $inputField = $form.find<HTMLInputElement>(".js-product-search__input");
  const $overlay = $form.find(".js-product-search__overlay");
  const placeholderText = $inputField.attr("placeholder");
  const $dropdown = $form.find(".js-product-search__dropdown");
  const $suggestionsList = $form.find(".js-product-search__suggestions");
  const $categoriesList = $form.find(".js-product-search__categories");
  const $brandList = $form.find(".js-product-search__brands");
  const $faqList = $form.find(".js-product-search__faq");
  const $storeList = $form.find(".js-product-search__stores");
  const $guideList = $form.find(".js-product-search__guides");
  const $otherList = $form.find(".js-product-search__other");
  const activeClass =
    "product-search__search-list--active test-product-search__search-list--active";
  const MINIMUM_QUERY_LENGTH = 2;
  const MAX_NUMBER_OF_SUGGESTIONS = 5;
  const ARROW_DOWN_KEY = 40;
  const ARROW_UP_KEY = 38;
  const BACKSPACE_KEY = 8;
  const ENTER_KEY = 13;
  const headerElementType = "h3";
  const SEARCH_API_SUGGEST_URL = `${window._sharedData.configuration.searchApi.basePath}/sites/${window._sharedData.siteUid}/searchContent`;
  const DEFAULT_USER_ID = "1";
  const { requestMapping } = window._sharedData;

  const closeProductSearch = function () {
    $inputField.val("").blur();
  };

  const arrowNavigation = function (pressKey: number) {
    const $dropdownListElements = $dropdown.find("li, button");
    const $activeElement = $dropdown.find(
      ".product-search__search-list--active"
    );
    const activeElementIndex = $dropdownListElements.index($activeElement);
    const starterIndex =
      pressKey === ARROW_DOWN_KEY ? 0 : $dropdownListElements.length - 1;
    const getNextActiveElementIndex = () => {
      switch (pressKey) {
        case ARROW_DOWN_KEY: {
          const isLastElementInDropdown =
            activeElementIndex === $dropdownListElements.length - 1;
          return isLastElementInDropdown ? 0 : activeElementIndex + 1;
        }
        case ARROW_UP_KEY: {
          const isFirstElementInDropdown = activeElementIndex === 0;
          return isFirstElementInDropdown
            ? $dropdownListElements.length - 1
            : activeElementIndex - 1;
        }
      }
    };

    const nextActiveElementIndex = getNextActiveElementIndex();
    const $nextActiveElement = $(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      $dropdownListElements.get(nextActiveElementIndex)
    );
    const currentActiveElementData = activeElementSelection(
      $dropdownListElements,
      $activeElement,
      $nextActiveElement,
      starterIndex
    );

    currentActiveElementData.suggestionFacet !== undefined &&
      $inputField.val(currentActiveElementData.suggestionFacet);
  };

  const activeElementSelection = function (
    dropdownListElements: JQuery<HTMLElement>,
    $activeElement: JQuery,
    $futureActiveElement: JQuery,
    indexOfElement: number
  ): ActiveElementSelectionProps {
    if ($activeElement.length === 0 || $futureActiveElement.length === 0) {
      if (typeof indexOfElement !== "number") {
        throw Error(
          `indexOfElement is not a number, its type is ${typeof indexOfElement}`
        );
      }
      const $dropdownListElement = $(dropdownListElements[indexOfElement]);
      $dropdownListElement.addClass(activeClass);
      return {
        suggestionFacet: $dropdownListElement.data("suggestion-facet") as
          | string
          | undefined,
        url: $dropdownListElement.data("url") as string | undefined,
      };
    }
    $activeElement.removeClass(activeClass);
    $futureActiveElement.addClass(activeClass);
    return {
      suggestionFacet: $futureActiveElement.data("suggestion-facet") as
        | string
        | undefined,
      url: $futureActiveElement.data("url") as string | undefined,
    };
  };

  const createSimpleQuery = (
    queryPrefix: string,
    queryArguments: QueryArgument[]
  ) => {
    const prefixStringIfNotEmpty = (
      stringPrefix: string,
      stringArgument: string
    ) => (stringArgument.length > 0 ? `${stringPrefix}${stringArgument}` : "");
    const queryArgumentsArray = queryArguments.map(
      (queryArgument) =>
        `${queryArgument.name}=${encodeURIComponent(queryArgument.value)}`
    );
    const queryArgumentsSuffix = prefixStringIfNotEmpty(
      "?",
      queryArgumentsArray.join("&")
    );
    return `/${queryPrefix}${queryArgumentsSuffix}`;
  };

  const createSimpleSearchQuery: (
    queryText: string,
    facets?: QueryArgument[]
  ) => string = (queryText, facets = []) =>
    createSimpleQuery("search", [
      {
        name: "query",
        value: queryText,
      },
      ...facets,
    ]);

  const getAttributeValue = (
    attributes: EntityAttributes[],
    name: string
  ): string | number | boolean | undefined => {
    const attribute: EntityAttributes | undefined = attributes.find(
      (attribute: EntityAttributes) => attribute.name === name
    );
    if (
      attribute !== undefined &&
      attribute.values !== undefined &&
      attribute.values.length > 0
    ) {
      return attribute.values[0];
    }
  };

  const dropdownHover = function () {
    const $dropdownListElements = $dropdown.find("li, button");
    $dropdownListElements.hover(
      function (this: $TSFixMe) {
        $($dropdownListElements).removeClass(activeClass);
        $(this).addClass(activeClass);
      },
      function (this: $TSFixMe) {
        $(this).removeClass(activeClass);
      }
    );
  };

  const dropdownClick = function () {
    const $dropdownListElements = $dropdown.find("li");
    const $dropdownListElementsAndButton = $dropdown.find<
      HTMLLIElement | HTMLButtonElement
    >("li, button");
    $dropdownListElements.off("click").on("click", function () {
      $form.submit();
    });

    $dropdownListElementsAndButton.off("mousedown").on("mousedown", (event) => {
      event.preventDefault();
    });
  };

  const clearSearchDropdown = () => {
    $dropdown.find("button").remove();
    $suggestionsList.empty();
    $categoriesList.empty();
    $brandList.empty();
    $faqList.empty();
    $guideList.empty();
    $storeList.empty();
    $otherList.empty();
    $dropdown.find(headerElementType).remove();
  };

  const addSuggestions = function (data: AutoCompleteResponse) {
    const facetMessageFormat = $(".site-header__bottom input").data(
      "facetformat"
    ) as string;

    const suggestionTrackingClass = "js-site-header__search-list-suggestion";
    const categoryTrackingClass = "js-site-header__search-list-category";
    const brandTrackingClass = "js-site-header__search-list-brand";
    const faqTrackingClass = "js-site-header__search-list-faq";
    const guideTrackingClass = "js-site-header__search-list-guide";
    const storeTrackingClass = "js-site-header__search-list-store";
    const otherTrackingClass = "js-site-header__search-list-other";
    const suggestions = data.queries?.items;

    const addSuggestionFacetAttribute = (
      element: HTMLLIElement,
      suggestion: string,
      categoryFacets?: CategoryFacetProps[]
    ) => {
      element.dataset.suggestionFacet = suggestion;
      element.dataset.url = createSimpleSearchQuery(suggestion, categoryFacets);
    };

    const addHeader = (
      translationKey: string,
      $list: JQuery<HTMLElement>,
      addEllipsis: boolean
    ) => {
      const headerElement = document.createElement(headerElementType);
      const translatedText = window.XxlHelpers.translate(translationKey);
      const appendix = addEllipsis ? "..." : "";
      headerElement.innerHTML = `${translatedText}${appendix}`;
      $list.before(headerElement);
    };

    const addSuggestedResultsItems = () => {
      const numberOfSuggestions = data.queries?.items?.length ?? 0;
      const maxNumSuggestResultsDisplayed =
        numberOfSuggestions < MAX_NUMBER_OF_SUGGESTIONS
          ? numberOfSuggestions
          : MAX_NUMBER_OF_SUGGESTIONS;

      for (let i = 0; i < maxNumSuggestResultsDisplayed; i++) {
        if (data.queries?.items !== undefined) {
          const listElement = document.createElement("li");
          const suggestion = data.queries.items[i]?.query;
          listElement.innerHTML = suggestion;
          listElement.className = suggestionTrackingClass;
          addSuggestionFacetAttribute(listElement, suggestion);
          $suggestionsList.append(listElement);
        }
      }
    };

    const addSuggestedCategoriesItems = (
      scopedQueryResult: ScopedQueryResult | undefined
    ) => {
      if (scopedQueryResult === undefined) {
        return;
      }
      const numberOfSuggestedCategories = scopedQueryResult.scopes?.length ?? 0;
      const maxNumSuggestFacetsResultsDisplayed =
        numberOfSuggestedCategories < MAX_NUMBER_OF_SUGGESTIONS
          ? numberOfSuggestedCategories
          : MAX_NUMBER_OF_SUGGESTIONS;
      const categoryFacet = window._sharedData.categoryFacet;

      for (let z = 0; z < maxNumSuggestFacetsResultsDisplayed; z++) {
        if (scopedQueryResult.scopes !== undefined) {
          const suggestionFacet = scopedQueryResult.scopes[z] as string;
          const suggestionFacetingString = scopedQueryResult.query;
          const facetMessage = facetMessageFormat
            .replace("{facetstring}", suggestionFacetingString)
            .replace("{facet}", `<b>${suggestionFacet}</b>`);

          const listElement = document.createElement("li");
          listElement.innerHTML = facetMessage;
          listElement.className = categoryTrackingClass;
          addSuggestionFacetAttribute(listElement, suggestionFacetingString, [
            { name: categoryFacet, value: suggestionFacet },
          ]);
          $categoriesList.append(listElement);
        }
      }
    };

    const addShowAllResultsBtn = () => {
      const btn = document.createElement("button");
      const suggestion = suggestions?.[0]?.query ?? "";
      const translatedText = window.XxlHelpers.translate(
        "header.search.suggestions.all"
      );

      btn.dataset.suggestionFacet = translatedText;
      btn.dataset.url = createSimpleSearchQuery(suggestion);
      btn.setAttribute("type", "submit");
      btn.innerHTML = translatedText;
      btn.onclick = (event) => {
        event.stopPropagation();
        const inputFieldValue = $inputField.val() ?? "";
        if (typeof inputFieldValue !== "string") {
          return;
        }

        btn.setAttribute("data-url", createSimpleSearchQuery(inputFieldValue));
      };
      btn.className = "js-site-header__search-list-btn";
      $dropdown.append(btn);
    };

    const createListElement = (
      elementName: string,
      redirectUrl: string,
      trackingClass: string
    ) => {
      const listElement = document.createElement("li");
      listElement.innerHTML = elementName;
      listElement.className = trackingClass;
      listElement.dataset.suggestionFacet = elementName;
      listElement.dataset.url = redirectUrl;
      return listElement;
    };

    const addSuggestedBrandItems = (
      brandSuggestions: SimpleEntityCollection | undefined
    ) => {
      if (
        brandSuggestions === undefined ||
        brandSuggestions.items === undefined
      ) {
        return;
      }
      for (const entity of brandSuggestions.items) {
        if ($brandList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined) {
          const attributes = entity.attributes;
          const brandCode = getAttributeValue(
            attributes,
            "brand_brandCode"
          ) as string;
          const brandName = getAttributeValue(
            attributes,
            "brand_brandName"
          ) as string;
          if (brandCode !== "" && brandName !== "") {
            $brandList.append(
              createListElement(
                brandName,
                `/b/${brandCode}/${brandName}`,
                brandTrackingClass
              )
            );
          }
        }
      }
    };

    const addSuggestedStoreItems = (
      storeSuggestions: SimpleEntityCollection | undefined
    ) => {
      if (
        storeSuggestions === undefined ||
        storeSuggestions.items === undefined
      ) {
        return;
      }
      for (const entity of storeSuggestions.items) {
        if ($storeList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined && entity.id !== "") {
          const storeId = entity.id;
          const storeUrl = $storeList.stringData(
            "storeurl" + storeId,
            "/store-finder"
          );
          const attributes = entity.attributes;
          const storeName = getAttributeValue(
            attributes,
            "store_name"
          ) as string;
          if (storeId !== "" && storeName !== "") {
            $storeList.append(
              createListElement(storeName, storeUrl, storeTrackingClass)
            );
          }
        }
      }
    };

    const addSuggestedGuideItems = (
      guideSuggestions: SimpleEntityCollection | undefined,
      newGuidesEnabled: boolean
    ) => {
      if (guideSuggestions === undefined || guideSuggestions.items == null) {
        return;
      }
      for (const entity of guideSuggestions.items) {
        if ($guideList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined) {
          const attributes = entity.attributes;
          const guideTitle = getAttributeValue(
            attributes,
            "guide_title"
          ) as string;
          const guideUrl = getAttributeValue(attributes, "guide_url") as string;
          const guidePath = requestMapping.guides;

          if (guideTitle !== "" && guideUrl !== "") {
            $guideList.append(
              createListElement(
                guideTitle,
                `/${guidePath}/${guideUrl}`,
                guideTrackingClass
              )
            );
          }
        }
      }
    };

    const addSuggestedFaqItems = (
      faqSuggestions: SimpleEntityCollection | undefined
    ) => {
      if (
        typeof faqSuggestions === "undefined" ||
        faqSuggestions.items === undefined
      ) {
        return;
      }
      const faqBasePath = window._sharedData.requestMapping.faq;
      for (const entity of faqSuggestions.items) {
        if ($faqList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined) {
          const attributes = entity.attributes;
          const title = getAttributeValue(attributes, "faq_title") as string;
          const faqUrl = (getAttributeValue(attributes, "faq_url") ??
            "") as string;
          if (title !== "") {
            $faqList.append(
              createListElement(
                title,
                `${faqBasePath}/${faqUrl}`, //redirect to base faq page when empty url, otherwise we couldn't suggest the main faq page
                faqTrackingClass
              )
            );
          }
        }
      }
    };

    const addSuggestedCampaignHubItems = (
      campaignHubSuggestions: SimpleEntityCollection | undefined
    ) => {
      if (
        typeof campaignHubSuggestions === "undefined" ||
        campaignHubSuggestions.items === undefined
      ) {
        return;
      }
      const campaignHubBasePath =
        window._sharedData.configuration.campaignHubPath;
      for (const entity of campaignHubSuggestions.items) {
        if ($otherList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined) {
          const attributes = entity.attributes;
          //heading describes specific campaign title, pageTitle describes only main campaign hub page, which doesn't have any heading
          const title = (getAttributeValue(attributes, "campaign_heading") ??
            getAttributeValue(attributes, "campaign_pageTitle")) as string;
          const campaignId = (getAttributeValue(
            attributes,
            "campaign_campaignId"
          ) ?? "") as string;
          if (title !== "") {
            $otherList.append(
              createListElement(
                title,
                `${campaignHubBasePath}/${campaignId}`, //redirect to base campaign page when empty url, otherwise we couldn't suggest the main campaign page
                otherTrackingClass
              )
            );
          }
        }
      }
    };

    const addSuggestedRewardItems = (
      rewardSuggestions: SimpleEntityCollection | undefined
    ) => {
      if (
        typeof rewardSuggestions === "undefined" ||
        rewardSuggestions.items === undefined
      ) {
        return;
      }
      const rewardPath: string = window._sharedData.requestMapping.reward;
      for (const entity of rewardSuggestions.items) {
        if ($otherList.children().length === MAX_NUMBER_OF_SUGGESTIONS) {
          break;
        }
        if (entity.attributes !== undefined) {
          const attributes = entity.attributes;
          const title = getAttributeValue(attributes, "reward_bannerTitle");
          if (typeof title === "string") {
            $otherList.append(
              createListElement(title, rewardPath, otherTrackingClass)
            );
          }
        }
      }
    };
    clearSearchDropdown();

    const isQueryStringLongEnough =
      $inputField.val() !== undefined &&
      String($inputField.val()).length >= MINIMUM_QUERY_LENGTH;

    if (isQueryStringLongEnough) {
      const hasSuggestions = Boolean(
        suggestions !== undefined && suggestions.length > 0
      );
      $suggestionsList
        .parent(".js-site-header__search-list-wrapper")
        .toggle(hasSuggestions);
      if (hasSuggestions) {
        addHeader("header.search.suggestions.items", $suggestionsList, true);
        addSuggestedResultsItems();
      }
      const scopedQueryResult = data.scopedQuery as
        | ScopedQueryResult
        | undefined;
      const hasCategories = Boolean(
        scopedQueryResult !== undefined &&
          scopedQueryResult.scopes !== undefined &&
          scopedQueryResult.scopes.length > 0
      );
      $categoriesList
        .parent(".js-site-header__search-list-wrapper")
        .toggle(hasCategories);
      if (hasCategories) {
        addHeader(
          "header.search.suggestions.categories",
          $categoriesList,
          false
        );
        addSuggestedCategoriesItems(scopedQueryResult);
      }
      const faqResults = data.faqResults;
      const hasFaq = Boolean(
        faqResults !== undefined &&
          faqResults.items !== undefined &&
          faqResults.items.length > 0
      );
      $faqList.parent(".js-site-header__search-list-wrapper").toggle(hasFaq);
      if (hasFaq) {
        addHeader("header.search.suggestions.faq", $faqList, false);
        addSuggestedFaqItems(faqResults);
      }
      const brandResults = data.brandResults;
      const hasBrands = Boolean(
        brandResults !== undefined &&
          brandResults.items !== undefined &&
          brandResults.items.length > 0
      );
      $brandList
        .parent(".js-site-header__search-list-wrapper")
        .toggle(hasBrands);
      if (hasBrands) {
        addHeader("header.search.suggestions.brands", $brandList, false);
        addSuggestedBrandItems(brandResults);
      }

      const newGuidesToggled =
        window._sharedData.featureToggles.toggle_new_guides;
      const guideResults = data.guideResults;
      const hasGuides = Boolean(
        guideResults !== undefined &&
          guideResults.items !== undefined &&
          guideResults.items.length > 0
      );
      $guideList
        .parent(".js-site-header__search-list-wrapper")
        .toggle(hasGuides);
      if (hasGuides) {
        addHeader("header.search.suggestions.guides", $guideList, false);
        addSuggestedGuideItems(guideResults, newGuidesToggled);
      }
      const storeResults = data.storeResults;
      const hasStores = Boolean(
        storeResults !== undefined &&
          storeResults.items !== undefined &&
          storeResults.items.length > 0
      );
      $storeList
        .parent(".js-site-header__search-list-wrapper")
        .toggle(hasStores);
      if (hasStores) {
        addHeader("header.search.suggestions.stores", $storeList, false);
        addSuggestedStoreItems(storeResults);
      }

      const campaignHubResults = data.campaignHubResults;
      const hasCampaignHub = Boolean(
        campaignHubResults !== undefined &&
          campaignHubResults.items !== undefined &&
          campaignHubResults.items.length > 0
      );

      const rewardResults = data.rewardResults;
      const hasReward = Boolean(
        rewardResults !== undefined &&
          rewardResults.items !== undefined &&
          rewardResults.items.length > 0
      );

      if (hasReward || hasCampaignHub) {
        $otherList.parent(".js-site-header__search-list-wrapper").show();
        addHeader("header.search.suggestions.other", $otherList, false);
        if (hasCampaignHub) {
          addSuggestedCampaignHubItems(campaignHubResults);
        }
        if (hasReward) {
          addSuggestedRewardItems(rewardResults);
        }
      } else {
        $otherList.parent(".js-site-header__search-list-wrapper").hide();
      }

      addShowAllResultsBtn();
      $searchWrapper.trigger("getResultSuccess", suggestions);

      dropdownHover();
      dropdownClick();
    }
  };

  const getResults = function (query: string) {
    const data = {
      take: 10, //10 although we display only 5, because first 5 brands could have no products
      query: query.trim().substring(0, 100),
      skip: 0,
      sortBy: [
        {
          type: "relevance",
          order: "desc",
        },
      ],
      includeOnlyNonEmptyBrands: true,
    } as AutoCompleteRequest;

    const userId = window.Cookie.getCookie().loopId ?? DEFAULT_USER_ID;
    void $.ajax({
      type: "POST",
      url: SEARCH_API_SUGGEST_URL,
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(data),
      headers: {
        "Api-Version": "V3",
        "User-Id": userId,
      },
      success(response: AutoCompleteResponse, _status, _xhr) {
        if ($inputField.is(":focus")) {
          addSuggestions(response);
        }
      },
      error(xhr, status, something) {
        console.log(
          "error: " +
            JSON.stringify(xhr) +
            ", status: " +
            JSON.stringify(status) +
            ", something: " +
            JSON.stringify(something)
        );
      },
    });
  };

  const stopEvent = (event: JQuery.SubmitEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  $form.on("submit", (event) => {
    stopEvent(event);

    const getSearchUrl = () => {
      const $selectedElement = $dropdown.find(
        ".product-search__search-list--active"
      );
      const isSuggestionSubmit = $selectedElement.length > 0;
      if (isSuggestionSubmit) {
        return $selectedElement.data("url") as string;
      }

      const inputValue = $inputField.val() ?? "";

      if (
        typeof inputValue !== "string" ||
        inputValue.length < MINIMUM_QUERY_LENGTH
      ) {
        return null;
      }

      return createSimpleSearchQuery(inputValue);
    };

    const searchUrl = getSearchUrl();
    if (typeof searchUrl === "string") {
      document.location.href = searchUrl;
    }
  });
  $inputField
    .on("submit", function (this, event) {
      const queryString = $(this).val();
      if (
        typeof queryString !== "string" ||
        queryString.length < MINIMUM_QUERY_LENGTH
      ) {
        stopEvent(event);
      }
    })
    .on("keydown", function (e) {
      const alphaNumericRegExp = /^[a-z0-9åäöüéæøß]$/i;
      const pressKey = e.keyCode;
      const previousInputValue = $(this).val() as string;
      let inputValue = previousInputValue;
      if (alphaNumericRegExp.test(e.key)) {
        inputValue = ($(this).val() as string) + e.key;
      } else if (pressKey === BACKSPACE_KEY) {
        inputValue = inputValue.substr(0, inputValue.length - 1);
      }
      const length = inputValue.length;
      if (length >= MINIMUM_QUERY_LENGTH) {
        if (pressKey === ARROW_DOWN_KEY || pressKey === ARROW_UP_KEY) {
          arrowNavigation(pressKey);
        } else if (pressKey === ENTER_KEY) {
          return true;
        } else {
          if (typeof searchTimeout === "number") {
            clearTimeout(searchTimeout);
          }
          searchTimeout = setTimeout(() => {
            getResults(inputValue);
          }, 250);
        }
      } else {
        clearTimeout(searchTimeout);
      }
    })
    .on("focus", function () {
      const inputValue: string = $(this).val()?.toString() ?? "";
      $(this).attr("placeholder", "");
      $searchWrapper.addClass("site-product__search-wrapper--close");
      if (inputValue.length >= MINIMUM_QUERY_LENGTH) {
        getResults(inputValue);
      }
    })
    .on(
      "blur",
      function (this: {
        attr: (
          attributeName: string,
          attributeValue: string | undefined
        ) => void;
      }) {
        $(this).attr("placeholder", placeholderText as string);
      }
    );

  $dropdown.on("click", () => {
    closeProductSearch();
  });

  $searchCloseBtn.on("mousedown", (event) => {
    event.preventDefault();
  });

  $searchCloseBtn.on("click", () => {
    if ($inputField.is(":focus") && $inputField.val() !== "") {
      clearSearchDropdown();
      $dropdown.hide();
      $inputField.val("");
    } else {
      closeProductSearch();
    }
  });

  return {
    $inputField,
    $dropdown,
    $overlay,
    clearSearchDropdown,
    MINIMUM_QUERY_LENGTH,
  };
};
