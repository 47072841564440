$(() => {
  smoothScroll();
});

function smoothScroll() {
  const $smooth = $(".js-smoothScroll");

  if (!$smooth.length) {
    return;
  }

  let extra = 0;

  if ($smooth.hasClass("js-smoothScroll__brand-anchor")) {
    const $brandHeader = $(".js-smoothScroll__brand-header");
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    extra = $brandHeader.height();
  } else if ($smooth.hasClass("js-smoothScroll-bundle")) {
    const bundleStepsHeader = $(".js-smoothScroll__bundle-steps").outerHeight();

    if (bundleStepsHeader !== undefined) {
      const headlineMargin = 100;
      extra = bundleStepsHeader + headlineMargin;
    }
  } else if ($smooth.hasClass("js-smoothScroll-product-detail")) {
    const contentPadding = 25;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    extra = $smooth.outerHeight(true) + contentPadding;
  }

  $smooth.click(function (e) {
    e.preventDefault();
    $("body").addClass("arrow-clicked");
    window.Header.showStickyHeader();
    $("html,body").animate(
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      { scrollTop: $($(this).attr("href")).offset().top - extra },
      500,
      () => {
        $("body").removeClass("arrow-clicked");
      }
    );

    if ($(this).hasClass("js-smoothScroll__brand-anchor")) {
      $(".js-smoothScroll").removeClass("brand-index-header__active");
      $(this).addClass("brand-index-header__active");
    }
  });
}
