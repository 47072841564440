window.PutForm = (function () {
  const putSuccess = function ($currentForm: $TSFixMe, avoidClose: $TSFixMe) {
    if (!avoidClose) {
      window.Lightbox.closeLightboxFromOtherPage();
    }
    window.PutUpdateForm.updateData($currentForm);
    window.PutUpdateForm.updateSuccessMessage($currentForm);
    window.PutUpdateForm.reload($currentForm);
    $($currentForm).trigger("FORM_SUBMIT");
  };

  const put = function (formElement: $TSFixMe) {
    const jsonData = {};
    const $currentForm = $(formElement);
    const inputFields = $currentForm.find("input,textarea");
    const putMessages = $currentForm.find(".js-form-put__message");
    const formSuccessMessage = $currentForm.find(".js-put-form__message");
    const $submitButton = $currentForm.find(".js-put-form__button");
    const shouldAvoidClose = Boolean($submitButton.data("should-avoid-close"));

    if (putMessages.length !== 0) {
      putMessages.remove();
    }

    inputFields.each((index, element) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      jsonData[element.id] = element.value;
    });

    $.ajax({
      type: "PUT",
      url: $currentForm.attr("action"),
      data: jsonData,
      beforeSend() {
        window.AnimateElement.setLoaderAnimation($submitButton, {
          isLoading: true,
        });
        window.AnimateElement.setAnimation(
          $submitButton,
          "site__button-checkmark"
        );
      },
      success() {
        window.AnimateElement.toggleAnimation({
          isActive: true,
          activationClass: "active",
          timeout: { set: false },
        });

        if (formSuccessMessage && formSuccessMessage.length > 0) {
          formSuccessMessage.fadeIn(1000, () => {
            setTimeout(() => {
              putSuccess($currentForm, shouldAvoidClose);
            }, 4000);
          });
        } else {
          putSuccess($currentForm, shouldAvoidClose);
        }
      },
      error(data) {
        handleFormErrors(data, $currentForm);
      },
      complete() {
        window.AnimateElement.setLoaderAnimation($submitButton, {
          isLoading: false,
        });

        if (!$submitButton.hasClass("js-put-form__unabled")) {
          window.AnimateElement.toggleAnimation({
            isActive: false,
            activationClass: "active",
            timeout: { set: true, length: 3000 },
          });
        }

        $submitButton.prop("disabled", false);
      },
    });
  };

  var handleFormErrors = function (data: $TSFixMe, $currentForm: JQuery) {
    data.responseJSON.errors.forEach((error: $TSFixMe) => {
      const elementId = error.subject;
      const { message } = error;
      const errorMessage = `<p class="form__message form__message--error js-form-put__message"><svg class="icons"><use href="#alert-icon" xlink:href="#alert-icon" /></svg>${message}</p>`;
      showFormErrors($currentForm, elementId, errorMessage);
    });
  };

  var showFormErrors = function (
    $currentForm: JQuery,
    elementId: $TSFixMe,
    errorMessage: string
  ) {
    if (elementId) {
      $currentForm.find(`#${elementId}`).after(errorMessage);
    } else {
      const $globalMessageElement = $(".js-form-put__global-error");
      $currentForm.find($globalMessageElement).append(errorMessage);
      $globalMessageElement.show();
    }

    $currentForm
      .find(".js-form-put__message")
      .slideDown()
      .css("display", "inline-flex");
  };

  const initialize = function () {
    const $currentForms = $(".js-put-form");
    if (!$currentForms.length) {
      return;
    }

    $currentForms.each((index, currentForm) => {
      $(currentForm)
        .off("submit")
        .on("submit", function (event) {
          event.preventDefault();
          put($(this));
        });
    });
  };

  return {
    initialize,
  };
})();

$(() => {
  window.PutForm.initialize();
});
