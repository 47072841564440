window.KlarnaPlacementWidget = (function () {
  const refreshKlarnaPlacement = () => {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService ?? [];
    window.KlarnaOnsiteService.push({
      eventName: "refresh-placements",
    });
  };

  const updatePrice = (price: number) => {
    console.debug(
      `Updated price in KlarnaPlacementWidget. New price is: ${price}`
    );
    $(".klarna-placement").attr("data-purchase-amount", price);
    refreshKlarnaPlacement();
  };

  const initialize = () => {
    refreshKlarnaPlacement();
  };

  return {
    initialize,
    updatePrice,
    refreshKlarnaPlacement,
  };
})();

$(() => {
  window.KlarnaPlacementWidget.initialize();
});
