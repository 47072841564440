type FormError = { message: string; subject: string };

window.Feedbackform = (function () {
  const $feedbackForm = $(".js-feedback-form");

  const getIfString = (orderNumber: unknown) =>
    typeof orderNumber === "string" ? orderNumber : undefined;

  const isValidOrderIdFormat = (orderNumber: string) =>
    orderNumber.match(/\b([A-Z]{2})[_-]([0-9]{8,9})\b/g) !== null;

  const isValidOrderNumberField = (orderNumber?: string) =>
    !orderNumber || isValidOrderIdFormat(orderNumber);

  const isValidEmailField = (email?: string) =>
    email !== undefined ? window.ValidateEmail(email) : false;

  const tryToFixOrderId = (orderNumber: string | undefined) => {
    if (
      orderNumber !== undefined &&
      orderNumber.match(/^(?:NO|SE|FI|AT|DK)-\d{8,9}$/g) !== null
    ) {
      return orderNumber.replace("-", "_");
    } else {
      return orderNumber;
    }
  };

  const initialize = function () {
    if ($feedbackForm.length === 0) {
      return;
    }

    $feedbackForm.on("submit", (event) => {
      event.preventDefault();
      const $feedbackFormSubmit = $(".js-feedback-form__button");
      const url = $feedbackForm.attr("action");
      const orderNumber = getIfString(
        $(".js-feedback-form__orderNr").val()
      )?.trim();
      const email = getIfString($(".js-feedback-form__email").val())?.trim();

      const validOrderNumberField = isValidOrderNumberField(orderNumber);
      const validEmail = isValidEmailField(email);

      if (validOrderNumberField && validEmail) {
        const name = $(".js-feedback-form__name").val();
        const reason = $feedbackForm
          .find(".select-box__item--selected")
          .data("select-name");
        const message = $(".js-feedback-form__message").val();

        const jsonData = {
          name,
          email,
          orderNumber: tryToFixOrderId(orderNumber),
          reason,
          message,
        };

        void $.ajax({
          type: "POST",
          dataType: "json",
          data: jsonData,
          url,

          beforeSend() {
            window.AnimateElement.setAnimation(
              $feedbackFormSubmit,
              "site__button-checkmark"
            );
            window.AnimateElement.setLoaderAnimation($feedbackFormSubmit, {
              isLoading: true,
            });
            $feedbackFormSubmit.prop("disabled", true);
            $(".js-form-post__message").remove();
          },
          success() {
            window.AnimateElement.toggleAnimation({
              isActive: true,
              activationClass: "active",
              timeout: { set: false },
            });
            $(".js-faq__form-success-message").fadeIn("slow");
            $(".js-faq__form-content").hide();
            $feedbackFormSubmit.hide();
          },
          error(data) {
            handleFormErrors(data.responseJSON?.errors);
            $feedbackFormSubmit.prop("disabled", false);
            window.AnimateElement.toggleAnimation({
              isActive: false,
              activationClass: "active",
              timeout: { set: false },
            });
          },
          complete() {
            window.AnimateElement.setLoaderAnimation($feedbackFormSubmit, {
              isLoading: false,
            });
          },
        });
      } else {
        const validationErrors: FormError[] = [];
        const orderNumberTranslationLabel =
          window._sharedData.translations["form.orderId.invalid"];
        const invalidEmailTranslationLabel =
          window._sharedData.translations["form.email.invalid"];
        if (
          !validOrderNumberField &&
          orderNumberTranslationLabel !== undefined
        ) {
          validationErrors.push({
            message: orderNumberTranslationLabel,
            subject: "orderNumber",
          });
        }
        if (!validEmail && invalidEmailTranslationLabel !== undefined) {
          validationErrors.push({
            message: invalidEmailTranslationLabel,
            subject: "email",
          });
        }
        handleFormErrors(validationErrors);
      }
    });
  };

  const handleFormErrors = (errors: FormError[] = []) => {
    $(".js-form-post__message").remove();

    const createErrorElement = (message: string) =>
      `<p class="form__message form__message--error js-form-post__message"><svg class="icons"><use href="#alert-icon" xlink:href="#alert-icon" /></svg>${message}</p>`;

    const showGlobalError = () => {
      const $errorElement = createErrorElement(
        window.XxlHelpers.translate("errors.server.title")
      );
      $feedbackForm
        .find(".js-form-post__global-error")
        .append($errorElement)
        .show();
    };

    if (errors.length > 0) {
      try {
        errors.forEach(({ subject, message }) => {
          const $errorElement = createErrorElement(message);
          $feedbackForm.find(`#${subject}`).after($errorElement);
        });
      } catch (error) {
        console.error(error);
        showGlobalError();
      }
    } else {
      showGlobalError();
    }
    $feedbackForm
      .find(".js-form-post__message")
      .slideDown()
      .css("display", "inline-flex");
  };

  return {
    initialize,
  };
})();

$(() => {
  window.Feedbackform.initialize();
});
