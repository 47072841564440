(() => {
  const breakpoints = {
    desktop: "desktop",
    tablet: "tablet",
    laptop: "laptop",
    mobile: "mobile",
  } as const;

  type Breakpoint = (typeof breakpoints)[keyof typeof breakpoints];

  const setBreakpoint = (viewWidth: number) => {
    let breakpoint: Breakpoint;
    if (viewWidth >= window.Base.resolutionSizes.tablet) {
      breakpoint = breakpoints.desktop;
    } else if (viewWidth > window.Base.resolutionSizes.mobile) {
      breakpoint = breakpoints.tablet;
    } else {
      breakpoint = breakpoints.mobile;
    }

    if (window.Base.breakpoint !== breakpoint) {
      window.Base.breakpoint = breakpoint;
      const event = new CustomEvent("breakpointChange", {
        detail: { breakpoint },
      });

      window.dispatchEvent(event);
    }
  };

  window.addEventListener("load", () => {
    setBreakpoint(window.innerWidth);
    document.documentElement.style.setProperty(
      "--xxl-vh",
      `${window.innerHeight}px`
    );
  });

  window.addEventListener("resize", () => {
    setBreakpoint(window.innerWidth);
    document.documentElement.style.setProperty(
      "--xxl-vh",
      `${window.innerHeight}px`
    );
  });
})();
