window.Login = (function () {
  const LOGIN_PATH = window._sharedData.requestMapping.login;

  const isLoggedIn = () => Boolean(window.Cookie.getCookie().loggedIn);
  const userLoginCheck = function (forceCartUpdate = false) {
    const cookie = window.Cookie.getCookie();
    const $loginAnchors = $("a.js-anchor-login-wrapper");
    const $loginButton = $("#xxl-login-link");
    const $myAccountButton = $(".js-site-header__my-account");
    const $desktopHeader = $(".js-site-header__top");
    const $siteHeader = $(".js-site-header");
    const $cartLogin = $("#xxl-cart-login");

    if (isLoggedIn()) {
      $loginButton.hide();
      $cartLogin.remove();
      $desktopHeader.find($myAccountButton).css("display", "flex");
      $loginAnchors.each((index, loginAnchor) => {
        $(loginAnchor).attr("href", $(loginAnchor).stringData("href", ""));
      });
      $siteHeader.removeClass("site-header--hide-categories");
    } else {
      $myAccountButton.hide();
      $desktopHeader.find($loginButton).show();
    }

    window.Cart.updateCartCount(forceCartUpdate);

    const $productList = $(".js-product-list");
    if ($productList.length > 0) {
      void window.ProductList.updateProductPrices($productList);
    }

    const $bundlePage = $(".js-bundle");
    if ($bundlePage.length > 0) {
      window.Bundle.ajaxUpdatePrice();
    }

    const $recommendationProductList = $(
      ".js-product-list__product-recommmendation"
    );
    if ($recommendationProductList.length > 0) {
      void window.ProductList.updateProductPrices($recommendationProductList);
    }

    const $product = $(".js-product");
    if ($product.length > 0) {
      void window.Product.updatedDisplayPrice($product)?.then(() => {
        return;
      });
    }

    if (forceCartUpdate && typeof window.XxlEvent === "object") {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      window.XxlEvent.dispatchLoginEvent(cookie.username, cookie.userGroups);
    }
  };

  const handleLoginLightbox = () => {
    if (window.XxlEvent !== undefined) {
      window.XxlEvent.dispatchEvent("xxl-login-click");
    } else {
      window.Lightbox.openLightboxWithLoader();
    }
  };

  const listenForClickOnLoginLinks = () => {
    // Login buttons/links.
    const $loginButtons = [
      $("#xxl-login-link"),
      $("#xxl-ts-login-link"),
      $("#xxl-club-login-link"),
      $("#xxl-cart-login"),
      $("#xxl-reward-banner-login-link"),
    ];

    $loginButtons.forEach(($btn) => {
      $btn.on("click", () => {
        handleLoginLightbox();
        window.megaMenu.closeMenu();
      });
    });

    // Used to pick up login clicks in generated pages, such as guides etc.
    $("body").on(
      "click",
      `a[href*="${window.location.origin}${LOGIN_PATH}"]`,
      (event) => {
        if (isLoggedIn()) {
          $(".js-site-header__my-account").click();
        } else {
          event.preventDefault();
          handleLoginLightbox();
        }
      }
    );
  };

  const initialize = function () {
    listenForClickOnLoginLinks();
    userLoginCheck();
  };

  return {
    initialize,
    userLoginCheck,
    isLoggedIn,
  };
})();

$(() => {
  window.Login.initialize();
});
