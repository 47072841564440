$(() => {
  const $element = $(".js-product-search--menu");
  if ($element.length) {
    window.ProductSearchMenu($element);
  }
});

window.ProductSearchMenu = function ($productSearchMenu: JQuery) {
  const { $inputField, MINIMUM_QUERY_LENGTH, clearSearchDropdown } =
    window.ProductSearch($productSearchMenu);

  $inputField
    .on("keyup", function () {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if ($(this).val().length < MINIMUM_QUERY_LENGTH) {
        clearSearchDropdown();
      }
    })
    .on("focus", function () {
      $(this).parent().find(".js-product-search__dropdown").fadeIn(200);
    })
    .on("blur", function () {
      if ($(this).val() === "") {
        $productSearchMenu.removeClass("site-product__search-wrapper--close");
        $inputField.parent().find(".js-product-search__dropdown").fadeOut(200);
      }
    });
};
