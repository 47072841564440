$(() => {
  window.Reward.hideRewardBannerIfSignedUp();
});

window.addEventListener("scroll", () => {
  const { scrollY } = window;
  const slideIndex = Math.ceil(scrollY / innerHeight);
  const atExactEndOfSlide = scrollY % innerHeight === 0;

  const $slides = $(".reward-slide");
  $slides.removeClass("active next");

  const isMobileSize = window.XxlHelpers.isMobileScreenSize();

  // Touch screen device?
  const isMockTouchScreen = $("html").hasClass("emulate-touch");
  const isTouchScreen =
    window.matchMedia("(pointer: coarse)").matches || isMockTouchScreen;

  // Detect which slide is active
  const isWithinSlidesSection = slideIndex <= $slides.length;
  const hasScrolledPastFirstSlide =
    scrollY >= innerHeight || (isMockTouchScreen && scrollY > 0);

  // Set fixed Signup button on mobile only.
  if (isMobileSize && isTouchScreen) {
    $("section.reward .reward-signup-button")
      .toggle(isWithinSlidesSection)
      .toggleClass("fixed", hasScrolledPastFirstSlide);
  }

  $(`#${slideIndex}`).addClass("active");
  if (!atExactEndOfSlide) {
    $(`#${slideIndex + 1}`).addClass("next");
  }
});

window.Reward = (() => {
  const hideRewardBannerIfSignedUp = () => {
    const subscribed =
      window.Cookie.getCookie().subscribedToNewsletter ?? false;
    const $rewardBanner = $(".js-reward-banner");
    if ($rewardBanner.length > 0) {
      $rewardBanner
        .toggle(!subscribed)
        .toggleClass("reward-banner-test-class", !subscribed);
    }
  };

  return {
    hideRewardBannerIfSignedUp,
  };
})();
