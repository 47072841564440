(function () {
  const initialize = function () {
    const faqEntryElement = document.getElementById("react-faq-entry");

    if (
      faqEntryElement?.dataset.returnEmail !== undefined &&
      faqEntryElement.dataset.returnOrderId !== undefined
    ) {
      const returnFormButton = document.getElementById(
        "anonymous-return-form-button"
      );
      if (returnFormButton !== null) {
        returnFormButton.click();
      }
    }
  };

  $(() => {
    initialize();
  });
})();
