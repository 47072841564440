import type { teamData, memberData, membersArrayData } from "./teamsales-types";

window.TeamMembers = (() => {
  const teamList: {
    clubId: string;
    clubName: string;
    teams: teamData[];
  } = {
    clubId: "",
    clubName: "",
    teams: [],
  };

  const teamAdminRoleString = window.XxlHelpers.translate(
    "teamsales.members.page.player.role.team.admin"
  );
  const memberRoleString = window.XxlHelpers.translate(
    "teamsales.members.page.player.role.member"
  );
  const teamAdminDeleteHeader = window.XxlHelpers.translate(
    "teamsales.members.page.remove.admin"
  );
  const teamAdminDeleteDescription = window.XxlHelpers.translate(
    "teamsales.members.page.remove.admin.message"
  );
  const teamAdminDeleteClose = window.XxlHelpers.translate(
    "teamsales.requisitions.buttons.close"
  );

  const getInitTeamIDElement = () => $(".js-team-member__select-team");
  const getInitTeamID = () =>
    getInitTeamIDElement().data("init-team-id") as string;
  const invalidateInitTeamID = () =>
    getInitTeamIDElement().data("init-team-id", null);
  const unlockScroll = () => {
    $("body").removeAttr("style").removeClass("site__scroll-hidden");
  };

  const teamListConfig = ({
    ...args
  }: {
    teamsData?: teamData[];
    selectedTeamId?: string;
    clubId?: string;
    clubName?: string;
  } = {}) => {
    const teamsData = args.teamsData ?? teamList.teams;
    const selectedTeamId = args.selectedTeamId ?? getInitTeamID();
    teamList.clubId = args.clubId ?? teamList.clubId;
    teamList.clubName = args.clubName ?? teamList.clubName;

    teamList.teams = teamsData.map((team) => {
      team.selected = selectedTeamId === team.code;
      const { code, name, selected, members } = team;
      return { code, name, selected, members };
    });

    return teamList;
  };

  const selectClub = function (selectedClub: JQuery<HTMLElement>) {
    const $selectedClub = $(selectedClub);
    const clubId = $selectedClub.data("select-value") as string;
    const clubName = $selectedClub.data("select-name") as string;
    const clubLogo = $selectedClub.data("club-image") as string;
    const selectedTeamId = getInitTeamID();

    teamListConfig({
      clubId,
      clubName,
      selectedTeamId,
    });
    $selectedClub
      .closest(".js-team-member__select-club")
      .find(".js-team-member__select-logo")
      .attr("src", clubLogo);
    fetchClubData();
  };

  const fetchClubData = function () {
    void $.ajax({
      url: `/clubs/${teamList.clubId}/representing`,
      type: "POST",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      success(teamsData) {
        teamListConfig({ teamsData });
        invalidateInitTeamID();
        resetTeamDropdown();
        resetTeamButtons();
        updateTeamDropdown();
        clubLinkUpdate();
        window.Table.tableContentDelete();
      },
      error(error) {
        console.log(error);
      },
    });
  };

  const resetTeamButtons = () => {
    $(".js-team-member__edit-team-name")
      .attr("data-url", "")
      .prop("disabled", true);
    $(".js-team-member__delete-team-name").prop("disabled", true);
  };

  const clubLinkUpdate = () => {
    const $shareLinkSelector = $(".js-team-member__share-link");
    const sentence = $shareLinkSelector.data("share-sentence") as string;
    const envUrl = $shareLinkSelector.data("env-url") as string;
    const clubLink = `${String(envUrl)}/club/${teamList.clubId}`;
    $(".js-team-member__link-description").html(
      `${String(
        sentence
      )} <span class="team-member__description-link">${clubLink}</span>`
    );
    $shareLinkSelector.data("link", clubLink);
  };

  const selectTeam = (selectedTeam: HTMLElement) => {
    const $selectedTeam = $(selectedTeam);
    const selectedTeamId =
      ($(".js-team-member__select-team").data("init-team-id") as string) ||
      ($selectedTeam.data("select-value") as string);
    teamListConfig({
      selectedTeamId,
    });
    resetTeamDropdown();
    updateTeamDropdown();
  };

  const resetTeamDropdown = () => {
    const $teamDropdownWrapper = $(".js-team-member__select-team");
    const teamDropdown = $teamDropdownWrapper.find(".js-select-box__content");
    const $teamDropdownHeader = $teamDropdownWrapper.find(
      ".js-select-box__header-text"
    );

    $teamDropdownHeader.text($teamDropdownHeader.data("original-text"));
    teamDropdown.empty();
  };

  const updateTeamDropdown = () => {
    const $teamDropdownWrapper = $(".js-team-member__select-team");
    const teamDropdown = $teamDropdownWrapper.find(".js-select-box__content");
    const teamDropdownHeader = $teamDropdownWrapper.find(
      ".js-select-box__header-text"
    );

    teamList.teams.map((team) => {
      teamDropdown.append(
        `<li class="select-box__item js-select-box__item js-team-member__select-team-item test-team-member__select-team-item" data-select-name="${String(
          team.name
        )}" data-select-value="${String(team.code)}"><span>${String(
          team.name
        )}</span></li>`
      );

      if (team.selected) {
        teamDropdownHeader.text(team.name);
        updateTeamTable(team);
      }
    });

    window.SelectBox.initialize(
      $teamDropdownWrapper,
      (selectedTeam: $TSFixMe) => {
        selectTeam(selectedTeam);
      }
    );
  };

  const updateTeamTable = (selectedTeam: teamData) => {
    const teamId = selectedTeam.code;
    const teamName = selectedTeam.name;

    void $.ajax({
      url: `/clubs/${teamList.clubId}/teams/${String(teamId)}`,
      type: "GET",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      success(teamMembers: memberData[]) {
        selectedTeam.members = [];
        selectedTeam.members = teamMembers;
        const teamTableConfig: {
          teamId: string;
          teamName: string;
          members: membersArrayData[];
        } = {
          teamId,
          teamName,
          members: [],
        };

        selectedTeam.members.forEach((teamMember: memberData) => {
          teamTableConfig.members.push({
            memberId: teamMember.uid,
            memberName: teamMember.configuration.firstname,
            memberLastName: teamMember.configuration.lastname,
            memberNumber: teamMember.configuration.number,
            memberInitials: teamMember.configuration.initials,
            customerId: teamMember.configuration.customerId,
            configurationId: teamMember.configuration.uid,
            memberRole:
              teamMember.configuration.teamAdmin !== undefined &&
              teamMember.configuration.teamAdmin
                ? teamAdminRoleString
                : memberRoleString,
          });
        });

        window.Table.mustacheRender(teamTableConfig);
        editTeamNameInitialize(teamId, teamName);
        deleteTeamInitialize(teamId, teamName);
      },
      error(data, result) {
        console.log(`${String(data)} ${result}`);
      },
    });
  };

  const renderDeleteLightbox = function () {
    $(".js-team__delete-member").on("click", function (event) {
      void (async () => {
        event.preventDefault();
        await window.Lightbox.openLightbox(this);
        const $tableRow = $(this).closest(".js-table__row");
        const isClubAdmin = $(this)
          .closest(".js-table__template")
          .data("club-admin") as boolean;
        const firstName = $tableRow.find(".js-team__first-name").text();
        const surname = $tableRow.find(".js-team__surname").text();
        const teamName = $tableRow.data("team") as string;
        const memberId = $tableRow.data("id") as string;
        const customerId = $tableRow.data("customerId") as string;
        const configurationId = $tableRow.data("configurationId") as string;
        const role = $tableRow.find(".js-team__role").text();

        if (role === teamAdminRoleString && !isClubAdmin) {
          $(".js-team-member__heading").text(teamAdminDeleteHeader);
          $(".js-team-member__description").text(teamAdminDeleteDescription);
          $(".js-team-member__delete").text(teamAdminDeleteClose);
          $(".team-member__from-team-text").text("");
          $(".js-team-member__delete")
            .off("click")
            .on("click", function () {
              window.Lightbox.closeLightboxFromOtherPage();
              unlockScroll();
            });
        } else {
          $(".js-team-member__name").text(`${firstName} ${surname}`);
          $(".js-team__name").text(teamName);
          $(".js-team-member__delete").data("member-id", memberId);
          $(".js-team-member__delete").data(
            "configuration-id",
            configurationId
          );
          $(".js-team-member__delete").data("customer-id", customerId);
          $(".js-team-member__edit").data("member-id", memberId);

          deleteTeamMember();
        }
      })();
    });
  };

  const getSelectedTeam = () =>
    teamList.teams.find((team) => team.selected === true);

  const deleteTeamMember = function () {
    $(".js-team-member__delete")
      .off("click")
      .on("click", function () {
        const clubId = teamList.clubId;
        const selectedTeam = getSelectedTeam();

        const teamId = selectedTeam?.code;
        const memberId = $(this).data("member-id") as string;
        const customerId = $(this).data("customer-id") as string;
        const configurationId = $(this).data("configuration-id") as string;

        void $.ajax({
          url: `/clubs/${clubId}/teams/${String(teamId)}/customers/${String(
            customerId
          )}/configurations/${String(configurationId)}/members/${String(
            memberId
          )}`,
          type: "DELETE",
          contentType: "application/json; charset=utf-8",
          success() {
            window.Table.removeTeamMember(memberId);
            window.Lightbox.closeLightboxFromOtherPage();
            unlockScroll();
          },
          error(data, result) {
            console.log(`${String(data)} ${result}`);
          },
        });
      });
  };

  const renderEditLightbox = function () {
    $(".js-team__edit-member").on("click", function (event) {
      void (async () => {
        event.preventDefault();
        await window.Lightbox.openLightbox(this);
        const $tableRow = $(this).closest(".js-table__row");
        const firstName = $tableRow.find(".js-team__first-name").text();
        const surname = $tableRow.find(".js-team__surname").text();
        const initials = $tableRow.find(".js-team__initials").text();
        const number = $tableRow.find(".js-team__number").text();
        const role = $tableRow.find(".js-team__role").text();
        const memberId = $tableRow.data("id") as string;
        const customerId = $tableRow.data("customer-id") as string;
        const configurationId = $tableRow.data("configuration-id") as string;

        const $lightbox = $(".js-lightbox-placeHolder");
        const $form = $lightbox.find(".js-team-member__edit-form");
        $form.find(".playerName").val(firstName);
        $form.find(".playerSurname").val(surname);
        $form.find(".initials").val(initials);
        $form.find(".playerNum").val(number);
        const $roleInput = $form.find(".playerRole");
        $roleInput.toggleClass(
          "checkbox--selected",
          role === teamAdminRoleString
        );
        $lightbox.find(".js-team-member__edit").data("customer-id", customerId);
        $lightbox.find(".js-team-member__edit").data("member-id", memberId);
        $lightbox
          .find(".js-team-member__edit")
          .data("configuration-id", configurationId);

        teamMemberLightbox($lightbox, role === teamAdminRoleString);
      })();
    });
  };

  const teamMemberLightbox = function (
    lightbox: JQuery<HTMLElement>,
    teamAdmin: boolean
  ) {
    $(".js-team-member__edit")
      .off("click")
      .on("click", function () {
        const $form = lightbox.find(".js-team-member__edit-form");
        const putMessages = $form.find(".js-form-put__message");
        if (putMessages.length !== 0) {
          putMessages.remove();
        }

        const { memberId, configurationId, customerId } = $(this).data();
        const clubId = teamList.clubId;

        const selectedTeam = getSelectedTeam();
        const teamId = selectedTeam?.code;

        const memberData = {
          initials: $form.find(".initials").val(),
          number: $form.find(".playerNum").val(),
          firstname: $form.find(".playerName").val(),
          lastname: $form.find(".playerSurname").val(),
          teamAdmin:
            $form.find(".playerRole").length === 0
              ? teamAdmin
              : $form
                  .find(".playerRole")
                  .closest(".checkbox")
                  .hasClass("checkbox--selected"),
          customerId: customerId as string,
          uid: configurationId as string,
          teamId,
          clubId,
          memberId: memberId as string,
        };

        void $.ajax({
          url: `/clubs/${clubId}/teams/${String(teamId)}/customers/${String(
            customerId
          )}/members/${String(memberId)}`,
          type: "PUT",
          data: memberData,
          success() {
            window.Table.editTeamMember(memberId, memberData);
            window.Lightbox.closeLightboxFromOtherPage();
            unlockScroll();
          },
          error(data) {
            handleFormErrors(data, $form);
          },
        });
      });
  };

  const handleFormErrors = function (data: $TSFixMe, currentForm: $TSFixMe) {
    if (data.responseJSON.errors) {
      data.responseJSON.errors.forEach((error: $TSFixMe) => {
        addErrorMessage(error, currentForm);
      });
    } else if (data.responseJSON.error) {
      addErrorMessage(data.responseJSON.error, currentForm);
    }
  };
  const addErrorMessage = (error: $TSFixMe, currentForm: $TSFixMe) => {
    const elementId = error.subject;
    const { message } = error;
    const errorMessage = `<p class="form__message form__message--error js-form-put__message"><svg class="icons"><use href="#alert-icon" xlink:href="#alert-icon" /></svg>${String(
      message
    )}</p>`;
    showFormErrors(currentForm, elementId, errorMessage);
  };

  const showFormErrors = function (
    currentForm: $TSFixMe,
    elementId: $TSFixMe,
    errorMessage: $TSFixMe
  ) {
    if (elementId) {
      currentForm.find(`#${String(elementId)}`).after(errorMessage);
    } else {
      const $globalMessageElement = $(".js-form-put__global-error");
      currentForm.find($globalMessageElement).append(errorMessage);
      $globalMessageElement.show();
    }

    currentForm
      .find(".js-form-put__message")
      .slideDown()
      .css("display", "inline-flex");
  };

  const copyLinkToClipboard = function () {
    $(".js-team-member__share-link").on("click", function () {
      const $temp = $("<input>");
      const $selectedClub = $(
        "li.js-team-member__select-item.select-box__item--selected"
      );
      const $teamMemberTooltip = $(".js-team-member__tooltip");
      const clubId = $selectedClub.data("select-value");
      const envUrl = $(this).data("env-url");
      const link = `${String(envUrl)}/club/${String(clubId)}`;
      $("body").append($temp);
      $temp.val(link).select();
      document.execCommand("copy");
      $temp.remove();

      const copied = $teamMemberTooltip.data("copied");
      $teamMemberTooltip.text(`${String(copied)} ${String($temp.val())}`);
    });
  };

  const editTeamNameInitialize = (
    selectedTeamId: $TSFixMe,
    teamName: $TSFixMe
  ) => {
    const $editTeamNameButton = $(".js-team-member__edit-team-name");
    $editTeamNameButton
      .attr(
        "data-url",
        `/account/club/${teamList.clubId}/team/${String(
          selectedTeamId
        )}/changeteamname`
      )
      .prop("disabled", false);

    $editTeamNameButton.off("click").on("click", function () {
      void (async () => {
        await window.Lightbox.openLightbox(this);
        const $editTeamNameForm = $(".js-team-member__change-team-name");
        $editTeamNameForm.find("#name").val(teamName);
        window.PutForm.initialize();

        $editTeamNameForm.off("FORM_SUBMIT").on("FORM_SUBMIT", () => {
          const newTeamName = $("#name").val();

          teamList.teams.forEach((eachTeam) => {
            if (eachTeam.code === selectedTeamId) {
              eachTeam.name = newTeamName as string;
              eachTeam.selected = true;
            }
          });
          resetTeamDropdown();
          updateTeamDropdown();
          unlockScroll();
        });
      })();
    });
  };

  const deleteTeamInitialize = (
    selectedTeamId: $TSFixMe,
    teamName: $TSFixMe
  ) => {
    const $deleteTeamButton = $(".js-team-member__delete-team-name");
    $deleteTeamButton.prop("disabled", false);

    $deleteTeamButton.off("click").on("click", function () {
      void window.Lightbox.openLightbox(this);
      const $deleteTeamForm = $(".js-team-member__delete-team");
      $deleteTeamForm.find(".js-team-member__team-name").text(teamName);
      $deleteTeamForm
        .find(".js-team-member__club-name")
        .text(teamList.clubName);

      $(".js-team-member__team-delete").on("click", function () {
        deleteSelectedTeam(this, selectedTeamId);
        unlockScroll();
      });
    });
  };

  const deleteSelectedTeam = (submitButton: $TSFixMe, teamId: $TSFixMe) => {
    void $.ajax({
      url: `/clubs/${teamList.clubId}/teams/${String(teamId)}`,
      type: "DELETE",
      contentType: "application/json; charset=utf-8",
      beforeSend() {
        window.AnimateElement.setLoaderAnimation(submitButton, {
          isLoading: true,
        });
      },
      success() {
        window.Lightbox.closeLightboxFromOtherPage();
        teamList.teams = teamList.teams.filter((team) => team.code !== teamId);
        resetTeamDropdown();
        resetTeamButtons();
        updateTeamDropdown();
      },
      error(error) {
        console.log(error);
      },
      complete() {
        window.AnimateElement.setLoaderAnimation(submitButton, {
          isLoading: false,
        });
      },
    });
  };

  const addNewTeamInitialize = () => {
    $(".js-team-member__add-team").on("click", function () {
      void window.Lightbox.openLightbox(this);
      $(".js-team-member__add-team-form").on("submit", function (event) {
        event.preventDefault();
        const submitButton = $(this).find("button");
        const newTeamName = $(this).find("#team-name").val();
        postNewTeam(submitButton, newTeamName);
      });
    });
  };

  const postNewTeam = (submitButton: $TSFixMe, newTeamName: $TSFixMe) => {
    void $.ajax({
      url: `/clubs/${teamList.clubId}/teams`,
      type: "POST",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        name: newTeamName,
      }),
      beforeSend() {
        window.AnimateElement.setLoaderAnimation(submitButton, {
          isLoading: true,
        });
      },
      success({ code, name }) {
        const newTeam = { code, name, selected: false, members: [] };
        teamList.teams.push(newTeam);

        window.Lightbox.closeLightboxFromOtherPage();
        resetTeamDropdown();
        resetTeamButtons();
        updateTeamDropdown();
      },
      error(error) {
        console.error(error);
      },
      complete() {
        window.AnimateElement.setLoaderAnimation(submitButton, {
          isLoading: false,
        });
      },
    });
  };

  const initialize = () => {
    const $teamMembersPage = $(".js-team-members-page");
    if ($teamMembersPage.length < 0) {
      return;
    }

    const $clubSelectDropdown = $(".js-team-member__select-club");
    const preselectedClub = $clubSelectDropdown.find(
      ".select-box__item--selected"
    );

    if (preselectedClub.length > 0) {
      selectClub(preselectedClub);
    }
    window.SelectBox.initialize(
      $clubSelectDropdown,
      (selectedClub: $TSFixMe) => {
        selectClub(selectedClub);
      }
    );

    const $teamDropdownWraper = $(".js-team-member__select-team");
    window.SelectBox.initialize(
      $teamDropdownWraper,
      (selectedTeam: HTMLElement) => {
        selectTeam(selectedTeam);
      }
    );
    addNewTeamInitialize();

    copyLinkToClipboard();
  };

  return {
    initialize,
    renderDeleteLightbox,
    renderEditLightbox,
  };
})();

$(() => {
  window.TeamMembers.initialize();
});
