window.KeyboardUtil = (() => {
  const validateKeyPressed = (event: JQuery.KeyDownEvent): boolean => {
    const $clickedElement = $(event.target);
    const isTabOrEnter = event.keyCode === 9 || event.keyCode === 13;
    if (isTabOrEnter) {
      $clickedElement.trigger("blur");
      return true;
    }

    const isNumberKey = event.keyCode > 47 && event.keyCode < 58;
    const isNumPadKey = event.keyCode > 95 && event.keyCode < 106;
    const isDelete = event.keyCode === 8;
    const isArrowUpOrDown = event.keyCode === 38 || event.keyCode === 40;
    const isArrowLeftOrRight = event.keyCode === 37 || event.keyCode === 39;

    return (
      isNumberKey ||
      isNumPadKey ||
      isDelete ||
      isArrowUpOrDown ||
      isArrowLeftOrRight
    );
  };

  return {
    validateKeyPressed,
  };
})();
