/**
 * This is the legacy version of storefront/react-app/src/utils/LockScreen/lock-screen.ts
 */
const LockScreen = function () {
  if (typeof document === "undefined") {
    return {
      lock: () =>
        console.info("LockScreen.lock is mocked during server side rendering."),
      setLocked: () =>
        console.info(
          "LockScreen.setLocked is mocked during server side rendering."
        ),
      unlock: () =>
        console.info(
          "LockScreen.unlock is mocked during server side rendering."
        ),
    };
  }

  const htmlElement = document.documentElement;
  const bodyElement = document.body;
  let scrollTop = 0;
  let isLocked = false;

  const lock = (isStickyHeader?: boolean): void => {
    if (isLocked) {
      return;
    }

    const windowHeight = window.innerHeight;
    const headerElement = document.getElementById("js-sticky-header");
    scrollTop = window.scrollY;
    isLocked = true;

    let headerHeight = 0;
    if (headerElement !== null) {
      headerHeight = headerElement.offsetHeight;
    }

    bodyElement.style.overflow = "hidden";
    htmlElement.style.overflow = "hidden";

    if (isStickyHeader === true) {
      bodyElement.style.marginTop = `${headerHeight}px`;
    } else {
      htmlElement.style.height = `${windowHeight}px`;
      bodyElement.style.height = `${windowHeight + scrollTop}px`;
      bodyElement.style.marginTop = `${scrollTop}px`;
    }
  };

  const unlock = (): void => {
    if (!isLocked) {
      return;
    }

    htmlElement.removeAttribute("style");
    bodyElement.removeAttribute("style");
    isLocked = false;
    bodyElement.classList.remove("site__scroll-hidden");
    setTimeout(() => {
      window.scrollTo(0, scrollTop);
    }, 0);
  };

  const setLocked = (setLock: boolean): void => {
    if (isLocked === setLock) {
      return;
    }

    if (setLock) {
      lock();
    } else {
      unlock();
    }
  };

  return {
    lock,
    setLocked,
    unlock,
  };
};

window.LockScreen = LockScreen();
