window.LoadContent = (function () {
  const getContent = function (contentContainer: HTMLElement) {
    const url = contentContainer.getAttribute("data-content-url");
    if (url !== null) {
      void $.ajax({
        url,
        method: "GET",
        success(data) {
          $(contentContainer).html(data);
          window.Checkout.initialize();
        },
      });
    }
  };

  const initialize = function () {
    const $contentContainers = $("[data-content-url]");

    if ($contentContainers.length <= 0) {
      return;
    }

    $.each($contentContainers, (index, element) => {
      getContent(element);
    });
  };

  return {
    initialize,
    getContent,
  };
})();

$(() => {
  window.LoadContent.initialize();
});
