window.megaMenu = (() => {
  const $menuButton = $(".js-mega-menu__button");

  const openMenu = () => {
    $menuButton.on("click", function () {
      const $clickedButton = $(this);
      $clickedButton
        .toggleClass("js-mega-menu__open")
        .attr("aria-expanded", "true");

      if (window.XxlEvent === undefined) {
        return;
      }
      const isOpen = $clickedButton.hasClass("js-mega-menu__open");
      window.XxlEvent.dispatchEvent(
        isOpen ? "XXL_MEGAMENU_OPEN" : "XXL_MEGAMENU_CLOSE"
      );

      return false;
    });
  };

  const closeMenu = () => {
    $menuButton
      .removeClass("js-mega-menu__open")
      .attr("aria-expanded", "false");
  };

  const initialize = () => {
    if ($menuButton.length === 0) {
      return;
    }

    openMenu();
  };

  return {
    initialize,
    closeMenu,
  };
})();

$(() => {
  window.megaMenu.initialize();
});
